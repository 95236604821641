import * as React from 'react'
import styles from './TableOverlay.module.scss'

interface Props {}

const TableOverlay: React.FC<Props> = ({ children, ...props }) => (
  <div className={styles.container} {...props}>
    {children}
  </div>
)

export default TableOverlay
