import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { HeadsetRounded, Subject, Delete } from '@material-ui/icons'
import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'

import {
  MiniCourseBookType,
  MiniCourseContentEntry,
  MiniCourseContentEntryType
} from '../../store/services/MiniCourse/miniCourse.types'

import { DndProvider } from 'react-dnd'
import DraggableCard from '../../shared/components/DraggableCard/DraggableCard'

import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes/routes'

import styles from './MiniCourseContent.module.scss'

interface Draggable {
  id: string
  component: MiniCourseContentEntry
}

interface FormFields {
  bookId?: string
  bookType: MiniCourseBookType
  lessonTemplateId?: string
}

interface MiniCourseFormProps {
  content: MiniCourseContentEntry[]
  disabled?: boolean
  onUpdate: (content: MiniCourseContentEntry[]) => void
}

const MiniCourseContent: React.FC<MiniCourseFormProps> = ({ content, disabled, onUpdate }) => {
  const [hoveredDraggableIndex, setHoveredDraggableIndex] = useState<number | null>(null)
  const [draggables, setDraggables] = useState<Draggable[]>([])

  useEffect(() => {
    setDraggables(
      content.map((component, index) => ({
        id: `${component.type}--${index}`,
        component
      }))
    )
  }, [content])

  const swapDraggables = (indexA: number, indexB: number) => {
    if (draggables) {
      const reordered = [...draggables]

      const temp = draggables[indexA]
      reordered[indexA] = reordered[indexB]
      reordered[indexB] = temp

      setDraggables(reordered)
    }
  }

  const submitForm = (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    const { bookId, bookType, lessonTemplateId } = values

    if (bookId) {
      onUpdate([...content, { type: MiniCourseContentEntryType.Book, bookId: bookId.trim(), bookType }])
    } else if (lessonTemplateId) {
      onUpdate([
        ...content,
        { type: MiniCourseContentEntryType.LessonTemplate, lessonTemplateId: lessonTemplateId.trim() }
      ])
    }

    helpers.resetForm()
  }

  const deleteContent = (id: string) =>
    onUpdate(draggables.filter((draggable) => id !== draggable.id).map((draggable) => draggable.component))

  const handleDrop = () => onUpdate(draggables.map((draggable) => draggable.component))

  const checkDisableSubmit = (values: FormFields) => disabled || !(values.bookId || values.lessonTemplateId)

  const dragDisabled = disabled || draggables.length < 2

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography display="block" variant="h5" component="h2" gutterBottom>
          Activities
        </Typography>

        {draggables.length === 0 ? (
          <Typography color="secondary">No content</Typography>
        ) : (
          <ol className={styles.content}>
            <DndProvider backend={HTML5Backend}>
              {draggables.map(({ id, component }, index) => (
                <li key={id} className={`${hoveredDraggableIndex === index ? styles.dropLocation : ''}`}>
                  <DraggableCard
                    id={id}
                    index={index}
                    moveCard={(dragIndex, hoverIndex) => {
                      setHoveredDraggableIndex(hoverIndex)
                      swapDraggables(dragIndex, hoverIndex)
                    }}
                    dropCard={() => {
                      setHoveredDraggableIndex(null)
                      handleDrop()
                    }}
                    canDrag={!dragDisabled}>
                    <Card className={styles.contentCard} key={index}>
                      <Typography className={styles.contentType} color="secondary">
                        {component.type === MiniCourseContentEntryType.Book ? 'Book' : 'Lesson'}
                      </Typography>

                      {'bookId' in component ? (
                        <Link to={`${ROUTES.BOOK}/${component.bookId}`}>Book ID: {component.bookId}</Link>
                      ) : (
                        <Link to={`${ROUTES.STUDY_SETS}/${component.lessonTemplateId}`}>
                          Lesson ID: {component.lessonTemplateId}
                        </Link>
                      )}

                      {'bookType' in component && (
                        <Typography className={styles.bookType} color="secondary">
                          {component.bookType === MiniCourseBookType.Listening ? <HeadsetRounded /> : <Subject />}
                          {component.bookType}
                        </Typography>
                      )}

                      {!disabled && (
                        <IconButton
                          aria-label="delete"
                          className={styles.deleteButton}
                          size="small"
                          onClick={() => deleteContent(id)}>
                          <Delete fontSize="inherit" />
                        </IconButton>
                      )}
                    </Card>
                  </DraggableCard>
                </li>
              ))}
            </DndProvider>
          </ol>
        )}

        <Divider className={styles.divider} />

        <Formik<FormFields>
          initialValues={{
            bookId: '',
            bookType: MiniCourseBookType.Reading,
            lessonTemplateId: ''
          }}
          enableReinitialize={true}
          onSubmit={submitForm}>
          {({ values, handleChange, handleSubmit }) => {
            return (
              <div className={styles.addForm}>
                <div>
                  <TextField
                    className={styles.rowItem}
                    id="bookId"
                    variant="outlined"
                    name="bookId"
                    label="Book ID"
                    disabled={disabled}
                    size="small"
                    margin="none"
                    value={values.bookId}
                    onChange={handleChange('bookId')}
                  />

                  <RadioGroup
                    aria-label="book type"
                    name="bookType"
                    value={values.bookType}
                    onChange={handleChange('bookType')}
                    row>
                    <FormControlLabel
                      disabled={disabled}
                      value={MiniCourseBookType.Reading}
                      control={<Radio />}
                      label="Read"
                    />

                    <FormControlLabel
                      disabled={disabled}
                      value={MiniCourseBookType.Listening}
                      control={<Radio />}
                      label="Listen"
                    />
                  </RadioGroup>
                </div>

                <span className={`${styles.or} ${styles.rowItem}`}>or</span>

                <TextField
                  className={styles.rowItem}
                  id="lessonTemplateId"
                  variant="outlined"
                  name="lessonTemplateId"
                  label="Lesson ID"
                  disabled={disabled}
                  size="small"
                  margin="none"
                  value={values.lessonTemplateId}
                  onChange={handleChange('lessonTemplateId')}
                />

                <Button
                  className={`${styles.submitButton} ${styles.rowItem}`}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={checkDisableSubmit(values)}
                  onClick={() => handleSubmit()}>
                  Add content
                </Button>
              </div>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default MiniCourseContent
