import { Avatar, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { CEFR_COLORS, ClassifyResponse, LevelPercentages, WORD_CEFR_LEVELS } from '../types'
import styles from './StatsContainer.module.scss'

const StatsContainer = ({ data }: { data: ClassifyResponse }) => {
  const firstRowCefr = Object.entries(data.cefr_levels.level_percentages).filter((_, index) => index <= 3)
  const secondRowCefr = Object.entries(data.cefr_levels.level_percentages).filter((_, index) => index > 3)
  return (
    <Paper className={styles.statsContainer}>
      <div className={styles.cefrColumn}>
        {firstRowCefr.map(([cefrLevelName, cefrLevelValues]) => {
          const cefrLevelPercent = cefrLevelValues[0]
          const cefrLevelRawValue = cefrLevelValues[1]
          return (
            <div key={cefrLevelName} className={styles.cefrLevelsPercentagesContainer}>
              <Avatar
                style={{
                  backgroundColor: CEFR_COLORS[cefrLevelName as keyof LevelPercentages],
                  color: 'black'
                }}>
                <Typography>{cefrLevelName?.toUpperCase() ?? '-'}</Typography>
              </Avatar>
              <Typography>{`${cefrLevelPercent} % (${cefrLevelRawValue})`} </Typography>
            </div>
          )
        })}
      </div>
      <div className={styles.cefrColumn}>
        {secondRowCefr.map(([cefrLevelName, cefrLevelValues]) => {
          const cefrLevelPercent = cefrLevelValues[0]
          const cefrLevelRawValue = cefrLevelValues[1]
          return (
            <div key={cefrLevelName} className={styles.cefrLevelsPercentagesContainer}>
              <Avatar
                style={{
                  backgroundColor: CEFR_COLORS[cefrLevelName as keyof LevelPercentages],
                  color: 'black'
                }}>
                <Typography>
                  {cefrLevelName === WORD_CEFR_LEVELS.NONE ? 'N' : cefrLevelName?.toUpperCase() ?? '-'}
                </Typography>
              </Avatar>
              <Typography>{`${cefrLevelPercent} % (${cefrLevelRawValue})`} </Typography>
            </div>
          )
        })}
      </div>
      <div className={styles.cefrColumn}>
        <Typography>{`CEFR level: ${data?.cefr_levels.vocab_level ?? '-'}`} </Typography>
        <Typography>{`Tokens: ${data?.info.number_of_tokens ?? 0}`} </Typography>
        <Typography>{`Sentences: ${data?.info.number_of_sentences ?? 0}`} </Typography>
        <Typography>{`Avg Sentence length: ${data?.info.avg_sentence_length ?? 0}`} </Typography>
        <Typography>{`MTLD Score: ${data?.info.mtld_score ?? 0}`} </Typography>
      </div>
    </Paper>
  )
}

export default StatsContainer
