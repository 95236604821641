import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from '@material-ui/core'
import DashboardToolbar from '../Dashboard/DashboardToolbar/DashboardToolbar'
import { useHistory, useParams } from 'react-router-dom'
import SearchField from '../shared/components/SearchField/SearchField'
import { useDispatch, useSelector } from 'react-redux'
import styles from './TutorsTable.module.scss'
import { ApiReqState, ApiSortModel, PaginatedStatus } from '../shared/api/types'
import { RootState } from '../store/types'
import { ArrowDropDown } from '@material-ui/icons'
import CollectionQueryHelper from '../shared/helpers/CollectionQueryHelper'
import TableOverlay from '../shared/components/TableOverlay/TableOverlay'
import { TutorModel, TutorStatus } from '../store/services/Tutor/types'
import {
  selectTutors,
  selectTutorsStatus,
  selectTutorsReqState,
  getTutorCollection,
  createTutor,
  updateTutor
} from '../store/services/Tutor/tutorReducer'
import { ROUTES } from '../routes/routes'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ValidationRules } from '../shared/helpers/ValidationRules'
import { Logger } from '../shared/logger/Logger'
import { AlertContext } from '../shared/components/AlertContext/AlertContext'
import { printApiMessage } from '../shared/api/apiMessages'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email.max(250),
  firstName: ValidationRules.required.max(50),
  lastName: ValidationRules.required.max(50)
})

const tutorStatusLabel = new Map<TutorStatus, string>([
  [TutorStatus.ACCEPTING_NEW, 'Accepting new'],
  [TutorStatus.NOT_ACCEPTING_NEW, 'Not accepting new'],
  [TutorStatus.DISABLED, 'Disabled']
])

interface State {
  sortParams: ApiSortModel
  searchQuery: string
}

export const TUTORS_PER_PAGE = 10
const NEW_ID = 'new'

const TutorsTable = () => {
  const history = useHistory()
  const { showAlert } = useContext(AlertContext)
  const params = useParams<{ tutorId: string }>()
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()

  const tutors = useSelector<RootState, TutorModel[]>((state) => selectTutors(state))
  const tutorsResStatus = useSelector<RootState, PaginatedStatus | undefined>((state) => selectTutorsStatus(state))
  const tutorReqState = useSelector<RootState, ApiReqState>((state) => selectTutorsReqState(state))

  const [state, setState] = useState<State>({
    sortParams: {},
    searchQuery: ''
  })
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      try {
        const { searchQuery, sortParams } = state
        await dispatch(getTutorCollection({ limit: TUTORS_PER_PAGE, page, sort: sortParams, search: searchQuery }))
      } catch (e) {
        Logger.log(e)
      }
    })()
  }, [page, state, dispatch])

  const handleSortRequest = (key: keyof TutorModel) => (e: React.MouseEvent<unknown>) => {
    setState({ searchQuery: state.searchQuery, sortParams: CollectionQueryHelper.setSortValue(key, state.sortParams) })
    setPage(0)
  }

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ searchQuery: e.target.value, sortParams: state.sortParams })
    setPage(0)
  }

  const handleCreate = async ({
    email,
    firstName,
    lastName
  }: {
    email: string
    firstName: string
    lastName: string
  }) => {
    try {
      await dispatch(createTutor({ email, firstName, lastName }))
      await dispatch(
        getTutorCollection({ limit: TUTORS_PER_PAGE, page, sort: state.sortParams, search: state.searchQuery })
      )
      history.push(ROUTES.TUTORS)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleEdit = async ({
    tutorId,
    email,
    firstName,
    lastName,
    status
  }: {
    tutorId: string
    email: string
    firstName: string
    lastName: string
    status: TutorStatus
  }) => {
    try {
      await dispatch(updateTutor(tutorId, { status, email, firstName, lastName }))
      await dispatch(
        getTutorCollection({ limit: TUTORS_PER_PAGE, page, sort: state.sortParams, search: state.searchQuery })
      )
      history.push(`${ROUTES.TUTORS}`)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const emptyRows = TUTORS_PER_PAGE - tutors.length
  const tutorData = tutors.find((tutor) => tutor._id === params.tutorId)

  const showDialog = !!params.tutorId
  const isEditing = !!params.tutorId && params.tutorId !== NEW_ID
  const isLoading = tutorReqState === ApiReqState.IDLE || tutorReqState === ApiReqState.PENDING

  return (
    <>
      <DashboardToolbar>
        <Typography component="h1" variant="h6">
          Tutors
        </Typography>
        <Button
          color={'primary'}
          variant={'contained'}
          className={styles.addButton}
          onClick={() => history.push(`${ROUTES.TUTORS}/${NEW_ID}`)}>
          Add new tutor
        </Button>
        <SearchField
          containerClassName={styles.searchField}
          value={state.searchQuery}
          onChange={handleSearchQuery}
          onClear={() => handleSearchQuery({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
        />
      </DashboardToolbar>
      <TableContainer component={Paper}>
        {!isLoading && tutors.length === 0 && (
          <TableOverlay>
            <Typography>Tutors not found.</Typography>
          </TableOverlay>
        )}
        {isLoading && (
          <TableOverlay data-testid="TutorsTableLoader">
            <CircularProgress size={40} />
          </TableOverlay>
        )}
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  data-testid={'SortByFirstNameTutorLabel'}
                  active={CollectionQueryHelper.isSortActive('firstName', state.sortParams)}
                  direction={CollectionQueryHelper.printSortDirection('firstName', state.sortParams)}
                  onClick={handleSortRequest('firstName')}
                  IconComponent={ArrowDropDown}>
                  First name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('lastName', state.sortParams)}
                  direction={CollectionQueryHelper.printSortDirection('lastName', state.sortParams)}
                  onClick={handleSortRequest('lastName')}
                  IconComponent={ArrowDropDown}>
                  Last name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('_id', state.sortParams)}
                  direction={CollectionQueryHelper.printSortDirection('_id', state.sortParams)}
                  onClick={handleSortRequest('_id')}
                  IconComponent={ArrowDropDown}>
                  Tutor ID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('email', state.sortParams)}
                  direction={CollectionQueryHelper.printSortDirection('email', state.sortParams)}
                  onClick={handleSortRequest('email')}
                  IconComponent={ArrowDropDown}>
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 120 }}>Learners</TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('status', state.sortParams)}
                  direction={CollectionQueryHelper.printSortDirection('status', state.sortParams)}
                  onClick={handleSortRequest('status')}
                  IconComponent={ArrowDropDown}>
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 120 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {tutors.map((tutor) => (
              <TableRow hover key={tutor._id} data-testid={'TutorListRow'}>
                <TableCell component="th" scope="row">
                  {tutor.firstName}
                </TableCell>
                <TableCell>{tutor.lastName}</TableCell>
                <TableCell>{tutor._id}</TableCell>
                <TableCell>{tutor.email}</TableCell>
                <TableCell>{tutor.userCount}</TableCell>
                <TableCell>{tutorStatusLabel.get(tutor.status)}</TableCell>
                <TableCell>
                  <Button color="primary" onClick={() => history.push(`${ROUTES.TUTORS}/${tutor._id}`)}>
                    Edit profile
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 59 * emptyRows }} data-testid={'TutorListEmptyRow'}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {tutorsResStatus ? (
          <TablePagination
            component="div"
            colSpan={3}
            count={tutorsResStatus.total}
            rowsPerPage={TUTORS_PER_PAGE}
            rowsPerPageOptions={[]}
            page={tutorsResStatus.offset / TUTORS_PER_PAGE}
            onPageChange={(_, page) => setPage(page)}
          />
        ) : (
          <span>Not loaded yet</span>
        )}
      </TableContainer>
      <Dialog open={showDialog && !isLoading} disableBackdropClick onClose={() => history.push(ROUTES.TUTORS)}>
        <Formik
          initialValues={
            tutorData
              ? {
                  email: tutorData.email,
                  firstName: tutorData.firstName,
                  lastName: tutorData.lastName,
                  status: tutorData.status
                }
              : { email: '', firstName: '', lastName: '', status: TutorStatus.NOT_ACCEPTING_NEW }
          }
          validationSchema={validationSchema}
          onSubmit={({ email, firstName, lastName, status }) =>
            isEditing
              ? handleEdit({ tutorId: params.tutorId, status, email, firstName, lastName })
              : handleCreate({ email, firstName, lastName })
          }>
          {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isSubmitting, isValid, dirty }) => (
            <>
              <DialogContent>
                <Typography variant="h6" component="h2">
                  {isEditing ? `${tutorData?.firstName} ${tutorData?.lastName}` : 'Add new tutor'}
                </Typography>
                {isEditing && (
                  <Typography variant="body2" className={styles.id}>
                    Tutor ID: {tutorData?._id}
                  </Typography>
                )}
                <Grid container spacing={4}>
                  {tutorData && tutorData.avatarUrl && (
                    <Grid item xs={4}>
                      <img className={styles.media} src={tutorData.avatarUrl} alt="avatar" />
                    </Grid>
                  )}
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      size="small"
                      onChange={handleChange('email')}
                      value={values.email}
                      error={touched.email && !!errors.email}
                      helperText={(touched.email && errors.email) || ''}
                      onBlur={() => setFieldTouched('email')}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="firstName"
                      label="First name"
                      id="firstName"
                      type="text"
                      autoComplete="firstName"
                      size="small"
                      onChange={handleChange('firstName')}
                      value={values.firstName}
                      error={touched.firstName && !!errors.firstName}
                      helperText={(touched.firstName && errors.firstName) || ''}
                      onBlur={() => setFieldTouched('firstName')}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="lastName"
                      label="Last name"
                      id="lastName"
                      type="text"
                      autoComplete="lastName"
                      size="small"
                      onChange={handleChange('lastName')}
                      value={values.lastName}
                      error={touched.lastName && !!errors.lastName}
                      helperText={(touched.lastName && errors.lastName) || ''}
                      onBlur={() => setFieldTouched('lastName')}
                    />
                    <FormControl variant="outlined" fullWidth margin="normal" size="small" disabled={!isEditing}>
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        labelId="status"
                        name="status"
                        id="status"
                        value={values.status}
                        onChange={handleChange}
                        label="Status">
                        {Array.from(tutorStatusLabel).map(([value, label]) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {tutorData && (
                      <Typography>
                        Learners: <span className={styles.userCount}>{tutorData.userCount}</span>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {tutorData && (tutorData.introductionVideo || tutorData.biography) && (
                  <>
                    <Typography variant="h6" component="h2" className={styles.presentationHeading}>
                      Presentation
                    </Typography>
                    <Grid container spacing={4}>
                      {tutorData.introductionVideo && (
                        <Grid item xs={4}>
                          <video className={styles.media} src={tutorData.introductionVideo} controls />
                        </Grid>
                      )}
                      <Grid item xs>
                        <Typography>{tutorData.biography}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => history.push(ROUTES.TUTORS)}>Cancel</Button>
                <Button
                  data-testid={'submit'}
                  type="submit"
                  variant={'contained'}
                  color={'primary'}
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={() => handleSubmit()}>
                  {isSubmitting && <CircularProgress size={24} color={'inherit'} />}
                  {!isSubmitting && isEditing && 'Save'}
                  {!isSubmitting && !isEditing && 'Add'}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default TutorsTable
