import React from 'react'
import { Button, Card, CardContent, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ValidationRules } from '../../shared/helpers/ValidationRules'

import FormFooter from '../../shared/components/FormFooter/FormFooter'

import styles from './ShelfForm.module.scss'
import { CreateShelfBody, ShelfApiDoc, UpdateShelfBody } from '../../store/services/Shelf/shelf.types'
import MultiSelector from '../../shared/components/MultiSelector/MultiSelector'

interface ShelfFormFields {
  title: string
  contents: any[]
  industryTags: string[]
  topicTags: string[]
}

interface ShelfFormProps {
  shelf?: ShelfApiDoc
  disabled?: boolean
  industries: string[]
  industryTags: string[]
  topics: string[]
  topicTags: string[]
  onCreate: (args: { shelf: CreateShelfBody; industryTags: string[]; topicTags: string[] }) => void
  onUpdate: (args: { shelf: UpdateShelfBody; industryTags: string[]; topicTags: string[] }) => void
  onDelete: () => void
  onPublish: () => void
  onUnpublish: () => void
}

const TITLE_MAX_LENGTH = 45

const validationSchema = Yup.object().shape({
  title: ValidationRules.required.max(TITLE_MAX_LENGTH)
})

function getInitialValues({
  shelf,
  industryTags,
  topicTags
}: {
  shelf?: ShelfApiDoc
  industryTags: string[]
  topicTags: string[]
}): ShelfFormFields {
  return shelf
    ? {
        title: shelf.title,
        contents: [],
        industryTags,
        topicTags
      }
    : {
        title: '',
        contents: [],
        industryTags: [],
        topicTags: []
      }
}

const ShelfForm: React.FC<ShelfFormProps> = ({
  shelf,
  disabled,
  industries,
  industryTags,
  topics,
  topicTags,
  onCreate,
  onUpdate,
  onDelete,
  onPublish,
  onUnpublish
}) => {
  const isNew = !shelf

  const handleSubmitForm = ({ title, industryTags, topicTags }: ShelfFormFields) => {
    if (isNew) {
      onCreate({ shelf: { title }, industryTags, topicTags })
    } else {
      onUpdate({ shelf: { title }, industryTags, topicTags })
    }
  }

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography display="block" variant="h5" component="h2" gutterBottom>
          {isNew ? 'Create shelf' : 'Shelf'}
        </Typography>
        <Formik<ShelfFormFields>
          enableReinitialize={true}
          initialValues={getInitialValues({ shelf, industryTags, topicTags })}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitForm}>
          {({ values, touched, errors, handleChange, handleSubmit, setFieldTouched, setFieldValue, dirty }) => {
            return (
              <>
                <TextField
                  id="title"
                  variant="outlined"
                  name="title"
                  label="Title"
                  required
                  fullWidth
                  inputProps={{ maxLength: TITLE_MAX_LENGTH }}
                  disabled={disabled}
                  size="small"
                  margin="normal"
                  value={values.title}
                  error={touched.title && !!errors.title}
                  helperText={(touched.title && errors.title) || ''}
                  onChange={handleChange('title')}
                  onBlur={() => setFieldTouched('title')}
                />

                <MultiSelector
                  className={styles.selector}
                  label="Industries"
                  options={industries}
                  selected={values.industryTags}
                  onChange={(selected) =>
                    setFieldValue(
                      'industryTags',
                      selected.map((s) => s.trim())
                    )
                  }
                  disabled={disabled}
                />

                <MultiSelector
                  className={styles.selector}
                  label="Topics"
                  options={topics}
                  selected={values.topicTags}
                  onChange={(selected) =>
                    setFieldValue(
                      'topicTags',
                      selected.map((s) => s.trim())
                    )
                  }
                  disabled={disabled}
                />

                <FormFooter>
                  {isNew ? (
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid={'submitBookBtn'}
                      onClick={() => {
                        handleSubmit()
                      }}>
                      Create shelf
                    </Button>
                  ) : (
                    <>
                      {shelf?.active ? (
                        <Button variant="contained" color="primary" onClick={onUnpublish}>
                          Unpublish
                        </Button>
                      ) : (
                        <>
                          <Button variant="outlined" color="primary" onClick={onDelete}>
                            Delete
                          </Button>

                          <Button variant="outlined" color="primary" onClick={onPublish}>
                            Publish
                          </Button>

                          <Button
                            disabled={!dirty}
                            variant="contained"
                            color="primary"
                            data-testid={'submitBookBtn'}
                            onClick={() => {
                              handleSubmit()
                            }}>
                            Update
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </FormFooter>
              </>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default ShelfForm
