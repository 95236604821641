import { Button, Card, CardContent, Grid, MenuItem, SvgIcon, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { ROUTES } from '../../routes/routes'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import { ConfirmPopup } from '../../shared/components/ConfirmPopup/ConfirmPopup'
import { getBookCollection } from '../../store/services/Book/bookReducer'
import { getContentTags, selectContentTags } from '../../store/services/ContentTag/contentTagReducer'
import { ContentTagModel, ContentType } from '../../store/services/ContentTag/types'
import {
  createStudySetComponent,
  createStudySetSection,
  deleteSpeakingChallengeComponent,
  deleteStudySetComponent,
  deleteStudySetSection,
  getStudySetSingle,
  moveStudySetComponent,
  selectStudySetSingleData,
  updateSpeakingChallengeComponent,
  updateStudySetComponent,
  updateStudySetSection
} from '../../store/services/StudySet/studySetReducer'
import {
  SectionTag,
  StudySetComponentModel,
  StudySetComponentRequest,
  StudySetComponentSpeakingChallengeModel,
  StudySetComponentSpeakingChallengeRequest,
  StudySetComponentType,
  StudySetModel
} from '../../store/services/StudySet/types'
import { getTopics, selectTopics } from '../../store/services/Topics/topicsReducer'
import { RootState } from '../../store/types'
import { ComponentTitle } from '../components/ComponentTitle/ComponentTitle'
import { Section } from '../components/Section/Section'
import { SpeakingChallenge } from '../content/SpeakingChallenge/SpeakingChallenge'
import { SpeakingChallengeForm } from '../content/SpeakingChallenge/SpeakingChallengeForm'
import { sectionTags } from '../sectionTags'
import StudySetForm from '../StudySetForm/StudySetForm'
import { ComponentMode } from '../types'
import styles from './StudySetSingle.module.scss'

interface StudySetParams {
  studySetId: string
}

const NEW_ID = 'new'

const StudySetSingle = () => {
  const history = useHistory()
  const { studySetId } = useParams<StudySetParams>()
  const { showAlert } = useContext(AlertContext)
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()
  const studySet = useSelector<RootState, StudySetModel | undefined>((state) =>
    selectStudySetSingleData(state, studySetId)
  )
  const contentTags = useSelector<RootState, ContentTagModel | undefined>((state) =>
    selectContentTags(state, studySetId, ContentType.LessonTemplate)
  )
  const topics = useSelector<RootState, string[]>((state) =>
    selectTopics(state, studySetId, ContentType.LessonTemplate)
  )
  const [contentMode, setContentMode] = useState<ComponentMode>({
    type: studySet?.active ? 'read-only' : 'editable'
  })

  const isNew = studySetId === NEW_ID

  useEffect(() => {
    if (!isNew) {
      ;(async () => {
        try {
          await Promise.all([
            dispatch(getStudySetSingle(studySetId)),
            dispatch(getContentTags({ contentType: ContentType.LessonTemplate, contentId: studySetId })),
            dispatch(getTopics({ contentType: ContentType.LessonTemplate, contentId: studySetId }))
          ])
        } catch (error) {
          showAlert(printApiMessage(error))
          history.push(ROUTES.STUDY_SETS)
        }
      })()
    }
  }, [dispatch, showAlert, history, isNew, studySetId])

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getBookCollection({ limit: 1000 }))
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    })()
  }, [dispatch, showAlert])

  useEffect(() => {
    if (studySet) {
      setContentMode({ type: studySet.active ? 'read-only' : 'editable' })
    }
  }, [studySet])

  const saveComponent = async (
    data: StudySetComponentModel | StudySetComponentRequest,
    sectionIndex: number,
    componentIndex?: number
  ) => {
    try {
      if (componentIndex === undefined) {
        await dispatch(createStudySetComponent(studySetId, sectionIndex, data))
      } else {
        await dispatch(updateStudySetComponent(studySetId, sectionIndex, componentIndex, data))
      }
      setContentMode({ type: 'editable' })
    } catch (error) {
      showAlert(printApiMessage(error))
      throw error
    }
  }

  const deleteComponent = async (sectionIndex: number, componentIndex: number) => {
    try {
      await dispatch(deleteStudySetComponent(studySetId, sectionIndex, componentIndex))
    } catch ({ message }) {
      showAlert(printApiMessage(message))
    }
  }

  const handleMoveComponent = async (sectionIndex: number, fromIndex: number, toIndex: number) => {
    try {
      if (studySet) {
        await dispatch(moveStudySetComponent(studySet._id, sectionIndex, { fromIndex, toIndex }))
      }
    } catch ({ message }) {
      showAlert(printApiMessage(message))
    }
  }

  const saveSpeakingChallenge = async (
    data: StudySetComponentSpeakingChallengeModel | StudySetComponentSpeakingChallengeRequest
  ) => {
    try {
      await dispatch(updateSpeakingChallengeComponent(studySetId, data))
      setContentMode({ type: 'editable' })
    } catch (error) {
      showAlert(printApiMessage(error))
      throw error
    }
  }

  const deleteSpeakingChallenge = async () => {
    try {
      await dispatch(deleteSpeakingChallengeComponent(studySetId))
    } catch ({ message }) {
      showAlert(printApiMessage(message))
    }
  }

  const saveSection = async (sectionTag: SectionTag, sectionIndex?: number) => {
    try {
      if (sectionIndex === undefined) {
        await dispatch(createStudySetSection(studySetId, sectionTag))
      } else {
        await dispatch(updateStudySetSection(studySetId, sectionIndex, sectionTag))
      }
      setContentMode({ type: 'editable' })
    } catch (error) {
      showAlert(printApiMessage(error))
      throw error
    }
  }

  const deleteSection = async (sectionIndex: number) => {
    try {
      await dispatch(deleteStudySetSection(studySetId, sectionIndex))
    } catch ({ message }) {
      showAlert(printApiMessage(message))
    }
  }

  const getContentMode = (sectionIndex: number): ComponentMode => {
    if (contentMode.type === 'editing' && contentMode.sectionIndex === sectionIndex) {
      return { type: contentMode.type, componentIndex: contentMode.componentIndex }
    }

    if (contentMode.type === 'adding' && contentMode.sectionIndex === sectionIndex) {
      return { type: contentMode.type, option: contentMode.option }
    }

    if (contentMode.type === 'editable') {
      return contentMode
    }

    return { type: 'read-only' }
  }

  const getAvailableSectionTagOptions = (currentTag?: SectionTag) =>
    sectionTags.filter((option) => {
      const isCurrent = option.type === currentTag
      const isSpeakingChallenge = option.type === SectionTag.SpeakingChallenge
      const isAlreadyUsed = studySet?.sections.map((s) => s.sectionName).includes(option.type)
      return isCurrent || (!isSpeakingChallenge && !isAlreadyUsed)
    })

  const availableSectionTagOptions = getAvailableSectionTagOptions()
  const SpeakingChallengeTag = sectionTags.find((option) => option.type === SectionTag.SpeakingChallenge)!
  const showAddSectionButton = contentMode.type === 'editable' && availableSectionTagOptions.length > 0
  const showSpeakingChallengeButton = contentMode.type === 'editable' && !studySet?.speakingChallenge
  const showSpeakingChallenge =
    (contentMode.type === 'adding' && contentMode.option.type === StudySetComponentType.SpeakingChallenge) ||
    studySet?.speakingChallenge

  return (
    <div className={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography display="block" variant="h5" component="h2" gutterBottom>
                {studySet ? 'Study set' : 'Create study set'}
              </Typography>
              <StudySetForm
                studySet={studySet}
                contentTags={contentTags}
                topics={topics}
                allowEdit={!studySet?.active}
              />
            </CardContent>
          </Card>
        </Grid>
        {!isNew && (
          <>
            {studySet?.sections.map((section, sectionIndex) => (
              <Grid item xs={12} key={section.sectionName}>
                <Section
                  section={section}
                  componentMode={getContentMode(sectionIndex)}
                  availableSectionTagOptions={getAvailableSectionTagOptions(section.sectionName)}
                  onSaveSection={(sectionTag) => saveSection(sectionTag, sectionIndex)}
                  onDeleteSection={() => deleteSection(sectionIndex)}
                  onMoveComponent={(fromIndex, toIndex) => handleMoveComponent(sectionIndex, fromIndex, toIndex)}
                  onSaveComponent={(data, componentIndex) => saveComponent(data, sectionIndex, componentIndex)}
                  onCancelComponent={() => setContentMode({ type: 'editable' })}
                  onDeleteComponent={(componentIndex) => deleteComponent(sectionIndex, componentIndex)}
                  onAddComponent={(option) => setContentMode({ type: 'adding', option, sectionIndex })}
                  onEditComponent={(componentIndex) =>
                    setContentMode({ type: 'editing', componentIndex, sectionIndex })
                  }
                />
              </Grid>
            ))}
            {showAddSectionButton && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  className={styles.addButton}
                  onClick={() => saveSection(availableSectionTagOptions[0].type)}>
                  + Add section
                </Button>
              </Grid>
            )}
            {showSpeakingChallengeButton && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  className={styles.addButton}
                  onClick={() =>
                    setContentMode({ type: 'adding', option: { type: StudySetComponentType.SpeakingChallenge } })
                  }>
                  + Add tutor interaction
                </Button>
              </Grid>
            )}
            {showSpeakingChallenge && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          className={styles.sectionSelector}
                          select
                          SelectProps={{ IconComponent: () => null }} // hide dropdown arrow
                          disabled
                          label="Section tag"
                          variant="outlined"
                          size="small"
                          value={SpeakingChallengeTag.type}
                          name="selectedOption">
                          <MenuItem key={SpeakingChallengeTag.type} value={SpeakingChallengeTag.type}>
                            <div className={styles.sectionSelectItem}>
                              <SvgIcon>
                                <SpeakingChallengeTag.Icon />
                              </SvgIcon>
                              {SpeakingChallengeTag.title}
                            </div>
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        {contentMode.type === 'adding' &&
                          contentMode.option.type === StudySetComponentType.SpeakingChallenge && (
                            <Card className={styles.placeholder}>
                              <CardContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h3" className={styles.componentHeading}>
                                      <ComponentTitle componentInfo={contentMode.option} />
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <SpeakingChallengeForm
                                      data={contentMode.option}
                                      onSave={(
                                        requestData:
                                          | StudySetComponentSpeakingChallengeModel
                                          | StudySetComponentSpeakingChallengeRequest
                                      ) => saveSpeakingChallenge(requestData)}
                                      onCancel={() => setContentMode({ type: 'editable' })}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                        {contentMode.type === 'editing' &&
                          contentMode.componentType === StudySetComponentType.SpeakingChallenge && (
                            <Card className={styles.card}>
                              <CardContent className={styles.content}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h3" className={styles.componentHeading}>
                                      <ComponentTitle
                                        componentInfo={studySet!.speakingChallenge as StudySetComponentModel}
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <SpeakingChallengeForm
                                      data={studySet!.speakingChallenge!}
                                      onSave={(
                                        requestData:
                                          | StudySetComponentSpeakingChallengeModel
                                          | StudySetComponentSpeakingChallengeRequest
                                      ) => saveSpeakingChallenge(requestData)}
                                      onCancel={() => setContentMode({ type: 'editable' })}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                        {contentMode.type === 'editable' && (
                          <Card className={styles.card}>
                            <CardContent className={styles.content}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography variant="h3" className={styles.componentHeading}>
                                    <ComponentTitle componentInfo={studySet!.speakingChallenge!} />
                                  </Typography>
                                </Grid>
                                <>
                                  <Grid item xs={10}>
                                    <SpeakingChallenge data={studySet!.speakingChallenge!} />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <div className={styles.buttons}>
                                      <ConfirmPopup onConfirm={() => deleteSpeakingChallenge()}>
                                        <Button color="primary" variant="outlined">
                                          Delete
                                        </Button>
                                      </ConfirmPopup>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          setContentMode({
                                            type: 'editing',
                                            componentType: StudySetComponentType.SpeakingChallenge
                                          })
                                        }>
                                        Edit
                                      </Button>
                                    </div>
                                  </Grid>
                                </>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                        {contentMode.type === 'read-only' && (
                          <Card className={styles.card}>
                            <CardContent className={styles.content}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography variant="h3" className={styles.componentHeading}>
                                    <ComponentTitle componentInfo={studySet!.speakingChallenge!} />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <SpeakingChallenge data={studySet!.speakingChallenge!} />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  )
}

export default StudySetSingle
