import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { StudySetComponentSpeakingChallengeModel } from '../../../store/services/StudySet/types'
import { VideoPreview } from '../../components/VideoPreview/VideoPreview'
import styles from './SpeakingChallenge.module.scss'

interface Props {
  data: StudySetComponentSpeakingChallengeModel
}

export const SpeakingChallenge = ({ data }: Props) => {
  return (
    <Box display="flex" gridGap={16}>
      {data.videoQuestion?.originalUrl && <VideoPreview src={data.videoQuestion.originalUrl} />}
      <div>
        <div className={styles.instructions}>
          <Typography variant="h4">Question to the learner</Typography>
          <Typography variant="body2" color="textSecondary">
            {data.textQuestion}
          </Typography>
        </div>

        {data.tutorInstruction && (
          <div className={styles.instructions}>
            <Typography variant="h4">Instructions for the tutor</Typography>
            <Typography variant="body2" color="textSecondary">
              {data.tutorInstruction}
            </Typography>
          </div>
        )}

        {data.points !== undefined && (
          <div className={styles.instructions}>
            <Typography variant="h4">Points</Typography>
            <Typography variant="body2" color="textSecondary">
              {data.points}
            </Typography>
          </div>
        )}
      </div>
    </Box>
  )
}
