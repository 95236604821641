import { Button, MenuItem, TextField } from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import { StudySetComponentOption } from '../../types'
import { ComponentTitle } from '../ComponentTitle/ComponentTitle'
import styles from './ComponentSelector.module.scss'
interface Props<T> {
  buttonTitle?: string
  options: T[]
  onAdd: (option: T) => void
}

export const ComponentSelector = <T extends StudySetComponentOption>({ buttonTitle, options, onAdd }: Props<T>) => {
  return (
    <Formik initialValues={{ type: 0 }} onSubmit={({ type: optionIndex }) => onAdd(options[optionIndex])}>
      {({ values, handleChange, handleSubmit }) => {
        return (
          <div className={styles.selector}>
            <TextField
              select
              className={styles.select}
              value={values.type}
              label="Type"
              variant="outlined"
              size="small"
              id="selectedOption"
              name="selectedOption"
              onChange={handleChange('type')}>
              {options.map((option, index) => (
                <MenuItem key={JSON.stringify(option)} value={index}>
                  <ComponentTitle componentInfo={option} />
                </MenuItem>
              ))}
            </TextField>
            <Button
              onClick={() => handleSubmit()}
              className={styles.addContent}
              disableRipple
              color="primary"
              variant="contained">
              {buttonTitle ?? 'Add'}
            </Button>
          </div>
        )
      }}
    </Formik>
  )
}
