import { BookType } from '@astrid/components'
import { ReactComponent as AbbeSvg } from '@astrid/components/dist/graphics/abbe_silhouette.svg'
import { SvgIcon } from '@material-ui/core'
import { Extension, HeadsetRounded, Sms, Subject, Videocam } from '@material-ui/icons'
import React from 'react'
import { getExerciseTypeDisplayName } from '../../../store/services/Exercise/helpers'
import { ExerciseType } from '../../../store/services/Exercise/types'
import { StudySetComponentModel, StudySetComponentType } from '../../../store/services/StudySet/types'
import { StudySetComponentOption } from '../../types'
import styles from './ComponentTitle.module.scss'

interface Props {
  componentInfo: StudySetComponentOption | StudySetComponentModel
}

export const ComponentTitle = ({ componentInfo }: Props) => {
  const isListeningStory =
    componentInfo.type === StudySetComponentType.Book && componentInfo.bookType === BookType.Listening
  const isReadingStory =
    componentInfo.type === StudySetComponentType.Book && componentInfo.bookType === BookType.Reading
  const isVideo = componentInfo.type === StudySetComponentType.Video
  const isAnimation = componentInfo.type === StudySetComponentType.Animation
  const isSpeakingChallenge = componentInfo.type === StudySetComponentType.SpeakingChallenge
  const isExercise = componentInfo.type === StudySetComponentType.Exercise

  const isListeningPageExercise = () => {
    if (
      componentInfo.type === StudySetComponentType.Exercise &&
      ('exercise' in componentInfo || componentInfo.exerciseType === ExerciseType.ReadSentence)
    ) {
      return componentInfo.sentenceType === BookType.Listening
    }

    return false
  }

  const getExerciseDisplayName = () => {
    if (componentInfo.type === StudySetComponentType.Exercise) {
      const type = 'exercise' in componentInfo ? componentInfo.exercise!.type : componentInfo.exerciseType!
      return getExerciseTypeDisplayName(type)
    }

    return ''
  }

  return (
    <div className={styles.componentIconTitle}>
      {isListeningStory && (
        <>
          <HeadsetRounded /> Listening Story
        </>
      )}
      {isReadingStory && (
        <>
          <Subject /> Reading Story
        </>
      )}
      {isVideo && (
        <>
          <Videocam /> Video
        </>
      )}
      {isAnimation && (
        <>
          <SvgIcon>
            <AbbeSvg />
          </SvgIcon>
          Animation
        </>
      )}
      {isSpeakingChallenge && (
        <>
          <Sms /> Speaking challenge
        </>
      )}
      {isListeningPageExercise() && (
        <>
          <Extension /> Listening Page
        </>
      )}
      {!isListeningPageExercise() && isExercise && (
        <>
          <Extension /> {getExerciseDisplayName()}
        </>
      )}
    </div>
  )
}
