import API from '../../../shared/api/API'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import { MiniCourseModel, MiniCourseContentEntry } from './miniCourse.types'

type MiniCourseLoadResponse = Paginated<MiniCourseModel>

class MiniCourseApi {
  static load = (
    { limit = 10, page = 0, sort, filter, search }: PaginatedParams = {},
    signal?: AbortSignal
  ): Promise<MiniCourseLoadResponse> =>
    API.get(`/mini-course`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $filter: filter && CollectionQueryHelper.filterParamsToQueryString(filter),
        $search: search?.toLocaleLowerCase()
      }),
      signal
    }).then((res) => res.data)

  static loadSingle = (id: MiniCourseModel['_id']): Promise<MiniCourseModel> =>
    API.get(`/mini-course/${id}/simple`).then((res) => res.data)

  static create = (data: FormData): Promise<MiniCourseModel> => API.post('/mini-course', data).then((res) => res.data)

  static delete = (id: MiniCourseModel['_id']) => API.delete(`/mini-course/${id}`)

  static publish = (id: MiniCourseModel['_id']): Promise<MiniCourseModel> =>
    API.put(`/mini-course/${id}/publish`, { active: true }).then((res) => res.data)

  static unpublish = (id: MiniCourseModel['_id']): Promise<MiniCourseModel> =>
    API.put(`/mini-course/${id}/publish`, { active: false }).then((res) => res.data)

  static update = (id: MiniCourseModel['_id'], data: FormData): Promise<MiniCourseModel> =>
    API.patch(`/mini-course/${id}`, data).then((res) => res.data)

  static updateContent = (id: MiniCourseModel['_id'], contents: MiniCourseContentEntry[]): Promise<MiniCourseModel> =>
    API.post(`/mini-course/${id}/contents`, { contents }).then((res) => res.data)
}

export default MiniCourseApi
