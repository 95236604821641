import { Chip } from '@material-ui/core'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import * as React from 'react'
import { ExerciseModel, ExerciseType } from '../../../store/services/Exercise/types'
import CustomizedAvatar from '../../CustomizedAvatar/CustomizedAvatar'
import styles from './BottomDescription.module.scss'
import { COMPREHENSION_IMAGE_HEIGHT, COMPREHENSION_IMAGE_WIDTH } from '../../../shared/constants/imageSizes'

const BottomDescription = ({ exercise }: { exercise: ExerciseModel }) => {
  if (exercise.type === ExerciseType.ReadSentence)
    return exercise.recordingUsUrl ? <VolumeUpIcon /> : <VolumeOffIcon className={styles.missing} />

  if (exercise.type === ExerciseType.MultiChoiceQuestion) {
    if (
      exercise.data.imageOption1 ||
      exercise.data.imageOption2 ||
      exercise.data.imageOption3 ||
      exercise.data.imageOption4
    )
      return (
        <div className={styles.imageOptions}>
          {[
            exercise.data.imageOption1,
            exercise.data.imageOption2,
            exercise.data.imageOption3,
            exercise.data.imageOption4
          ].map(
            (option) =>
              option && (
                <CustomizedAvatar
                  key={option}
                  url={option}
                  width={COMPREHENSION_IMAGE_WIDTH * 0.2}
                  height={COMPREHENSION_IMAGE_HEIGHT * 0.2}
                />
              )
          )}
        </div>
      )

    if (
      exercise.data.audioOptionUs1 ||
      exercise.data.audioOptionUs2 ||
      exercise.data.audioOptionUs3 ||
      exercise.data.audioOptionUs4
    )
      return (
        <>
          {exercise.data.audioOptionUs1 && <VolumeUpIcon />}
          {exercise.data.audioOptionUs2 && <VolumeUpIcon />}
          {exercise.data.audioOptionUs3 && <VolumeUpIcon />}
          {exercise.data.audioOptionUs4 && <VolumeUpIcon />}
        </>
      )

    if (exercise.data.optionsUs?.length)
      return (
        <>
          {exercise.data.optionsUs.map((option) => (
            <Chip key={option} className={styles.singleOption} variant="outlined" label={option} />
          ))}
        </>
      )

    if (exercise.data.options?.length)
      return (
        <>
          {exercise.data.options.map((option) => (
            <Chip key={option} className={styles.singleOption} variant="outlined" label={option} />
          ))}
        </>
      )
  }
  if (exercise.type === ExerciseType.SentenceBuilder)
    return (
      <div className={styles.distractorsContainer}>
        Distractors:
        {exercise.data.distractorsUs?.map((distractor) => (
          <Chip key={distractor} className={styles.distractor} variant="outlined" label={distractor} />
        ))}
      </div>
    )

  if (exercise.type === ExerciseType.WordBuilder)
    return (
      <>
        <div className={styles.distractorsContainer}>
          Distractors:
          {exercise.data.distractorsUs?.map((distractor) => (
            <Chip key={distractor} className={styles.distractor} variant="outlined" label={distractor} />
          ))}
        </div>
      </>
    )

  return null
}

export default BottomDescription
