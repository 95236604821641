import * as React from 'react'
import styles from './FormFooter.module.scss'

interface Props {
  gutterBottom?: boolean
}

const FormFooter: React.FC<Props> = ({ children, gutterBottom }) => (
  <div className={`${styles.container} ${gutterBottom ? styles.gutterBottom : ''}`}>{children}</div>
)
export default FormFooter
