import * as React from 'react'
import styles from './BuilderSegment.module.scss'

interface Props {
  index: string
  value: string
  checked: boolean
  add: () => void
  remove: () => void
  disabled?: boolean
  onBlur?: () => void
}

const BuilderSegment: React.FC<Props> = ({ index, value, checked, add, remove, disabled, onBlur }) => {
  return (
    <div className={`${styles.segment} ${disabled ? styles.disabled : ''}`}>
      <input
        type="checkbox"
        id={`segment-${index}`}
        data-testid={`segment-${index}`}
        value={index}
        checked={checked}
        onChange={
          !disabled
            ? () => {
                if (checked) {
                  return remove()
                } else {
                  return add()
                }
              }
            : undefined
        }
        disabled={disabled}
        onBlur={onBlur}
      />
      <label htmlFor={`segment-${index}`}>{value}</label>
    </div>
  )
}

export default BuilderSegment
