export const jsonToFormData = (data: any): FormData => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    // todo handle nested objects
    if (Array.isArray(value) && value.some((item) => item instanceof Blob || item instanceof File)) {
      value.forEach((file) => formData.append(key, file))
    } else if (value instanceof Blob || value instanceof File) {
      formData.append(key, value)
    } else if (Array.isArray(value)) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value as string)
    }
  })
  return formData
}
