export const getModifiedValues = (values: { [key: string]: unknown }, initialValues: { [key: string]: unknown }) => {
  let modifiedValues: { [key: string]: unknown } = {}

  Object.entries(values).forEach(([key, value]) => {
    if (value !== initialValues[key]) {
      modifiedValues[key] = value
    }
  })

  return modifiedValues
}
