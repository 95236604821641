import * as React from 'react'
import { IconButton, InputBase, InputBaseProps } from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'
import styles from './SearchField.module.scss'

interface Props extends InputBaseProps {
  containerClassName?: string
  onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SearchField: React.FC<Props> = ({ containerClassName, onClear, value, ...props }) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null)

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    onClear?.(e)
  }

  return (
    <div className={`${styles.container} ${containerClassName} ${onClear ? styles.withClear : ''}`}>
      <IconButton disabled={true}>
        <Search color={'secondary'} />
      </IconButton>
      <InputBase
        inputRef={inputRef}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        {...props}
      />
      {!!inputRef.current?.value && (
        <IconButton aria-label="clear" onClick={handleClear} className={styles.clearButton}>
          <Clear />
        </IconButton>
      )}
    </div>
  )
}

export default SearchField
