import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'

interface ConfirmPopupProps {
  onConfirm: () => void
  inactive?: boolean
  disabled?: boolean
  text?: string
  cancelText?: string
  confirmText?: string
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  onConfirm,
  text,
  inactive,
  disabled,
  cancelText,
  confirmText,
  children
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
  }

  const handleClick = () => {
    if (!disabled) {
      if (inactive) {
        handleConfirm()
      } else {
        setIsOpen(true)
      }
    }
  }

  return (
    <>
      <div onClick={handleClick}>{children}</div>
      <Dialog open={isOpen}>
        <DialogContent>
          <DialogContentText>{text || 'Are you sure?'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => setIsOpen(false)}>
            {cancelText || 'Cancel'}
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            {confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
