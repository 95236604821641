import { Avatar } from '@material-ui/core'
import * as React from 'react'
import styles from './CustomizedAvatar.module.scss'

interface Props {
  url: string
  width?: number
  height?: number
}

const CustomizedAvatar = ({ url, width, height }: Props) => (
  <Avatar variant="square" src={url} className={styles.exerciseItemAvatar} style={{ width, height }} />
)

export default CustomizedAvatar
