import { BookType } from '@astrid/components'
import { Box, Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import Comprehension from '../../../Exercise/Comprehension/Comprehension'
import ReadingPage from '../../../Exercise/ReadingPage/ReadingPage'
import SentenceBuilder from '../../../Exercise/SentenceBuilder/SentenceBuilder'
import WordBuilder from '../../../Exercise/WordBuilder/WordBuilder'
import VideoUploader from '../../../shared/components/VideoUploader/VideoUploader'
import { ValidationRules } from '../../../shared/helpers/ValidationRules'
import { Logger } from '../../../shared/logger/Logger'
import ExerciseApi from '../../../store/services/Exercise/exerciseApi'
import {
  ExerciseModel,
  ExerciseMultiChoiceQuestionModel,
  ExerciseReadSentenceModel,
  ExerciseSentenceBuilderModel,
  ExerciseType,
  ExerciseWordBuilderModel
} from '../../../store/services/Exercise/types'
import StudySetApi from '../../../store/services/StudySet/studySetApi'
import { selectStudySetSingle } from '../../../store/services/StudySet/studySetReducer'
import {
  StudySetComponentType,
  StudySetComponentVideoModel,
  StudySetComponentVideoRequest
} from '../../../store/services/StudySet/types'
import { RootState } from '../../../store/types'
import { ComponentForm } from '../../components/ComponentForm/ComponentForm'
import { ComponentSelector } from '../../components/ComponentSelector/ComponentSelector'
import { VideoPreview } from '../../components/VideoPreview/VideoPreview'
import { studySetComponentOptions } from '../../studySetComponentOptions'
import { ComponentFormProps, StudySetComponentOption } from '../../types'
import { Exercise } from '../Exercise/Exercise'

const validationSchema = Yup.object().shape({
  videoId: ValidationRules.required
})

const supportedExerciseTypes = [
  ExerciseType.MultiChoiceQuestion,
  ExerciseType.ReadSentence,
  ExerciseType.SentenceBuilder,
  ExerciseType.WordBuilder
] as const

type Props = ComponentFormProps<StudySetComponentVideoModel, StudySetComponentVideoRequest>
type VideoExerciseType = typeof supportedExerciseTypes[number]
type VideoExerciseOption = StudySetComponentOption & { exerciseType: VideoExerciseType }

const exerciseOptions = studySetComponentOptions.filter(
  (option): option is VideoExerciseOption =>
    option.type === StudySetComponentType.Exercise &&
    supportedExerciseTypes.some((supported) => supported === option.exerciseType) &&
    (!('sentenceType' in option) || option.sentenceType === BookType.Reading)
)

export const VideoForm = ({ data, onCancel, onSave }: Props) => {
  const studySetId = useSelector<RootState, string | undefined>((state) => selectStudySetSingle(state).data?._id)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(!data.videoId)
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(!!data.exercise)
  const [selectedExerciseType, setSelectedExerciseType] = useState<VideoExerciseType | null>(null)
  const [originalVideoUrl, setOriginalVideoUrl] = useState<string>('')
  const exerciseIdRef = useRef<string | undefined>(data.exercise?._id)

  const handleFormSubmit = (values: { videoId: string }): StudySetComponentVideoRequest => {
    return {
      type: StudySetComponentType.Video,
      videoId: values.videoId,
      exerciseId: exerciseIdRef.current
    }
  }

  return (
    <ComponentForm
      validationSchema={validationSchema}
      initialValues={{
        videoId: data.videoId || '',
        videoUrl: ''
      }}
      onSubmitForm={handleFormSubmit}
      onCancel={onCancel}
      onSave={onSave}>
      {({ errors, handleSubmit, setFieldValue }) => {
        const videoUrl = originalVideoUrl || data.video?.originalUrl
        const exerciseData =
          exerciseIdRef.current && data.exercise?._id === exerciseIdRef.current ? data.exercise : null

        const getExerciseProps = <T extends ExerciseModel>() => ({
          data: exerciseData as T,
          onSubmitSuccess: () => setSelectedExerciseType(null),
          onCancel: () => setSelectedExerciseType(null),
          createExercise: async (exerciseData: FormData) => {
            if (studySetId) {
              try {
                const { _id } = (await StudySetApi.createStudySetExercise(studySetId, exerciseData)).data
                exerciseIdRef.current = _id
                handleSubmit()
              } catch (e) {
                Logger.log(e)
              }
            }
          },
          updateExercise: async (exerciseData: FormData) => {
            try {
              exerciseIdRef.current && (await ExerciseApi.update(exerciseData, exerciseIdRef.current))
              handleSubmit()
            } catch (e) {
              Logger.log(e)
            }
          },
          bookChild: true,
          isVideoOverlay: true
        })

        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                {videoUrl && <VideoPreview src={videoUrl} />}
                <div>
                  <Button
                    onClick={() => setShowUploadDialog(true)}
                    variant={videoUrl ? 'outlined' : 'contained'}
                    color="primary">
                    {videoUrl ? 'Replace video' : 'Add video'}
                  </Button>
                </div>
                {errors.videoId && <FormHelperText error>{errors.videoId}</FormHelperText>}
              </Grid>

              <Grid item xs>
                {exerciseData && (
                  <Box display="inline-block" marginBottom={1}>
                    <Exercise exercise={exerciseData} />
                  </Box>
                )}
                <Box display="flex" flexDirection="column" alignItems="flex-start" gridGap={8}>
                  {exerciseData ? (
                    <Button
                      onClick={() => setSelectedExerciseType(exerciseData?.type as VideoExerciseType)}
                      variant="outlined"
                      color="primary">
                      Edit exercise
                    </Button>
                  ) : (
                    <ComponentSelector<VideoExerciseOption>
                      options={exerciseOptions}
                      buttonTitle="Add exercise"
                      onAdd={(option) => setSelectedExerciseType(option.exerciseType)}
                    />
                  )}

                  {showDeleteButton && (
                    <Button
                      onClick={() => {
                        exerciseIdRef.current = undefined
                        setShowDeleteButton(false)
                      }}
                      variant="text"
                      color="primary">
                      Delete exercise
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Dialog open={showUploadDialog}>
              <DialogTitle>Add video</DialogTitle>
              <DialogContent>
                <VideoUploader
                  onUploaded={(videoData) => {
                    setFieldValue('videoId', videoData._id)
                    setOriginalVideoUrl(videoData.originalUrl)
                    setShowUploadDialog(false)
                  }}
                  onCancel={() => setShowUploadDialog(false)}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              open={!!selectedExerciseType}
              onClose={(_, reason) => reason === 'escapeKeyDown' && setSelectedExerciseType(null)}>
              <DialogContent style={{ minWidth: 600 }}>
                {selectedExerciseType === ExerciseType.ReadSentence && (
                  <ReadingPage {...getExerciseProps<ExerciseReadSentenceModel>()} />
                )}
                {selectedExerciseType === ExerciseType.MultiChoiceQuestion && (
                  <Comprehension {...getExerciseProps<ExerciseMultiChoiceQuestionModel>()} />
                )}
                {selectedExerciseType === ExerciseType.SentenceBuilder && (
                  <SentenceBuilder {...getExerciseProps<ExerciseSentenceBuilderModel>()} />
                )}
                {selectedExerciseType === ExerciseType.WordBuilder && (
                  <WordBuilder {...getExerciseProps<ExerciseWordBuilderModel>()} />
                )}
              </DialogContent>
            </Dialog>
          </>
        )
      }}
    </ComponentForm>
  )
}
