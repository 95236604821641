import { Button, FormControl, InputLabel, MenuItem, Popover, Select, Typography } from '@material-ui/core'
import axios from 'axios'
import { useFormik } from 'formik'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import CEFRBadge from '../CEFRBadge/CEFRBadge'
import { endpoints } from '../endpoints'
import { CefrLevels, POS, Result, WordSense, WORD_CEFR_LEVELS } from '../types'
import styles from './ClassifiedWord.module.scss'

const validationSchema = Yup.object().shape({
  cefr: Yup.mixed<WORD_CEFR_LEVELS>().oneOf(Object.values(WORD_CEFR_LEVELS)).nullable(),
  pos: Yup.string(),
  source: Yup.string().nullable(),
  sense: Yup.string().nullable(),
  note: Yup.string().nullable()
})

interface ClassifiedWordProps {
  result: Result
  sentence: Result[]
  onDataChange: (data: CefrLevels, newSentece: Result[]) => void
}

const ClassifiedWord = ({
  result: { level_source, pos, word_sense, tense, token, id, syllables, syllables_ipa, other_word_senses, cefr_level },
  sentence,
  onDataChange: setSentenceData
}: ClassifiedWordProps) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'classifiedWordPopper' })
  const { showAlert } = useContext(AlertContext)
  const { values, setFieldValue, submitForm, handleChange, setValues } = useFormik<{
    source?: string
    cefr: WORD_CEFR_LEVELS
    pos?: POS
    sense?: string
    note?: string
    wordSense?: WordSense
  }>({
    initialValues: { cefr: cefr_level || WORD_CEFR_LEVELS.NONE, source: '', pos: (pos as POS) || null },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newSentence = sentence.map((word) => {
        if (word.id === id) {
          return {
            ...word,
            cefr_level: values.cefr,
            pos: values.pos,
            level_source: values.source,
            word_sense: values.wordSense,
            other_word_senses: word.other_word_senses.map((wordSense) =>
              wordSense.sense === values.wordSense?.sense ? word.word_sense : wordSense
            )
          } as Result
        }
        return word
      })

      axios
        .post(endpoints.updateSentence, { payload: newSentence })
        .then((res) => {
          setSentenceData(res.data, newSentence)
          showAlert(`${token} updated succesfully.`, 'success')
        })
        .catch((e) => {
          showAlert(`${token} updated unsuccesfully.`, 'error')
        })
    }
  })

  const handleWordSenseChange = (wordSense: WordSense) => {
    const { sense, level_source, cefr_level, note } = wordSense

    setValues({ sense, source: level_source, cefr: cefr_level, note }, true)
  }

  const handleCefrChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setFieldValue('cefr', e.target.value, true)
  }
  return (
    <>
      <CEFRBadge {...bindTrigger(popupState)} cefrLevel={values.cefr}>
        {token}
      </CEFRBadge>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={styles.popperContainer}>
          <div className={styles.tokenHeader}>Word: {token}</div>
          <div className={styles.popperTextContainer}>
            <div className={styles.popperLeftContainer}>
              <div>Source:{level_source ?? 'unknown'}</div>
              <div> Word sense: {word_sense ?? 'unknown'}</div>
              <div> Syllables: {syllables ?? 'unknown'}</div>
              <div> Syllables ipa: {syllables_ipa ?? 'unknown'}</div>
              <div> Tense: {tense ?? 'unknown'}</div>
            </div>
            <div>
              <FormControl className={styles.selectControl}>
                <InputLabel>CEFR</InputLabel>
                <Select
                  value={values.cefr}
                  onChange={handleCefrChange}
                  inputProps={{
                    name: 'CEFR'
                  }}>
                  {Object.values(WORD_CEFR_LEVELS).map((cefrLevel) => (
                    <MenuItem key={cefrLevel} value={cefrLevel}>
                      {cefrLevel?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={styles.selectControl}>
                <InputLabel>POS</InputLabel>
                <Select
                  value={values.pos}
                  onChange={handleChange}
                  inputProps={{
                    name: 'pos'
                  }}>
                  {Array.from(new Set([...Object.values(POS), pos])).map((pos) => (
                    <MenuItem key={pos} value={pos}>
                      {pos.toString()?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <div className={styles.otherWordSensesContainer}>
              {other_word_senses.map((wordSense, index) => {
                return (
                  <CEFRBadge
                    onClick={() => {
                      handleWordSenseChange(wordSense)
                    }}
                    key={`${wordSense.sense}${index}`}
                    cefrLevel={wordSense.cefr_level}>
                    <div className={styles.labelText}>
                      <Typography className={styles.levelSourceText}>{wordSense.level_source}</Typography>
                      <Typography> {wordSense.sense}</Typography>
                    </div>
                  </CEFRBadge>
                )
              })}
            </div>
          </div>
          <Button onClick={() => submitForm()} color="primary" variant="contained" fullWidth type="submit">
            Submit
          </Button>
        </div>
      </Popover>
    </>
  )
}

export default ClassifiedWord
