import { BookBranding, BookType, CEFRLevels, GESELevels, ISELevels } from '@astrid/components'
import { ApiReqState, Paginated, PaginatedStatus } from '../../../shared/api/types'
import { CroppedResult } from '../../../shared/components/ImageField/ImageField'
import { ExerciseModel } from '../Exercise/types'

export interface BookState {
  collection: {
    results: BookModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
  single: {
    data?: BookModel
    reqState: ApiReqState
  }
}

export enum BOOK_ACTIONS {
  LOAD_COLLECTION = 'BOOK__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'BOOK__LOAD_COLLECTION_REQ_STATE',
  SINGLE_SET_DATA = 'BOOK__SINGLE_SET_DATA',
  SINGLE_REQ_STATE = 'BOOK__SINGLE_REQ_STATE'
}

export interface BookModel {
  _id: string
  imageUrl: string
  imageWideUrl: string
  imageOriginalUrl: string
  imageWideOriginalUrl: string
  title: string
  titleUs?: string
  description?: string
  visible: boolean
  order: number
  exercises: ExerciseModel['_id'][]
  author: string
  illustrator: string
  publisher: string
  cefrLevel?: CEFRLevels
  geseLevel?: GESELevels
  iseLevel?: ISELevels
  hashtags?: string[]
  keywords?: string[]
  totalPoints: number
  bookType: BookType
  branding: BookBranding
  hideTitle: boolean
  attribution?: string
  imageTitle?: string
  imageAttribute?: string
}

export interface Book {
  _id: string
  exercises: string[] // array of exercises ids
  imageUrl: string
  imageWideUrl: string
  imageOriginalUrl: string
  imageWideOriginalUrl: string
  title: string
  titleUs?: string
  visible: boolean
  order?: number
  author: string
  illustrator: string
  publisher: string
  difficultyLevel: number
  recommended: boolean
  totalPoints: number
  cefrLevel?: CEFRLevels
  hashtags: string[]
  keywords: string[]
  targetCountries: string[]
  isAstridClassic?: boolean
  attribution?: string
  imageTitle?: string
  imageAttribute?: string
}

export interface FormBookModel
  extends Omit<
    BookModel,
    | '_id'
    | 'imageUrl'
    | 'imageWideUrl'
    | 'exercises'
    | 'hashtags'
    | 'keywords'
    | 'order'
    | 'totalPoints'
    | 'imageOriginalUrl'
    | 'imageWideOriginalUrl'
  > {
  image: CroppedResult | { url: string; blob?: null }
  imageWide: CroppedResult | { url: string; blob?: null }
  imageWideOriginal: CroppedResult | { url: string; blob?: null }
  imageOriginal: CroppedResult | { url: string; blob?: null }
  hashtags: string
  keywords: string
  labelAdults: boolean
  labelKids: boolean
  topics: string[]
  domains: string[]
  trinityReview?: { approved: boolean }
}

export enum TargetCountries {
  UK = 'UK',
  US = 'US'
}

export const TARGET_COUNTRIES_ARRAY = Object.entries(TargetCountries).map(([key]) => key)

export interface BookLoadCollection {
  type: BOOK_ACTIONS.LOAD_COLLECTION
  collection: Paginated<BookModel>
}

export interface BookLoadCollectionReqState {
  type: BOOK_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export interface BookSingleReqState {
  type: BOOK_ACTIONS.SINGLE_REQ_STATE
  reqState: ApiReqState
}

export interface BookSingleSetData {
  type: BOOK_ACTIONS.SINGLE_SET_DATA
  data: BookModel
}

export type BookActions = BookLoadCollection | BookLoadCollectionReqState | BookSingleReqState | BookSingleSetData
