import { Box } from '@material-ui/core'
import { VolumeUp } from '@material-ui/icons'
import React from 'react'
import { ExerciseModel, ExerciseType } from '../../../store/services/Exercise/types'
import { ComponentImage } from '../../components/ComponentImage/ComponentImage'
import { ComprehensionView } from './ComprehensionView/ComprehensionView'
import { ConversationView } from './ConversationView/ConversationView'
import { ReadingPageView } from './ReadingPageView/ReadingPageView'
import { SentenceBuilderView } from './SentenceBuilderView/SentenceBuilderView'
import { WorldBuilderView } from './WordBuilderView/WordBuilderView'
import { WordPracticeView } from './WordPracticeView/WordPracticeView'

interface Props {
  exercise: ExerciseModel
}

export const Exercise = ({ exercise }: Props) => {
  return (
    <Box display="flex" gridGap={16}>
      {exercise && (
        <Box display="flex" alignItems="center" gridGap={16}>
          {exercise.imageUrl && <ComponentImage imageUrl={exercise.imageUrl} />}

          {exercise.type === ExerciseType.ReadSentence && <ReadingPageView exercise={exercise} />}
          {exercise.type === ExerciseType.WordBuilder && <WorldBuilderView exercise={exercise} />}
          {exercise.type === ExerciseType.SentenceBuilder && <SentenceBuilderView exercise={exercise} />}
          {exercise.type === ExerciseType.MultiChoiceQuestion && <ComprehensionView exercise={exercise} />}
          {exercise.type === ExerciseType.WordPractice && <WordPracticeView exercise={exercise} />}
          {exercise.type === ExerciseType.Conversation && <ConversationView exercise={exercise} />}

          {exercise.recordingUsUrl && <VolumeUp />}
        </Box>
      )}
    </Box>
  )
}
