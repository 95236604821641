import React from 'react'
import styles from './ComponentImage.module.scss'

interface Props {
  imageUrl: string
  altText?: string
}

export const ComponentImage = ({ imageUrl, altText }: Props) => {
  return (
    <div className={styles.imageContainer}>
      <img className={styles.image} alt={altText || 'Study Set image'} src={imageUrl} />
    </div>
  )
}
