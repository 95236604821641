import API from '../../../shared/api/API'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { VideoModel } from './types'

class VideoApi {
  static upload = (data: FormData, config?: AxiosRequestConfig): AxiosPromise<VideoModel> =>
    API.post('/videos', data, config)
}

export default VideoApi
