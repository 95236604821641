export enum ShelfContentType {
  MiniCourse = 'mini-course'
}

export type ShelfMiniCourseContent = {
  contentType: ShelfContentType
  contentId: string
}

export type ShelfContent = ShelfMiniCourseContent

export type ShelfApiDoc = {
  _id: string
  title: string
  contents: ShelfContent[]
  active: boolean
}

export type ShelfApiDocForList = {
  _id: string
  title: string
  active: boolean
}

export type CreateShelfBody = {
  title: string
}

export type SetShelfContentsBody = {
  contents: ShelfContent[]
}

export type UpdateShelfBody = Partial<CreateShelfBody>
