import { AUTH_ACTIONS, AuthActions, AuthCredentials, AuthLoadUser, AuthState } from './types'
import { Dispatch } from 'redux'
import AuthApi from './authApi'
import { RootState } from '../../types'
import { Logger } from '../../../shared/logger/Logger'

export const AuthInitialState: AuthState = {
  user: undefined
}

const authReducer = (state: AuthState = AuthInitialState, action: AuthActions) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOAD_DATA:
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
}

export default authReducer

// ACTIONS
export const loginUser = (data: AuthCredentials & { captchaToken: string }) => async (
  dispatch: Dispatch<AuthLoadUser>
) => {
  try {
    const { token, user } = (await AuthApi.login(data)).data
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
    AuthApi.setUserToken(token)
  } catch (err) {
    Logger.log(err)
    throw err
  }
}

export const getUser = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { user } = (await AuthApi.load()).data
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (err) {
    Logger.log(err)
    throw err
  }
}

// SELECTORS
export const selectUser = (state: RootState) => state.auth.user
export const selectAuthedUser = (state: RootState) => {
  if (!state.auth.user) {
    // Only use this method in components behind AuthWall
    throw Error('User not found')
  }
  return state.auth.user
}
