import { Button, Theme, withStyles } from '@material-ui/core'

const TertiaryButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: '#e5d9ff',
    '&:hover': {
      backgroundColor: '#CFBDFA'
    }
  }
}))(Button)

export default TertiaryButton
