export const ROUTES = {
  ROOT: '/',
  BOOK: '/book',
  EXERCISE: '/exercise',
  SETTINGS: '/settings',
  STUDENTS: '/students',
  TEACHERS: '/teachers',
  TUTORS: '/tutors',
  STUDY_SETS: '/study-sets',
  MINI_COURSE: '/mini-course',
  SHELF: '/shelf',
  SCHOOLS: '/schools',
  CERF_TOOL: '/cefrTool'
}
