import { Box } from '@material-ui/core'
import React from 'react'
import { StudySetComponentAnimationModel } from '../../../store/services/StudySet/types'

interface Props {
  data: StudySetComponentAnimationModel
}

export const Animation = ({ data }: Props) => {
  return (
    <Box display="flex" gridGap={16}>
      {data.animationName}
    </Box>
  )
}
