import { ExerciseModel, ExerciseType } from './types'

export const sortExercisesByIdsArray = (exercises: ExerciseModel[], ids: ExerciseModel['_id'][]) => {
  return ids.map((id) => exercises.find(({ _id }) => id === _id)).filter((el) => el !== undefined) as ExerciseModel[]
}

export const printExerciseContent = (exercise: ExerciseModel): string => {
  switch (exercise.type) {
    case ExerciseType.ReadSentence:
      return exercise.data.sentenceUs

    case ExerciseType.MultiChoiceQuestion:
      return exercise.data.questionUs

    case ExerciseType.SentenceBuilder:
      return exercise.data.wordOrSentenceUs

    case ExerciseType.ReadWordRace:
      return exercise.data.words.join(', ')

    case ExerciseType.WordBuilder:
      return exercise.data.wordOrSentenceUs

    case ExerciseType.WordPractice:
      return exercise.data.words.join(', ')

    case ExerciseType.Conversation:
      return exercise.data.conversationElements.map((element) => element.instructorScript || '').join(', ')
  }
}

export const getExerciseTypeDisplayName = (exerciseType: ExerciseType) => {
  switch (exerciseType) {
    case ExerciseType.ReadSentence:
      return 'Reading Page'

    case ExerciseType.MultiChoiceQuestion:
      return 'Comprehension'

    case ExerciseType.SentenceBuilder:
      return 'Sentence builder'

    case ExerciseType.ReadWordRace:
      return 'Word race'

    case ExerciseType.WordBuilder:
      return 'Word Craft'

    case ExerciseType.WordPractice:
      return 'Pronunciation Pro'

    case ExerciseType.Conversation:
      return 'Conversation Activity'
  }
}

export const getExerciseTypeInitialsName = (exercise: ExerciseModel): string => {
  switch (exercise.type) {
    case ExerciseType.ReadSentence:
      return 'RP'

    case ExerciseType.MultiChoiceQuestion:
      return 'C'

    case ExerciseType.SentenceBuilder:
      return 'SB'

    case ExerciseType.ReadWordRace:
      return 'WR'

    case ExerciseType.WordBuilder:
      return 'WC'

    case ExerciseType.WordPractice:
      return 'PP'

    case ExerciseType.Conversation:
      return 'CA'
  }
}
