import { AxiosPromise } from 'axios'
import API from '../../../shared/api/API'
import { ContentTagModel, UpdateContentTagsRequest } from './types'

type ContentRef = Pick<ContentTagModel, 'contentType' | 'contentId'>

class ContentTagApi {
  static load = ({ contentType, contentId }: ContentRef): AxiosPromise<ContentTagModel> =>
    API.get(`/content-tags/${contentType}/${contentId}`)

  static update = ({
    contentType,
    contentId,
    tags,
    trinityReview
  }: UpdateContentTagsRequest): AxiosPromise<ContentTagModel> =>
    API.put(`/content-tags/${contentType}/${contentId}`, { tags, trinityReview })

  static delete = ({ contentType, contentId }: ContentRef): AxiosPromise<void> =>
    API.delete(`/content-tags/${contentType}/${contentId}`)

  static loadAllIndustries = (): Promise<string[]> => API.get(`/content-tags/industry`).then((result) => result.data)

  static loadIndustries = ({ contentType, contentId }: ContentRef): Promise<string[]> =>
    API.get(`/content-tags/industry/${contentType}/${contentId}`).then((result) => result.data)

  static updateIndustries = ({ contentType, contentId, tags }: ContentTagModel): AxiosPromise<ContentTagModel> =>
    API.put(`/content-tags/industry/${contentType}/${contentId}`, { tags: tags })

  static loadAllDomains = (): Promise<string[]> => API.get(`/content-tags/domain`).then((result) => result.data)

  static loadDomains = ({ contentType, contentId }: ContentRef): Promise<string[]> =>
    API.get(`/content-tags/domain/${contentType}/${contentId}`).then((result) => result.data)

  static updateDomains = ({ contentType, contentId, tags }: ContentTagModel): AxiosPromise<string[]> =>
    API.put(`/content-tags/domain/${contentType}/${contentId}`, { tags: tags })
}

export default ContentTagApi
