import { CEFRLevels } from '@astrid/components'

export enum MiniCourseContentEntryType {
  Book = 'book',
  LessonTemplate = 'lesson-template'
}

export enum MiniCourseBookType {
  Reading = 'Reading',
  Listening = 'Listening'
}

export type MiniCourseContentEntry =
  | {
      type: MiniCourseContentEntryType.Book
      bookId: string
      bookType: MiniCourseBookType
    }
  | {
      type: MiniCourseContentEntryType.LessonTemplate
      lessonTemplateId: string
    }

export interface MiniCourseModel {
  _id: string
  title: string
  description: string
  imageUrl: string
  contents: MiniCourseContentEntry[]
  cefrLevel: CEFRLevels
  active: boolean
}
