import API from '../../../shared/api/API'
import { AxiosPromise } from 'axios'
import { StudentModel } from './types'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { cleanObject } from '../../../shared/helpers/cleanObject'

type StudentLoadResponse = Paginated<StudentModel>

class StudentApi {
  static load = ({ limit = 10, page = 0, sort, search }: PaginatedParams = {}): AxiosPromise<StudentLoadResponse> =>
    API.get(`/statistics/user`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $search: search?.toLocaleLowerCase()
      })
    })
}

export default StudentApi
