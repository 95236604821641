import { AxiosPromise } from 'axios'
import API from '../../../shared/api/API'
import { TopicsModel } from './types'

class TopicsApi {
  static getAll = (): AxiosPromise<string[]> => API.get(`/topics`)

  static load = ({ contentType, contentId }: Omit<TopicsModel, 'topics'>): AxiosPromise<string[]> =>
    API.get(`/topics/${contentType}/${contentId}`)

  static update = ({ contentType, contentId, topics }: TopicsModel): AxiosPromise<string[]> =>
    API.put(`/topics/${contentType}/${contentId}`, { topics })
}

export default TopicsApi
