import { BookType, CEFRLevels } from '@astrid/components'
import { ApiReqState, Paginated, PaginatedStatus } from '../../../shared/api/types'
import { ExerciseModel, ExerciseType } from '../Exercise/types'
import { VideoModel } from '../Video/types'

export interface StudySetState {
  collection: {
    results: StudySetModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
  single: {
    data?: StudySetModel
    reqState: ApiReqState
  }
}

export enum STUDY_SET_ACTIONS {
  LOAD_COLLECTION = 'STUDY_SET__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'STUDY_SET__LOAD_COLLECTION_REQ_STATE',
  SINGLE_REQ_STATE = 'STUDY_SET__SINGLE_REQ_STATE',
  SINGLE_SET_DATA = 'STUDY_SET__SINGLE_SET_DATA',
  SINGLE_DELETE_DATA = 'STUDY_SET__SINGLE_DELETE_DATA'
}

export enum StudySetComponentType {
  Book = 'book',
  Exercise = 'exercise',
  SpeakingChallenge = 'speaking-challenge',
  Video = 'video',
  Animation = 'animation'
}

export enum SectionTag {
  GetReady = 'get-ready',
  Learn = 'learn',
  Practice = 'practice',
  SpeakingChallenge = 'speaking-challenge'
}

export interface StudySetComponentModelBase {
  type: StudySetComponentType
}

export interface StudySetComponentBookModel extends StudySetComponentModelBase {
  type: StudySetComponentType.Book
  bookId: string
  bookType: BookType.Listening | BookType.Reading
}

export interface StudySetComponentSpeakingChallengeModel extends StudySetComponentModelBase {
  type: StudySetComponentType.SpeakingChallenge
  points: number
  textQuestion: string
  videoQuestion: {
    id: string
    originalUrl: string
  }
  tutorInstruction?: string
}

export interface StudySetComponentVideoModel extends StudySetComponentModelBase {
  type: StudySetComponentType.Video
  videoId: string
  video: VideoModel
  exercise?: ExerciseModel
}

export interface StudySetComponentExerciseModel extends StudySetComponentModelBase {
  type: StudySetComponentType.Exercise
  exerciseId: string
  exerciseType: ExerciseType
  sentenceType?: BookType.Listening | BookType.Reading
  exercise: ExerciseModel
}

export interface StudySetComponentAnimationModel extends StudySetComponentModelBase {
  type: StudySetComponentType.Animation
  animationName: string
}

export type StudySetComponentModel =
  | StudySetComponentBookModel
  | StudySetComponentSpeakingChallengeModel
  | StudySetComponentVideoModel
  | StudySetComponentExerciseModel
  | StudySetComponentAnimationModel

export interface StudySetExerciseModel {
  _id: string
  lessonTemplateId: string
}

export interface StudySetSection {
  sectionName: SectionTag
  components: StudySetComponentModel[]
}

export interface StudySetModel {
  _id: string
  title: string
  hideTitle: boolean
  objective: string
  framework: string
  attribution?: string
  learnerObjective: string
  cefrLevel: CEFRLevels
  imageUrl: string
  imageOriginalUrl: string
  active: boolean
  sections: StudySetSection[]
  speakingChallenge?: StudySetComponentSpeakingChallengeModel
}
export interface MoveComponentModel {
  toIndex: number
  fromIndex: number
}

// Requests
export interface StudySetComponentSpeakingChallengeRequest {
  type: StudySetComponentType.SpeakingChallenge
  textQuestion: string
  videoQuestionId: string
  points: number
  tutorInstruction?: string
}

export interface StudySetComponentVideoRequest {
  type: StudySetComponentType.Video
  videoId: string
  exerciseId?: string
}

export interface StudySetComponentExerciseRequest {
  type: StudySetComponentType.Exercise
  exerciseId: string
  sentenceType?: BookType.Listening | BookType.Reading
}

export type StudySetComponentRequest =
  | StudySetComponentSpeakingChallengeRequest
  | StudySetComponentVideoRequest
  | StudySetComponentExerciseRequest

// Actions
export interface StudySetLoadCollection {
  type: STUDY_SET_ACTIONS.LOAD_COLLECTION
  collection: Paginated<StudySetModel>
}

export interface StudySetLoadCollectionReqState {
  type: STUDY_SET_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export interface StudySetSingleReqState {
  type: STUDY_SET_ACTIONS.SINGLE_REQ_STATE
  reqState: ApiReqState
}

export interface StudySetSingleSetData {
  type: STUDY_SET_ACTIONS.SINGLE_SET_DATA
  data: StudySetModel
}

export interface StudySetSingleDeleteData {
  type: STUDY_SET_ACTIONS.SINGLE_DELETE_DATA
}

export type StudySetActions =
  | StudySetLoadCollection
  | StudySetLoadCollectionReqState
  | StudySetSingleReqState
  | StudySetSingleSetData
  | StudySetSingleDeleteData
