import React from 'react'

import {
  Button,
  CircularProgress,
  debounce,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { ArrowDropDown, Visibility, VisibilityOff } from '@material-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import DashboardToolbar from '../../Dashboard/DashboardToolbar/DashboardToolbar'
import { ROUTES } from '../../routes/routes'
import SearchField from '../../shared/components/SearchField/SearchField'
import TableLink from '../../shared/components/TableLink/TableLink'
import TableOverlay from '../../shared/components/TableOverlay/TableOverlay'
import CollectionQueryHelper from '../../shared/helpers/CollectionQueryHelper'
import MiniCourseApi from '../../store/services/MiniCourse/miniCourse.api'
import { MINI_COURSE_NEW_ID } from '../MiniCourseSingle/MiniCourseSingle'

import styles from './MiniCourseTable.module.scss'
import { MiniCourseModel } from '../../store/services/MiniCourse/miniCourse.types'

export const MINI_COURSES_PER_PAGE = 50

const MiniCourseTable = () => {
  const location = useLocation()
  const history = useHistory()
  const { data, isValidating: isLoading } = useSWR(`load?q=${location.search}`, () =>
    MiniCourseApi.load({
      limit: MINI_COURSES_PER_PAGE,
      ...CollectionQueryHelper.getParamsFromQueryString(location.search)
    })
  )

  const handleSortRequest = (key: keyof MiniCourseModel) => () => {
    const sort = CollectionQueryHelper.getToggledSortValueAsQueryString(key, location.search)
    const newParams = CollectionQueryHelper.buildQueryString(location.search, { sort: sort })
    history.replace({ search: newParams })
  }

  const handleSearchQuery = debounce((search: string) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { search }, true)
    history.replace({ search: params })
  }, 300)

  const handleChangePage = (page: number) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { page })
    history.replace({ search: params })
  }

  const { sort, search } = CollectionQueryHelper.getParamsFromQueryString(location.search)
  const miniCourses = data?.results
  const status = data?.status

  return (
    <>
      <DashboardToolbar>
        <Typography component="h1" variant="h6">
          Mini courses
        </Typography>

        <Button
          color={'primary'}
          variant={'contained'}
          className={styles.addButton}
          onClick={() => history.push(`${ROUTES.MINI_COURSE}/${MINI_COURSE_NEW_ID}`)}>
          Add new
        </Button>

        <SearchField
          containerClassName={styles.searchField}
          defaultValue={search}
          onChange={(e) => handleSearchQuery(e.target.value)}
          onClear={() => handleSearchQuery('')}
        />
      </DashboardToolbar>

      <TableContainer component={Paper} data-testid={'MiniCourseTable'}>
        {isLoading && (
          <TableOverlay>
            <CircularProgress size={40} />
          </TableOverlay>
        )}

        {!isLoading && miniCourses?.length === 0 && (
          <TableOverlay>
            <Typography color="textSecondary" align="center">
              No results.
            </Typography>
          </TableOverlay>
        )}

        <Table aria-label="customized table" className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  data-testid={'SortByFirstNameTutorLabel'}
                  active={CollectionQueryHelper.isSortActive('title', sort)}
                  direction={CollectionQueryHelper.printSortDirection('title', sort)}
                  onClick={handleSortRequest('title')}
                  IconComponent={ArrowDropDown}>
                  Title
                </TableSortLabel>
              </TableCell>

              <TableCell style={{ width: 100 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('active', sort)}
                  direction={CollectionQueryHelper.printSortDirection('active', sort)}
                  onClick={handleSortRequest('active')}
                  IconComponent={ArrowDropDown}>
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {miniCourses?.map((miniCourse) => (
              <TableRow hover key={miniCourse._id} data-testid={'StudySetListRow'}>
                <TableCell component="th" scope="row">
                  <TableLink to={`${ROUTES.MINI_COURSE}/${miniCourse._id}`}>{miniCourse.title}</TableLink>
                </TableCell>

                <TableCell>
                  <TableLink to={`${ROUTES.MINI_COURSE}/${miniCourse._id}`}>
                    {miniCourse.active ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff color="disabled" fontSize="small" />
                    )}
                  </TableLink>
                </TableCell>
              </TableRow>
            ))}
            {miniCourses?.length === 0 && (
              <TableRow className={styles.emptyRow}>
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        {status && (
          <TablePagination
            component="div"
            colSpan={3}
            count={status.total}
            rowsPerPage={MINI_COURSES_PER_PAGE}
            rowsPerPageOptions={[]}
            page={status.offset / MINI_COURSES_PER_PAGE}
            onPageChange={(_, page) => handleChangePage(page)}
          />
        )}
      </TableContainer>
    </>
  )
}

export default MiniCourseTable
