import React, { useContext } from 'react'
import { Button, Card, CardContent, MenuItem, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { MiniCourseModel } from '../../store/services/MiniCourse/miniCourse.types'

import FormFooter from '../../shared/components/FormFooter/FormFooter'
import ImageField from '../../shared/components/ImageField/ImageField'
import MultiSelector from '../../shared/components/MultiSelector/MultiSelector'
import { jsonToFormData } from '../../shared/helpers/jsonToFormData'

import styles from './MiniCourseForm.module.scss'
import { CEFRLevels } from '@astrid/components'

type Func = (args: { formData: FormData; industryTags: string[]; topicTags: string[] }) => void

interface MiniCourseFormFields {
  title: string
  description: string
  image: { url: string; blob: Blob | null }
  contents: any[]
  cefrLevel: CEFRLevels
  industryTags: string[]
  topicTags: string[]
}

interface MiniCourseFormProps {
  miniCourse?: MiniCourseModel
  disabled?: boolean
  industries: string[]
  industryTags: string[]
  topics: string[]
  topicTags: string[]
  onCreate: Func
  onUpdate: Func
  onDelete: () => void
  onPublish: () => void
  onUnpublish: () => void
}

const TITLE_MAX_LENGTH = 45

const validationSchema = Yup.object().shape({
  title: ValidationRules.required.max(TITLE_MAX_LENGTH),
  description: ValidationRules.required
})

function getInitialValues({
  miniCourse,
  industryTags,
  topicTags
}: {
  miniCourse?: MiniCourseModel
  industryTags: string[]
  topicTags: string[]
}): MiniCourseFormFields {
  return miniCourse
    ? {
        title: miniCourse.title,
        description: miniCourse.description,
        image: { url: miniCourse.imageUrl, blob: null },
        contents: [],
        cefrLevel: miniCourse.cefrLevel,
        industryTags,
        topicTags
      }
    : {
        title: '',
        description: '',
        image: { url: '', blob: null },
        contents: [],
        cefrLevel: CEFRLevels.A11,
        industryTags: [],
        topicTags: []
      }
}

const MiniCourseForm: React.FC<MiniCourseFormProps> = ({
  miniCourse,
  disabled,
  industries,
  industryTags,
  topics,
  topicTags,
  onCreate,
  onUpdate,
  onDelete,
  onPublish,
  onUnpublish
}) => {
  const { showAlert } = useContext(AlertContext)
  const isNew = !miniCourse

  const handleSubmitForm = ({
    title,
    description,
    image,
    cefrLevel,
    industryTags,
    topicTags
  }: MiniCourseFormFields) => {
    let preparedData: any = {
      title,
      description,
      cefrLevel
    }

    preparedData.image = image.blob instanceof Blob ? image.blob : image.url

    const formData = jsonToFormData(preparedData)
    if (isNew) {
      onCreate({ formData, industryTags, topicTags })
    } else {
      onUpdate({ formData, industryTags, topicTags })
    }
  }

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography display="block" variant="h5" component="h2" gutterBottom>
          {isNew ? 'Create mini course' : 'Mini course'}
        </Typography>
        <Formik<MiniCourseFormFields>
          enableReinitialize={true}
          initialValues={getInitialValues({ miniCourse, industryTags, topicTags })}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitForm}>
          {({ values, touched, errors, handleChange, handleSubmit, setFieldTouched, setFieldValue, dirty }) => {
            return (
              <>
                <TextField
                  id="title"
                  variant="outlined"
                  name="title"
                  label="Title"
                  required
                  fullWidth
                  inputProps={{ maxLength: TITLE_MAX_LENGTH }}
                  disabled={disabled}
                  size="small"
                  margin="normal"
                  value={values.title}
                  error={touched.title && !!errors.title}
                  helperText={(touched.title && errors.title) || ''}
                  onChange={handleChange('title')}
                  onBlur={() => setFieldTouched('title')}
                />

                <TextField
                  id="objective"
                  variant="outlined"
                  name="description"
                  label="Description (or Learning Objective)"
                  required
                  fullWidth
                  multiline
                  minRows={4}
                  disabled={disabled}
                  size="small"
                  margin="normal"
                  value={values.description}
                  error={touched.description && !!errors.description}
                  helperText={(touched.description && errors.description) || ''}
                  onChange={handleChange('description')}
                  onBlur={() => setFieldTouched('description')}
                />

                <div className={styles.imageWrapper}>
                  <ImageField
                    showAlert={showAlert}
                    style={{ width: 286, height: 162 }}
                    width={286}
                    height={null}
                    name="image"
                    cropperProps={{ locked: false }}
                    aspectRatio={286 / 162}
                    disabled={disabled}
                    value={values.image.url}
                    onChange={handleChange('image')}
                    onBlur={() => {
                      setFieldTouched('image')
                    }}
                  />
                </div>

                <TextField
                  id="cefrLevel"
                  name="cefrLevel"
                  label="CEFR Level"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  select
                  disabled={disabled}
                  value={values.cefrLevel}
                  onChange={handleChange('cefrLevel')}
                  onBlur={() => setFieldTouched('cefrLevel')}
                  error={touched.cefrLevel && !!errors.cefrLevel}
                  helperText={(touched.cefrLevel && errors.cefrLevel) || ''}
                  inputProps={{ 'data-testid': 'cefrLevel' }}>
                  {Object.values(CEFRLevels).map((lvl) => (
                    <MenuItem value={`${lvl}`} key={lvl}>
                      {lvl}
                    </MenuItem>
                  ))}
                </TextField>

                <MultiSelector
                  className={styles.selector}
                  label="Industries"
                  options={industries}
                  selected={values.industryTags}
                  onChange={(selected) =>
                    setFieldValue(
                      'industryTags',
                      selected.map((s) => s.trim())
                    )
                  }
                  disabled={disabled}
                />

                <MultiSelector
                  className={styles.selector}
                  label="Topics"
                  options={topics}
                  selected={values.topicTags}
                  onChange={(selected) =>
                    setFieldValue(
                      'topicTags',
                      selected.map((s) => s.trim())
                    )
                  }
                  disabled={disabled}
                />

                <FormFooter>
                  {isNew ? (
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid={'submitBookBtn'}
                      onClick={() => {
                        handleSubmit()
                      }}>
                      Create course
                    </Button>
                  ) : (
                    <>
                      {miniCourse?.active ? (
                        <Button variant="contained" color="primary" onClick={onUnpublish}>
                          Unpublish
                        </Button>
                      ) : (
                        <>
                          <Button variant="outlined" color="primary" onClick={onDelete}>
                            Delete
                          </Button>

                          <Button variant="outlined" color="primary" onClick={onPublish}>
                            Publish
                          </Button>

                          <Button
                            disabled={!dirty}
                            variant="contained"
                            color="primary"
                            data-testid={'submitBookBtn'}
                            onClick={() => {
                              handleSubmit()
                            }}>
                            Update
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </FormFooter>
              </>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default MiniCourseForm
