import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import * as Yup from 'yup'
import VideoUploader from '../../../shared/components/VideoUploader/VideoUploader'
import { ValidationRules } from '../../../shared/helpers/ValidationRules'
import {
  StudySetComponentSpeakingChallengeModel,
  StudySetComponentSpeakingChallengeRequest,
  StudySetComponentType
} from '../../../store/services/StudySet/types'
import { ComponentForm } from '../../components/ComponentForm/ComponentForm'
import { ComponentFormProps } from '../../types'
import { VideoPreview } from '../../components/VideoPreview/VideoPreview'

const validationSchema = Yup.object().shape({
  videoQuestionId: ValidationRules.required,
  textQuestion: ValidationRules.required,
  tutorInstruction: Yup.string().optional(),
  points: ValidationRules.points
})

type Props = ComponentFormProps<StudySetComponentSpeakingChallengeModel, StudySetComponentSpeakingChallengeRequest>

export const SpeakingChallengeForm = ({ data, onCancel, onSave }: Props) => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(data.videoQuestion?.originalUrl)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)

  const handleSubmit = (values: {
    tutorInstruction: string
    textQuestion: string
    videoQuestionId: string
    points: number
  }): StudySetComponentSpeakingChallengeRequest => {
    return {
      type: StudySetComponentType.SpeakingChallenge,
      tutorInstruction: values.tutorInstruction,
      videoQuestionId: values.videoQuestionId,
      textQuestion: values.textQuestion,
      points: values.points
    }
  }

  return (
    <ComponentForm
      validationSchema={validationSchema}
      initialValues={{
        tutorInstruction: data.tutorInstruction || '',
        videoQuestionId: data.videoQuestion?.id || '',
        textQuestion: data.textQuestion || '',
        points: data.points || 100
      }}
      onSubmitForm={handleSubmit}
      onCancel={onCancel}
      onSave={onSave}>
      {({ values, touched, errors, handleChange, setFieldValue, setFieldTouched }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {videoUrl && <VideoPreview src={videoUrl} />}
                <div>
                  <Button
                    onClick={() => setShowUploadDialog(true)}
                    variant={videoUrl ? 'outlined' : 'contained'}
                    color="primary">
                    {videoUrl ? 'Replace video' : 'Add video'}
                  </Button>
                </div>
                {errors.videoQuestionId && <FormHelperText error>{errors.videoQuestionId}</FormHelperText>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="points"
                  label="Stars"
                  id="points"
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="small"
                  onChange={handleChange('points')}
                  value={values.points}
                  error={touched.points && !!errors.points}
                  helperText={(touched.points && errors.points) || ''}
                  onBlur={() => setFieldTouched('points')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="textQuestion"
                  label="Question to the learner"
                  id="textQuestion"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  onChange={handleChange('textQuestion')}
                  value={values.textQuestion}
                  error={touched.textQuestion && !!errors.textQuestion}
                  helperText={(touched.textQuestion && errors.textQuestion) || ''}
                  onBlur={() => setFieldTouched('textQuestion')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="instruction"
                  label="Instructions for the tutor"
                  id="instruction"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  onChange={handleChange('tutorInstruction')}
                  value={values.tutorInstruction}
                  error={touched.tutorInstruction && !!errors.tutorInstruction}
                  helperText={(touched.tutorInstruction && errors.tutorInstruction) || ''}
                  onBlur={() => setFieldTouched('tutorInstruction')}
                />
              </Grid>
            </Grid>
            <Dialog open={showUploadDialog}>
              <DialogTitle>Add video</DialogTitle>
              <DialogContent>
                <VideoUploader
                  onUploaded={(videoData) => {
                    setFieldValue('videoQuestionId', videoData._id)
                    setVideoUrl(videoData.originalUrl)
                    setShowUploadDialog(false)
                  }}
                  onCancel={() => setShowUploadDialog(false)}
                />
              </DialogContent>
            </Dialog>
          </>
        )
      }}
    </ComponentForm>
  )
}
