import { Card, CardContent } from '@material-ui/core'
import React from 'react'
import { ExerciseReadSentenceModel } from '../../../../store/services/Exercise/types'

interface Props {
  exercise: ExerciseReadSentenceModel
}

export const ReadingPageView = ({ exercise }: Props) => {
  return (
    <Card>
      <CardContent>{exercise.data.sentenceUs}</CardContent>
    </Card>
  )
}
