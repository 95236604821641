import * as React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import styles from './TableLink.module.scss'

const TableLink = ({ to, children }: LinkProps) => {
  return (
    <Link className={styles.link} to={to}>
      {children}
    </Link>
  )
}

export default TableLink
