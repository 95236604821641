import React from 'react'

import {
  Button,
  CircularProgress,
  debounce,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { ArrowDropDown, Visibility, VisibilityOff } from '@material-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import DashboardToolbar from '../../Dashboard/DashboardToolbar/DashboardToolbar'
import { ROUTES } from '../../routes/routes'
import SearchField from '../../shared/components/SearchField/SearchField'
import TableLink from '../../shared/components/TableLink/TableLink'
import TableOverlay from '../../shared/components/TableOverlay/TableOverlay'
import CollectionQueryHelper from '../../shared/helpers/CollectionQueryHelper'
import { SHELF_NEW_ID } from '../ShelfSingle/ShelfSingle'

import styles from './ShelfTable.module.scss'
import ShelfApi from '../../store/services/Shelf/shelf.api'
import { ShelfApiDocForList } from '../../store/services/Shelf/shelf.types'

export const PAGE_SIZE = 50

const ShelfTable = () => {
  const location = useLocation()
  const history = useHistory()
  const { data, isValidating: isLoading } = useSWR(`load?q=${location.search}`, () =>
    ShelfApi.load({
      limit: PAGE_SIZE,
      ...CollectionQueryHelper.getParamsFromQueryString(location.search)
    })
  )

  const handleSortRequest = (key: keyof ShelfApiDocForList) => () => {
    const sort = CollectionQueryHelper.getToggledSortValueAsQueryString(key, location.search)
    const newParams = CollectionQueryHelper.buildQueryString(location.search, { sort: sort })
    history.replace({ search: newParams })
  }

  const handleSearchQuery = debounce((search: string) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { search }, true)
    history.replace({ search: params })
  }, 300)

  const handleChangePage = (page: number) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { page })
    history.replace({ search: params })
  }

  const { sort, search } = CollectionQueryHelper.getParamsFromQueryString(location.search)
  const shelves = data?.results
  const status = data?.status

  return (
    <>
      <DashboardToolbar>
        <Typography component="h1" variant="h6">
          Shelves
        </Typography>

        <Button
          color={'primary'}
          variant={'contained'}
          className={styles.addButton}
          onClick={() => history.push(`${ROUTES.SHELF}/${SHELF_NEW_ID}`)}>
          Add new
        </Button>

        <SearchField
          containerClassName={styles.searchField}
          defaultValue={search}
          onChange={(e) => handleSearchQuery(e.target.value)}
          onClear={() => handleSearchQuery('')}
        />
      </DashboardToolbar>

      <TableContainer component={Paper} data-testid="ShelfTable">
        {isLoading && (
          <TableOverlay>
            <CircularProgress size={40} />
          </TableOverlay>
        )}

        {!isLoading && shelves?.length === 0 && (
          <TableOverlay>
            <Typography color="textSecondary" align="center">
              No results.
            </Typography>
          </TableOverlay>
        )}

        <Table aria-label="customized table" className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('title', sort)}
                  direction={CollectionQueryHelper.printSortDirection('title', sort)}
                  onClick={handleSortRequest('title')}
                  IconComponent={ArrowDropDown}>
                  Title
                </TableSortLabel>
              </TableCell>

              <TableCell style={{ width: 100 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('active', sort)}
                  direction={CollectionQueryHelper.printSortDirection('active', sort)}
                  onClick={handleSortRequest('active')}
                  IconComponent={ArrowDropDown}>
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {shelves?.map((shelf) => (
              <TableRow hover key={shelf._id}>
                <TableCell component="th" scope="row">
                  <TableLink to={`${ROUTES.SHELF}/${shelf._id}`}>{shelf.title}</TableLink>
                </TableCell>

                <TableCell>
                  <TableLink to={`${ROUTES.SHELF}/${shelf._id}`}>
                    {shelf.active ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff color="disabled" fontSize="small" />
                    )}
                  </TableLink>
                </TableCell>
              </TableRow>
            ))}
            {shelves?.length === 0 && (
              <TableRow className={styles.emptyRow}>
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        {status && (
          <TablePagination
            component="div"
            colSpan={3}
            count={status.total}
            rowsPerPage={PAGE_SIZE}
            rowsPerPageOptions={[]}
            page={status.offset / PAGE_SIZE}
            onPageChange={(_, page) => handleChangePage(page)}
          />
        )}
      </TableContainer>
    </>
  )
}

export default ShelfTable
