import { Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectBooks } from '../../../store/services/Book/bookReducer'
import { BookModel } from '../../../store/services/Book/types'
import { StudySetComponentBookModel } from '../../../store/services/StudySet/types'
import { RootState } from '../../../store/types'
import { ComponentImage } from '../../components/ComponentImage/ComponentImage'
import styles from './Book.module.scss'

interface Props {
  data: StudySetComponentBookModel
}

export const Book = ({ data }: Props) => {
  const books = useSelector<RootState, BookModel[]>((state) => selectBooks(state))
  const book = books.find((book) => book._id === data.bookId)

  return (
    <div className={styles.book}>
      {book ? (
        <>
          <div>
            <ComponentImage imageUrl={book.imageUrl} altText={book.title} />
          </div>
          <div className={styles.bookInfo}>
            <Typography variant="h4" className={styles.title}>
              {book.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {book.author}
            </Typography>
          </div>
        </>
      ) : (
        <Typography>Book ID: {data.bookId}</Typography>
      )}
    </div>
  )
}
