import ApiService from './ApiService'

export const ACCESS_TOKEN_KEY = 'authorization'

const API = new ApiService({
  API_URL: process.env.REACT_APP_API_URL,
  DEFAULT_HTTP_HEADERS: {
    'Content-Type': 'application/json'
  },
  ACCESS_TOKEN_KEY: ACCESS_TOKEN_KEY
}).service

export default API
