import React from 'react'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

interface Props {
  label: string
  options: string[]
  selected: string[]
  onChange: (selected: string[]) => void
  disabled?: boolean
  className?: string
}

const MultiSelector = ({ label, options, selected, disabled, className, onChange }: Props) => {
  return (
    <Autocomplete
      className={className}
      multiple
      freeSolo
      id="combobox"
      disabled={disabled}
      options={options}
      value={selected}
      onChange={(_, value) => onChange(value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
      }
      renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
    />
  )
}

export default MultiSelector
