import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import * as Yup from 'yup'
import { ValidationRules } from '../../../shared/helpers/ValidationRules'
import { StudySetComponentAnimationModel, StudySetComponentType } from '../../../store/services/StudySet/types'
import { ComponentForm } from '../../components/ComponentForm/ComponentForm'
import { ComponentFormProps } from '../../types'

enum Animations {
  READY_FOR_NEXT_ACTIVITY = 'ready for next activity',
  READ_OUT_LOUD = 'read out loud',
  READY_FOR_SPEAKING_CHALLENGE = 'ready for speaking challenge'
}

const validationSchema = Yup.object().shape({
  animationName: ValidationRules.required
})

export const AnimationForm = ({ data, onCancel, onSave }: ComponentFormProps<StudySetComponentAnimationModel>) => {
  const handleSubmit = (values: { animationName: string }): StudySetComponentAnimationModel => {
    return {
      type: StudySetComponentType.Animation,
      animationName: values.animationName
    }
  }

  return (
    <ComponentForm
      validationSchema={validationSchema}
      initialValues={{
        animationName: data.animationName || ''
      }}
      onSubmitForm={handleSubmit}
      onCancel={onCancel}
      onSave={onSave}>
      {({ values, touched, errors, handleChange, setFieldTouched }) => {
        return (
          <TextField
            name="animationName"
            label="Animation"
            id="animationName"
            variant="outlined"
            fullWidth
            select
            required
            size="small"
            onChange={handleChange('animationName')}
            value={values.animationName}
            error={touched.animationName && !!errors.animationName}
            helperText={(touched.animationName && errors.animationName) || ''}
            onBlur={() => setFieldTouched('animationName')}>
            {Object.values(Animations).map((animation) => (
              <MenuItem value={animation} key={animation}>
                {animation}
              </MenuItem>
            ))}
          </TextField>
        )
      }}
    </ComponentForm>
  )
}
