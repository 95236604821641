import { BookBranding, BookType, CEFRLevels, GESELevels, ISELevels } from '@astrid/components'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@material-ui/core'
import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import useSWR from 'swr'
import * as Yup from 'yup'
import emptyCoverUrl from '../../assets/empty_448x448.png'
import { ROUTES } from '../../routes/routes'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import FormFooter from '../../shared/components/FormFooter/FormFooter'
import ImageField from '../../shared/components/ImageField/ImageField'
import MultiSelector from '../../shared/components/MultiSelector/MultiSelector'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { jsonToFormData } from '../../shared/helpers/jsonToFormData'
import BookApi from '../../store/services/Book/bookApi'
import {
  createBookSingle,
  publishBookSingle,
  unpublishBookSingle,
  updateBookSingle
} from '../../store/services/Book/bookReducer'
import { BookModel, FormBookModel } from '../../store/services/Book/types'
import ContentTagApi from '../../store/services/ContentTag/contentTagApi'
import { deleteContentTags, updateContentTags } from '../../store/services/ContentTag/contentTagReducer'
import { CONTENT_TAG_AGE_OPTIONS, ContentTagModel, ContentType } from '../../store/services/ContentTag/types'
import { updateDomains } from '../../store/services/Domains/domainsReducer'
import TopicsApi from '../../store/services/Topics/topicsApi'
import { updateTopics } from '../../store/services/Topics/topicsReducer'
import { RootState } from '../../store/types'
import styles from './BookForm.module.scss'
import { IseLevelLabels } from '../../shared/helpers/enumLabels'

const validationSchema = Yup.object().shape({
  titleUs: ValidationRules.required,
  author: ValidationRules.requiredName,
  illustrator: ValidationRules.requiredName,
  publisher: ValidationRules.requiredName,
  cefrLevel: ValidationRules.cefrLevel,
  bookType: ValidationRules.required,
  imageTitle: ValidationRules.optionalWithMaxLength,
  imageAttribute: ValidationRules.optionalWithMaxLength
})

interface Props {
  data?: BookModel
  contentTags?: ContentTagModel
  topics?: string[]
  domains?: string[]
  onEditable: (editable: boolean) => void
}
enum DialogType {
  DeleteStory = 'delete',
  PublishStory = 'publish',
  UnpublishStory = 'unpublish'
}

const BookForm: React.FC<Props> = ({ data, contentTags, topics, domains, onEditable }) => {
  const { showAlert } = useContext(AlertContext)
  const history = useHistory()
  const { data: allTopics } = useSWR('all-topics', () => TopicsApi.getAll().then((res) => res.data))
  const { data: allDomains } = useSWR('all-domains', () => ContentTagApi.loadAllDomains())
  const isNew = !data

  const [editable, setEditableState] = useState(isNew)
  const setEditable = (editable: boolean) => {
    setEditableState(editable)
    onEditable(editable)
  }

  const [isDuplicating, setDuplicating] = useState(false)
  const [isCopying, setCopying] = useState(false)
  const [dialogType, setDialogType] = useState<DialogType | null>(null)
  useEffect(() => {
    setEditable(isNew)
    // eslint-disable-next-line
  }, [isNew])

  const disableFields = !editable

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()

  const submitForm = useCallback(
    async (
      {
        image,
        imageOriginal,
        imageWide,
        imageWideOriginal,
        title,
        titleUs,
        description,
        author,
        publisher,
        illustrator,
        hashtags,
        keywords,
        cefrLevel,
        geseLevel,
        iseLevel,
        bookType,
        visible,
        hideTitle,
        branding,
        attribution,
        imageAttribute,
        imageTitle,
        labelAdults,
        labelKids,
        topics,
        domains,
        trinityReview
      }: FormBookModel,
      { setSubmitting }: Omit<FormikHelpers<FormBookModel>, 'setFormikState'>
    ) => {
      const preparedData: any = {
        title,
        titleUs,
        description,
        author,
        publisher,
        illustrator,
        bookType,
        hideTitle,
        branding,
        cefrLevel: cefrLevel || null,
        hashtags: (hashtags as string).split(',').map((phrase: string) => phrase.trim()),
        keywords: (keywords as string).split(',').map((phrase: string) => phrase.trim()),
        visible,
        attribution,
        imageTitle,
        imageAttribute
      }
      // don't pass tclp levels if not set
      if (geseLevel) preparedData.geseLevel = geseLevel
      if (iseLevel) preparedData.iseLevel = iseLevel

      const tags = getTagList({
        [CONTENT_TAG_AGE_OPTIONS.ADULT]: labelAdults,
        [CONTENT_TAG_AGE_OPTIONS.KID]: labelKids
      })

      const images = [
        { field: 'image', imageObj: image },
        { field: 'imageWide', imageObj: imageWide },
        { field: 'imageOriginal', imageObj: imageOriginal },
        { field: 'imageWideOriginal', imageObj: imageWideOriginal }
      ]
      const allImageFieldsPopulated = images.every((img) => img.imageObj.blob || img.imageObj.url)
      const fallbackImage = allImageFieldsPopulated ? null : await (await fetch(emptyCoverUrl)).blob()

      for (const { field, imageObj } of images) {
        if (imageObj.blob instanceof Blob) {
          preparedData[field] = imageObj.blob
        } else if (!imageObj.url) {
          preparedData[field] = fallbackImage
        }
      }

      try {
        if (isNew) {
          const bookData = ((await dispatch(createBookSingle(jsonToFormData(preparedData)))) as unknown) as BookModel
          await dispatch(
            updateContentTags({ contentId: bookData._id, contentType: ContentType.Book, tags, trinityReview })
          )
          // wait for updateContentTags since content tags also include topics
          await dispatch(updateTopics({ contentId: bookData._id, contentType: ContentType.Book, topics }))
          await dispatch(updateDomains({ contentId: bookData._id, contentType: ContentType.Book, domains }))
          history.push(`${ROUTES.BOOK}/${bookData._id}`)
        } else {
          const preparedDataWithExercises = { ...preparedData, exercises: data!.exercises }

          await dispatch(updateBookSingle(jsonToFormData(preparedDataWithExercises), data!._id))
          // ⚠️⚠️⚠️ updateContentTags will remove the domain and topic tags, so we need to call updateTopics & updateDomains
          // TODO: https://github.com/astrideducation/astrid/issues/4038
          await dispatch(
            updateContentTags({ contentId: data!._id, contentType: ContentType.Book, tags, trinityReview })
          )
          await dispatch(updateTopics({ contentId: data!._id, contentType: ContentType.Book, topics }))
          await dispatch(updateDomains({ contentId: data!._id, contentType: ContentType.Book, domains }))

          setEditable(false)
          showAlert('Book updated succesfully', 'success')
        }
      } catch (error) {
        showAlert(printApiMessage(error))
      } finally {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const getTagList = (ageTags: {
    [CONTENT_TAG_AGE_OPTIONS.ADULT]: boolean
    [CONTENT_TAG_AGE_OPTIONS.KID]: boolean
  }) => {
    const tagObj: { [key: string]: boolean } = {}
    const currentTags = contentTags?.tags || []
    currentTags.forEach((tag) => {
      tagObj[tag] = true
    })
    tagObj[CONTENT_TAG_AGE_OPTIONS.ADULT] = ageTags[CONTENT_TAG_AGE_OPTIONS.ADULT]
    tagObj[CONTENT_TAG_AGE_OPTIONS.KID] = ageTags[CONTENT_TAG_AGE_OPTIONS.KID]

    return Object.keys(tagObj).filter((tag) => tagObj[tag])
  }

  const submitPublish = useCallback(
    async (setSubmitting) => {
      try {
        await dispatch(publishBookSingle(data!._id))
      } catch (error) {
        showAlert(printApiMessage(error))
      } finally {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const submitUnpublish = useCallback(
    async (setSubmitting) => {
      try {
        await dispatch(unpublishBookSingle(data!._id))
      } catch (error) {
        showAlert(printApiMessage(error))
      } finally {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const submitDelete = useCallback(
    async (setSubmitting) => {
      try {
        await Promise.all([
          BookApi.delete(data!._id),
          dispatch(deleteContentTags({ contentId: data!._id, contentType: ContentType.Book }))
        ])
        history.push(`${ROUTES.ROOT}`)
      } catch (error) {
        showAlert(printApiMessage(error))
      } finally {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const submitDuplicate = useCallback(
    async (setDuplicating) => {
      try {
        setDuplicating(true)
        const response = await BookApi.duplicate(data!._id)
        history.push(`${ROUTES.BOOK}/${response.data._id}`)
      } catch (error) {
        showAlert(printApiMessage(error))
      } finally {
        setDuplicating(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const submitCopying = useCallback(
    async (setCopying) => {
      try {
        setCopying(true)
        await BookApi.copy(data!._id)
        showAlert('Book copied successfully!', 'success')
      } catch (error) {
        showAlert(printApiMessage(error))
      } finally {
        setCopying(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        isNew
          ? {
              image: {
                url: ''
              },
              imageWide: {
                url: ''
              },
              imageOriginal: {
                url: ''
              },
              imageWideOriginal: {
                url: ''
              },
              title: '',
              titleUs: '',
              description: '',
              author: 'Astrid',
              illustrator: 'Astrid',
              publisher: 'Astrid',
              difficultyLevel: 0,
              hashtags: '',
              keywords: '',
              cefrLevel: '' as CEFRLevels,
              geseLevel: '' as GESELevels,
              iseLevel: '' as ISELevels,
              visible: false,
              bookType: BookType.Mix,
              isAstridClassic: false,
              branding: BookBranding.NONE,
              hideTitle: false,
              imageTitle: '',
              imageAttribute: '',
              attribution: '',
              labelAdults: true,
              labelKids: false,
              topics: [],
              domains: [],
              trinityReview: undefined
            }
          : {
              image: { url: data?.imageUrl || '', blob: null },
              imageWide: { url: data?.imageWideUrl || '', blob: null },
              imageWideOriginal: { url: data?.imageWideOriginalUrl || '', blob: null },
              imageOriginal: { url: data?.imageOriginalUrl || '', blob: null },
              title: data?.title || '',
              titleUs: data?.titleUs || '',
              description: data?.description || '',
              author: data?.author || '',
              illustrator: data?.illustrator || '',
              publisher: data?.publisher || '',
              cefrLevel: data?.cefrLevel || ('' as CEFRLevels),
              geseLevel: data?.geseLevel || ('' as GESELevels),
              iseLevel: data?.iseLevel || ('' as ISELevels),
              hashtags: data?.hashtags?.join(',') || '',
              keywords: data?.keywords?.join(',') || '',
              visible: data?.visible || false,
              bookType: data?.bookType || ('' as BookType),
              branding: data?.branding || BookBranding.NONE,
              hideTitle: !!data?.hideTitle,
              imageTitle: data?.imageTitle || '',
              imageAttribute: data?.imageAttribute || '',
              attribution: data?.attribution || '',
              labelAdults: contentTags?.tags.includes(CONTENT_TAG_AGE_OPTIONS.ADULT) ?? false,
              labelKids: contentTags?.tags.includes(CONTENT_TAG_AGE_OPTIONS.KID) ?? false,
              topics: topics || [],
              domains: domains || [],
              trinityReview: contentTags?.trinityReview
            }
      }
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values, { setFormikState, ...actions }) => {
        submitForm(values, actions as FormikHelpers<FormBookModel>)
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        isSubmitting,
        dirty,
        setSubmitting,
        resetForm,
        handleBlur
      }) => {
        return (
          <>
            <div>
              <div className={styles.imageWrapper}>
                <span>Carousel Image</span>
                <ImageField
                  showAlert={showAlert}
                  data-testid="bookCoverImage"
                  name="image"
                  style={{ width: 240, height: 224 }}
                  value={values.image.url}
                  onChange={handleChange('image')}
                  onOriginalChange={handleChange('imageOriginal')}
                  originalValue={values.imageOriginal.url}
                  disabled={disableFields}
                  onBlur={() => {
                    setFieldTouched('image')
                    setFieldTouched('imageOriginal')
                  }}
                  cropperProps={{ locked: false }}
                  aspectRatio={240 / 224}
                  width={220}
                  height={null}
                />
              </div>
              <div className={styles.imageWrapper}>
                <span>Title Page Image</span>
                <ImageField
                  aspectRatio={343 / 224}
                  showAlert={showAlert}
                  data-testid="bookWideImage"
                  name="imageWide"
                  style={{ width: 343, height: 224 }}
                  value={values.imageWide.url}
                  originalValue={values.imageWideOriginal.url}
                  onChange={handleChange('imageWide')}
                  onOriginalChange={handleChange('imageWideOriginal')}
                  disabled={disableFields}
                  onBlur={() => setFieldTouched('imageWide')}
                  cropperProps={{ locked: false }}
                  width={320}
                  height={null}
                />
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="title"
                label="UK title"
                name="title"
                size="small"
                onChange={handleChange('title')}
                value={values.title}
                error={touched.title && !!errors.title}
                helperText={(touched.title && errors.title) || ''}
                onBlur={() => setFieldTouched('title')}
                disabled={disableFields}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="titleUs"
                label="US title"
                name="titleUs"
                size="small"
                onChange={handleChange('titleUs')}
                value={values.titleUs}
                error={touched.titleUs && !!errors.titleUs}
                helperText={(touched.titleUs && errors.titleUs) || ''}
                onBlur={() => setFieldTouched('titleUs')}
                disabled={disableFields}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="description"
                label="Description"
                name="description"
                size="small"
                onChange={handleChange('description')}
                value={values.description}
                error={touched.description && !!errors.description}
                helperText={(touched.description && errors.description) || ''}
                onBlur={() => setFieldTouched('description')}
                disabled={disableFields}
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth margin="normal" size="small" disabled={disableFields}>
                    <InputLabel id="brandingLabel">Branding</InputLabel>
                    <Select
                      labelId="brandingLabel"
                      name="branding"
                      id="branding"
                      value={values.branding}
                      onChange={handleChange}
                      label="Branding">
                      <MenuItem value={''}>
                        <em>Unset</em>
                      </MenuItem>
                      {Object.values(BookBranding).map((lvl) => (
                        <MenuItem value={`${lvl}`} key={lvl}>
                          {lvl}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <div className={styles.switchWrapper}>
                    <FormControlLabel
                      control={
                        <Switch color="primary" checked={values.hideTitle} onChange={handleChange('hideTitle')} />
                      }
                      label="Hide title"
                      disabled={disableFields}
                    />
                  </div>
                </Grid>
              </Grid>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="author"
                label="Author"
                name="author"
                size="small"
                onChange={handleChange('author')}
                value={values.author}
                error={touched.author && !!errors.author}
                helperText={(touched.author && errors.author) || ''}
                onBlur={() => setFieldTouched('author')}
                disabled={disableFields}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="illustrator"
                    label="Illustrator"
                    name="illustrator"
                    size="small"
                    onChange={handleChange('illustrator')}
                    value={values.illustrator}
                    error={touched.illustrator && !!errors.illustrator}
                    helperText={(touched.illustrator && errors.illustrator) || ''}
                    onBlur={() => setFieldTouched('illustrator')}
                    disabled={disableFields}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="publisher"
                    label="Publisher"
                    name="publisher"
                    size="small"
                    onChange={handleChange('publisher')}
                    value={values.publisher}
                    error={touched.publisher && !!errors.publisher}
                    helperText={(touched.publisher && errors.publisher) || ''}
                    onBlur={() => setFieldTouched('publisher')}
                    disabled={disableFields}
                  />
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="imageTitle"
                label="Image Title"
                name="imageTitle"
                size="small"
                onChange={handleChange('imageTitle')}
                value={values.imageTitle}
                error={touched.imageTitle && !!errors.imageTitle}
                helperText={(touched.imageTitle && errors.imageTitle) || ''}
                onBlur={() => setFieldTouched('imageTitle')}
                disabled={disableFields}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="imageAttribute"
                label="Image Attribution"
                name="imageAttribute"
                size="small"
                onChange={handleChange('imageAttribute')}
                value={values.imageAttribute}
                error={touched.imageAttribute && !!errors.imageAttribute}
                helperText={(touched.imageAttribute && errors.imageAttribute) || ''}
                onBlur={() => setFieldTouched('imageAttribute')}
                disabled={disableFields}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                minRows={3}
                id="attribution"
                label="Attribution"
                name="attribution"
                size="small"
                onChange={handleChange('attribution')}
                value={values.attribution}
                error={touched.attribution && !!errors.attribution}
                helperText={(touched.attribution && errors.attribution) || ''}
                onBlur={() => setFieldTouched('attribution')}
                disabled={disableFields}
              />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    name="cefrLevel"
                    label="CEFR Level"
                    required
                    id="cefrLevel"
                    variant="outlined"
                    fullWidth
                    select
                    margin="normal"
                    value={values.cefrLevel}
                    onChange={handleChange}
                    disabled={disableFields}
                    size="small"
                    onBlur={() => setFieldTouched('cefrLevel')}
                    error={touched.cefrLevel && !!errors.cefrLevel}
                    helperText={(touched.cefrLevel && errors.cefrLevel) || ''}
                    inputProps={{ 'data-testid': 'cefrLevel' }}>
                    {Object.values(CEFRLevels).map((lvl) => (
                      <MenuItem value={`${lvl}`} key={lvl}>
                        {lvl}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="geseLevel"
                    label="GESE Level"
                    id="geseLevel"
                    variant="outlined"
                    fullWidth
                    select
                    margin="normal"
                    value={values.geseLevel}
                    onChange={handleChange}
                    disabled={disableFields}
                    size="small"
                    onBlur={() => setFieldTouched('geseLevel')}
                    inputProps={{ 'data-testid': 'geseLevel' }}>
                    <MenuItem value={''}>
                      <em>Unset</em>
                    </MenuItem>
                    {Object.values(GESELevels).map((lvl) => (
                      <MenuItem value={lvl} key={lvl}>
                        {lvl}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="iseLevel"
                    label="ISE Level"
                    id="iseLevel"
                    variant="outlined"
                    fullWidth
                    select
                    margin="normal"
                    value={values.iseLevel}
                    onChange={handleChange}
                    disabled={disableFields}
                    size="small"
                    onBlur={() => setFieldTouched('iseLevel')}
                    inputProps={{ 'data-testid': 'iseLevel' }}>
                    <MenuItem value={''}>
                      <em>Unset</em>
                    </MenuItem>
                    {Object.values(ISELevels).map((lvl) => (
                      <MenuItem value={lvl} key={lvl}>
                        {IseLevelLabels[lvl]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={7}>
                  <FormControlLabel
                    className={styles.label}
                    control={
                      <Checkbox color="primary" checked={values.labelAdults} onChange={handleChange('labelAdults')} />
                    }
                    label="Adults"
                    disabled={disableFields}
                  />
                  <FormControlLabel
                    className={styles.label}
                    control={
                      <Checkbox color="primary" checked={values.labelKids} onChange={handleChange('labelKids')} />
                    }
                    label="Kids"
                    disabled={disableFields}
                  />
                  {values.trinityReview ? (
                    <FormControlLabel
                      className={styles.label}
                      control={
                        <Checkbox
                          color="primary"
                          checked={values.trinityReview.approved}
                          onChange={(event, checked) => {
                            setFieldValue('trinityReview', { approved: checked })
                          }}
                        />
                      }
                      label="Trinity Approved"
                      disabled={disableFields || !values.trinityReview.editable}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={5}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    disabled={disableFields}
                    error={touched.bookType && !!errors.bookType}>
                    <InputLabel id="bookTypeLabel">Book type</InputLabel>
                    <Select
                      labelId="bookTypeLabel"
                      name="bookType"
                      id="bookType"
                      value={values.bookType}
                      onChange={handleChange}
                      data-testid="bookType"
                      onBlur={handleBlur('bookType')}
                      label="Book type">
                      {Object.values(BookType).map((type) => (
                        <MenuItem value={`${type}`} key={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.bookType && errors.bookType && <FormHelperText>{errors.bookType}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <MultiSelector
                    className={styles.multiSelector}
                    label="Topics"
                    options={allTopics || []}
                    selected={values.topics}
                    onChange={(selected) => setFieldValue('topics', selected)}
                    disabled={disableFields}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiSelector
                    className={styles.multiSelector}
                    label="Domains"
                    options={allDomains || []}
                    selected={values.domains}
                    onChange={(selected) => setFieldValue('domains', selected)}
                    disabled={disableFields}
                  />
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="hashtags"
                label="Add tags"
                name="hashtags"
                size="small"
                helperText="Separate with comma animal,lion,sun"
                onChange={handleChange('hashtags')}
                value={values.hashtags}
                onBlur={() => setFieldTouched('hashtags')}
                disabled={disableFields}
              />
              <TextField
                variant="outlined"
                fullWidth
                id="keywords"
                label="Add Keywords"
                name="keywords"
                size="small"
                helperText="Separate with comma animal,lion,sun"
                onChange={handleChange('keywords')}
                value={values.keywords}
                onBlur={() => setFieldTouched('keywords')}
                disabled={disableFields}
              />
              <FormFooter>
                {isNew ? (
                  <Button
                    variant="contained"
                    color="primary"
                    data-testid={'submitBookBtn'}
                    onClick={() => {
                      handleSubmit()
                    }}
                    disabled={!dirty || isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Save New Book'}
                  </Button>
                ) : (
                  <>
                    {editable && (
                      <>
                        <Button
                          className={styles.negativeButton}
                          variant="outlined"
                          onClick={() => {
                            setEditable(false)
                            resetForm()
                          }}>
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          data-testid={'submitBookBtn'}
                          onClick={() => {
                            handleSubmit()
                          }}
                          disabled={!dirty || isSubmitting}>
                          {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Update'}
                        </Button>
                      </>
                    )}
                    {!values.visible && !editable && (
                      <div className={styles.buttonsContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setDialogType(DialogType.PublishStory)}>
                          {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Publish'}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: '10px' }}
                          data-testid={'editBookBtn'}
                          onClick={() => setEditable(true)}>
                          Edit
                        </Button>
                        <div className={styles.buttonsGroup}>
                          <Button variant="outlined" onClick={() => setDialogType(DialogType.DeleteStory)}>
                            Delete
                          </Button>
                          <Button variant="outlined" onClick={() => submitDuplicate(setDuplicating)}>
                            {isDuplicating ? <CircularProgress size={24} color={'inherit'} /> : 'Duplicate'}
                          </Button>
                          <Button variant="outlined" onClick={() => submitCopying(setCopying)}>
                            {isCopying ? <CircularProgress size={24} color={'inherit'} /> : 'Copy to production'}
                          </Button>
                        </div>
                      </div>
                    )}
                    {values.visible && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDialogType(DialogType.UnpublishStory)}>
                        {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Unpublish'}
                      </Button>
                    )}
                  </>
                )}
              </FormFooter>
            </div>
            <Dialog open={dialogType !== null}>
              <DialogContent style={{ minWidth: 600 }}>
                <DialogContentText id="alert-dialog-description">
                  {dialogType && `Are you sure you want to ${dialogType} this story?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogType(null)} color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    if (dialogType === DialogType.UnpublishStory) {
                      submitUnpublish(setSubmitting)
                    }
                    if (dialogType === DialogType.PublishStory) {
                      submitPublish(setSubmitting)
                    }
                    if (dialogType === DialogType.DeleteStory) {
                      submitDelete(setSubmitting)
                    }
                    setDialogType(null)
                  }}
                  color="primary"
                  autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }}
    </Formik>
  )
}

export default BookForm
