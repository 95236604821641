import {
  StudySetActions,
  STUDY_SET_ACTIONS,
  StudySetLoadCollectionReqState,
  StudySetModel,
  StudySetLoadCollection,
  StudySetState,
  StudySetSingleSetData,
  StudySetSingleReqState,
  StudySetComponentModel,
  MoveComponentModel,
  StudySetSingleDeleteData,
  StudySetComponentRequest,
  StudySetComponentSpeakingChallengeModel,
  StudySetComponentSpeakingChallengeRequest,
  SectionTag
} from './types'
import { Dispatch } from 'redux'
import { RootState } from '../../types'
import { createSelector } from 'reselect'
import { Logger } from '../../../shared/logger/Logger'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import StudySetApi from './studySetApi'
import { ThunkAction } from 'redux-thunk'

export const StudySetInitialState: StudySetState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  },
  single: {
    data: undefined,
    reqState: ApiReqState.IDLE
  }
}

const studySetReducer = (state: StudySetState = StudySetInitialState, action: StudySetActions) => {
  switch (action.type) {
    case STUDY_SET_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case STUDY_SET_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    case STUDY_SET_ACTIONS.SINGLE_SET_DATA:
      return {
        ...state,
        single: {
          ...state.single,
          data: action.data
        }
      }
    case STUDY_SET_ACTIONS.SINGLE_DELETE_DATA:
      return {
        ...state,
        single: {
          ...state.single,
          data: undefined
        }
      }
    case STUDY_SET_ACTIONS.SINGLE_REQ_STATE:
      return {
        ...state,
        single: {
          ...state.single,
          reqState: action.reqState
        }
      }
    default:
      return state
  }
}

export default studySetReducer

// ACTIONS
const setStudySetLoadReqState = (reqState: ApiReqState): StudySetLoadCollectionReqState => ({
  type: STUDY_SET_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setStudySetLoadCollection = (collection: Paginated<StudySetModel>): StudySetLoadCollection => ({
  type: STUDY_SET_ACTIONS.LOAD_COLLECTION,
  collection
})

const setSingleStudySetData = (data: StudySetModel): StudySetSingleSetData => ({
  type: STUDY_SET_ACTIONS.SINGLE_SET_DATA,
  data
})
const deleteSingleStudySetData = (): StudySetSingleDeleteData => ({
  type: STUDY_SET_ACTIONS.SINGLE_DELETE_DATA
})

const setSingleStudySetReqState = (reqState: ApiReqState): StudySetSingleReqState => ({
  type: STUDY_SET_ACTIONS.SINGLE_REQ_STATE,
  reqState
})

let abortController: AbortController
export const getStudySetCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<StudySetLoadCollection | StudySetLoadCollectionReqState>
) => {
  abortController?.abort()
  abortController = new AbortController()

  try {
    dispatch(setStudySetLoadReqState(ApiReqState.PENDING))

    const collection = (await StudySetApi.load(params, abortController.signal)).data
    dispatch(setStudySetLoadCollection(collection))

    dispatch(setStudySetLoadReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setStudySetLoadReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const getStudySetSingle = (id: StudySetModel['_id']) => async (
  dispatch: Dispatch<StudySetSingleSetData | StudySetSingleReqState>
) => {
  try {
    dispatch(setSingleStudySetReqState(ApiReqState.PENDING))

    const { data } = await StudySetApi.loadSingle(id)
    dispatch(setSingleStudySetData(data))

    dispatch(setSingleStudySetReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setSingleStudySetReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const createStudySet = (
  studySetData: FormData
): ThunkAction<Promise<StudySetModel>, RootState, unknown, StudySetSingleSetData> => async (
  dispatch
): Promise<StudySetModel> => {
  try {
    const { data } = await StudySetApi.create(studySetData)
    dispatch(setSingleStudySetData(data))
    return data
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const deleteStudySet = (
  id: StudySetModel['_id']
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleDeleteData> => async (dispatch) => {
  try {
    await StudySetApi.delete(id)
    dispatch(deleteSingleStudySetData())
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const updateStudySet = (
  id: StudySetModel['_id'],
  studySetData: FormData
): ThunkAction<Promise<StudySetModel>, RootState, unknown, StudySetSingleSetData | StudySetSingleReqState> => async (
  dispatch
): Promise<StudySetModel> => {
  try {
    dispatch(setSingleStudySetReqState(ApiReqState.PENDING))

    const { data } = await StudySetApi.update(id, studySetData)
    dispatch(setSingleStudySetData(data))

    dispatch(setSingleStudySetReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleStudySetReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const publishStudySet = (id: string) => async (
  dispatch: Dispatch<StudySetSingleSetData | StudySetSingleReqState>
): Promise<StudySetModel> => {
  try {
    dispatch(setSingleStudySetReqState(ApiReqState.PENDING))
    const { data } = await StudySetApi.publish(id)
    dispatch(setSingleStudySetData(data))

    dispatch(setSingleStudySetReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleStudySetReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const unpublishStudySet = (id: string) => async (
  dispatch: Dispatch<StudySetSingleSetData | StudySetSingleReqState>
): Promise<StudySetModel> => {
  try {
    dispatch(setSingleStudySetReqState(ApiReqState.PENDING))
    const { data } = await StudySetApi.unpublish(id)
    dispatch(setSingleStudySetData(data))

    dispatch(setSingleStudySetReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleStudySetReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const createStudySetComponent = (
  studySetId: StudySetModel['_id'],
  sectionIndex: number,
  data: StudySetComponentModel | StudySetComponentRequest
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    const { data: componentData } = await StudySetApi.createComponent(studySetId, sectionIndex, data)

    const updatedStudySet = {
      ...studySetData,
      sections: Object.assign([], studySetData.sections, {
        [sectionIndex]: {
          ...studySetData.sections[sectionIndex],
          components: [...studySetData.sections[sectionIndex].components, componentData]
        }
      })
    }

    dispatch(setSingleStudySetData(updatedStudySet))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const updateStudySetComponent = (
  studySetId: StudySetModel['_id'],
  sectionIndex: number,
  componentIndex: number,
  data: StudySetComponentModel | StudySetComponentRequest
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    const { data: componentData } = await StudySetApi.updateComponent(studySetId, sectionIndex, componentIndex, data)

    const updatedStudySet = {
      ...studySetData,
      sections: Object.assign([], studySetData.sections, {
        [sectionIndex]: {
          ...studySetData.sections[sectionIndex],
          components: Object.assign([], studySetData.sections[sectionIndex].components, {
            [componentIndex]: componentData
          })
        }
      })
    }

    dispatch(setSingleStudySetData(updatedStudySet))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const deleteStudySetComponent = (
  studySetId: StudySetModel['_id'],
  sectionIndex: number,
  componentIndex: number
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    await StudySetApi.deleteComponent(studySetId, sectionIndex, componentIndex)

    const updatedStudySet = {
      ...studySetData,
      sections: Object.assign([], studySetData.sections, {
        [sectionIndex]: {
          ...studySetData.sections[sectionIndex],
          components: studySetData.sections[sectionIndex].components.filter((_, index) => index !== componentIndex)
        }
      })
    }

    dispatch(setSingleStudySetData(updatedStudySet))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const moveStudySetComponent = (
  id: StudySetModel['_id'],
  sectionIndex: number,
  data: MoveComponentModel
): ThunkAction<Promise<StudySetModel>, RootState, unknown, StudySetSingleSetData> => async (
  dispatch
): Promise<StudySetModel> => {
  try {
    const { data: studySetData } = await StudySetApi.moveComponent(id, sectionIndex, data)
    dispatch(setSingleStudySetData(studySetData))
    return studySetData
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const updateSpeakingChallengeComponent = (
  studySetId: StudySetModel['_id'],
  data: StudySetComponentSpeakingChallengeModel | StudySetComponentSpeakingChallengeRequest
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    const { data: componentData } = await StudySetApi.updateSpeakingChallenge(studySetId, data)

    dispatch(setSingleStudySetData({ ...studySetData, speakingChallenge: componentData }))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const deleteSpeakingChallengeComponent = (
  studySetId: StudySetModel['_id']
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    await StudySetApi.deleteSpeakingChallenge(studySetId)

    dispatch(setSingleStudySetData({ ...studySetData, speakingChallenge: undefined }))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const createStudySetSection = (
  studySetId: StudySetModel['_id'],
  sectionTag: SectionTag
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    await StudySetApi.createSection(studySetId, sectionTag)

    console.log(studySetData)
    dispatch(
      setSingleStudySetData({
        ...studySetData,
        sections: [...studySetData.sections, { sectionName: sectionTag, components: [] }]
      })
    )
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const updateStudySetSection = (
  studySetId: StudySetModel['_id'],
  sectionIndex: number,
  sectionTag: SectionTag
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    await StudySetApi.updateSection(studySetId, sectionIndex, sectionTag)

    const updatedStudySet = {
      ...studySetData,
      sections: Object.assign([], studySetData.sections, {
        [sectionIndex]: { ...studySetData.sections[sectionIndex], sectionName: sectionTag }
      })
    }

    dispatch(setSingleStudySetData(updatedStudySet))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

export const deleteStudySetSection = (
  studySetId: StudySetModel['_id'],
  sectionIndex: number
): ThunkAction<Promise<void>, RootState, unknown, StudySetSingleSetData> => async (dispatch, getState) => {
  try {
    const studySetData = selectStudySetSingleData(getState(), studySetId)!
    for (let i = studySetData.sections[sectionIndex].components.length - 1; i >= 0; i--) {
      await StudySetApi.deleteComponent(studySetId, sectionIndex, i)
    }
    await StudySetApi.deleteSection(studySetId, sectionIndex)

    const updatedStudySet = {
      ...studySetData,
      sections: studySetData.sections.filter((_, index) => index !== sectionIndex)
    }

    dispatch(setSingleStudySetData(updatedStudySet))
  } catch (e) {
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

// SELECTORS
export const selectStudySetCollection = (state: RootState) => state.studySet.collection
export const selectStudySets = createSelector(selectStudySetCollection, (collection) => collection.results)
export const selectStudySetsStatus = createSelector(selectStudySetCollection, (collection) => collection.status)
export const selectStudySetsReqState = createSelector(selectStudySetCollection, (collection) => collection.reqState)

export const selectStudySetSingle = (state: RootState) => state.studySet.single
export const selectStudySetSingleReqState = createSelector(selectStudySetSingle, (single) => single.reqState)
export const selectStudySetSingleData = createSelector(
  selectStudySetSingle,
  (_: RootState, id: string) => id,
  (single, id) => (single.data?._id === id ? single.data : StudySetInitialState.single.data)
)
