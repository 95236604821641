import { PaginatedStatus, ApiReqState, Paginated } from '../../../shared/api/types'

export interface TeacherState {
  collection: {
    results: TeacherModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
}

export enum TEACHER_ACTIONS {
  LOAD_COLLECTION = 'TEACHER__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'TEACHER__LOAD_COLLECTION_REQ_STATE'
}

export interface TeacherModel {
  _id: string
  email: string
  school: string
  studentsCount: number
  classCount: number
  firstName: string
  lastName: string
}

export interface TeacherLoadCollection {
  type: TEACHER_ACTIONS.LOAD_COLLECTION
  collection: Paginated<TeacherModel>
}

export interface TeacherLoadCollectionReqState {
  type: TEACHER_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export type TeacherActions = TeacherLoadCollection | TeacherLoadCollectionReqState
