import { ApiReqState } from '../../../shared/api/types'
import { ContentType } from '../ContentTag/types'

export interface TopicsState {
  data?: TopicsModel
  reqState: ApiReqState
}

export enum TOPICS_ACTIONS {
  REQ_STATE = 'TOPICS__REQ_STATE',
  SET_DATA = 'TOPICS__SET_DATA'
}

export interface TopicsModel {
  contentType: ContentType
  contentId: string
  topics: string[]
}

export interface TopicsReqState {
  type: TOPICS_ACTIONS.REQ_STATE
  reqState: ApiReqState
}

export interface TopicsSetData {
  type: TOPICS_ACTIONS.SET_DATA
  data: TopicsModel
}

export type TopicsActions = TopicsReqState | TopicsSetData
