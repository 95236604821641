import { PaginatedStatus, ApiReqState, Paginated } from '../../../shared/api/types'

export interface SchoolState {
  collection: {
    results: SchoolModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
}

export enum SCHOOL_ACTIONS {
  LOAD_COLLECTION = 'SCHOOL__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'SCHOOL__LOAD_COLLECTION_REQ_STATE'
}

export interface SchoolModel {
  name: string
  city: string
  country: string
  teachersCount: number
  studentsCount: number
  classCount: number
}

export interface SchoolLoadCollection {
  type: SCHOOL_ACTIONS.LOAD_COLLECTION
  collection: Paginated<SchoolModel>
}

export interface SchoolLoadCollectionReqState {
  type: SCHOOL_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export type SchoolActions = SchoolLoadCollection | SchoolLoadCollectionReqState
