import { Button, Grid, IconButton, Radio, TextField, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { FieldArray, FormikErrors, FormikTouched } from 'formik'
import React from 'react'
import TertiaryButton from '../../../shared/components/TertiaryButton/TertiaryButton'
import styles from '../Comprehension.module.scss'
import { ComprehensionFormFields } from '../types'

interface TextFormProps {
  handleTranslationClick: (text: string, translateTo: 'uk' | 'us') => Promise<string | undefined>
  handleChange: Function
  setFieldTouched: Function
  setFieldValue: Function
  values: ComprehensionFormFields
  touched: FormikTouched<ComprehensionFormFields>
  formDisabled: boolean
  errors: FormikErrors<ComprehensionFormFields>
  isVideoOverlay?: boolean
}

export const TextQuestionForm: React.FC<TextFormProps> = ({
  handleTranslationClick,
  handleChange,
  setFieldTouched,
  setFieldValue,
  values,
  touched,
  formDisabled,
  errors,
  isVideoOverlay
}) => {
  return (
    <>
      {!isVideoOverlay && (
        <>
          <Typography variant="h6">UK</Typography>

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="question"
            label="Question"
            placeholder="Enter the question"
            name="question"
            size="small"
            onChange={handleChange('question')}
            value={values.question}
            error={touched.question && !!errors.question}
            helperText={(touched.question && errors.question) || ''}
            onBlur={() => setFieldTouched('question')}
            disabled={formDisabled}
          />

          <Button
            onClick={async () => {
              setFieldValue('questionUs', await handleTranslationClick(values.question || '', 'us'))
            }}
            variant="outlined"
            color="primary"
            className={styles.translateButton}>
            Translate to US
          </Button>
        </>
      )}

      {!isVideoOverlay && <Typography variant="h6">US</Typography>}

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="questionUs"
        label="Question"
        placeholder="Enter the question"
        name="questionUs"
        size="small"
        onChange={handleChange('questionUs')}
        value={values.questionUs}
        error={touched.questionUs && !!errors.questionUs}
        helperText={(touched.questionUs && errors.questionUs) || ''}
        onBlur={() => setFieldTouched('questionUs')}
        disabled={formDisabled}
      />

      {!isVideoOverlay && (
        <Button
          onClick={async () => {
            setFieldValue('question', await handleTranslationClick(values.questionUs || '', 'uk'))
          }}
          variant="outlined"
          color="primary"
          className={styles.translateButton}>
          Translate to UK
        </Button>
      )}
    </>
  )
}

export const TextAnswerForm: React.FC<TextFormProps> = ({
  values,
  handleChange,
  handleTranslationClick,
  formDisabled,
  touched,
  errors,
  setFieldTouched,
  setFieldValue,
  isVideoOverlay
}) => {
  return (
    <>
      {!isVideoOverlay && (
        <>
          <Typography variant="h6">UK</Typography>
          <FieldArray name={'options'}>
            {(arrayHelpers) => {
              return (
                <div className={styles.optionsContainer}>
                  {values.options!.map((option, index) => {
                    return (
                      <div className={styles.option} key={index}>
                        <Radio
                          checked={values.answer === index}
                          onChange={() => {
                            handleChange('answer')({ target: { name: 'answer', value: index } } as React.ChangeEvent<
                              any
                            >)
                          }}
                          value={index}
                          name="option-answer"
                          color="primary"
                          inputProps={
                            { 'data-testid': `option-${index}-answer` } as React.InputHTMLAttributes<HTMLInputElement>
                          }
                          disabled={formDisabled}
                        />
                        <TextField
                          required
                          fullWidth
                          placeholder={`Enter the option`}
                          id={`options.${index}`}
                          name={`options.${index}`}
                          inputProps={{ 'data-testid': `option-${index}` }}
                          value={option}
                          onChange={handleChange(`options.${index}`)}
                          error={
                            Array.isArray(touched.options) &&
                            touched.options[index] &&
                            !!(Array.isArray(errors.options) && errors.options[index])
                          }
                          helperText={
                            Array.isArray(touched.options) &&
                            touched.options[index] &&
                            Array.isArray(errors.options) &&
                            errors.options[index]
                          }
                          onBlur={() => setFieldTouched(`options.${index}`)}
                          disabled={formDisabled}
                        />
                        <IconButton
                          aria-label="remove"
                          data-testid={`option-${index}-remove`}
                          onClick={() => {
                            arrayHelpers.remove(index)
                            setFieldTouched('options')
                            if (values.answer === index) {
                              setFieldValue('answer', 0)
                            }
                          }}
                          disabled={formDisabled}>
                          <Clear />
                        </IconButton>
                      </div>
                    )
                  })}
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item>
                      <TertiaryButton
                        data-testid="addComprehensionOption"
                        onClick={() => {
                          arrayHelpers.push('')
                          setFieldTouched('options')
                        }}
                        disabled={values.options!.length >= 4 || formDisabled}>
                        Add
                      </TertiaryButton>
                    </Grid>
                    <Grid item>
                      <TertiaryButton
                        data-testid="translateToUsBtn"
                        onClick={async () => {
                          setFieldValue('questionUs', await handleTranslationClick(values.question || '', 'us'))
                          for (let i = 0; i < values.options!.length; ++i) {
                            setFieldValue(
                              `optionsUs.${i}`,
                              await handleTranslationClick(values.options![i] || '', 'us')
                            )
                          }

                          setFieldValue('answerUs', values.answer)
                        }}
                        disabled={values.options!.length === 0 || values.question === ''}>
                        Translate to US
                      </TertiaryButton>
                    </Grid>
                  </Grid>
                </div>
              )
            }}
          </FieldArray>
        </>
      )}

      {!isVideoOverlay && <Typography variant="h6">US</Typography>}
      <FieldArray name={'optionsUs'}>
        {(arrayHelpers) => {
          return (
            <div className={styles.optionsContainer}>
              {values.optionsUs.map((option, index) => {
                return (
                  <div className={styles.option} key={index}>
                    <Radio
                      checked={values.answerUs === index}
                      onChange={() => {
                        handleChange('answerUs')({ target: { name: 'answerUs', value: index } } as React.ChangeEvent<
                          any
                        >)
                      }}
                      value={index}
                      name="option-answer-us"
                      color="primary"
                      inputProps={
                        { 'data-testid': `option-${index}-answer-us` } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                      disabled={formDisabled}
                    />
                    <TextField
                      required={!!values.questionUs}
                      fullWidth
                      placeholder={`Enter the option`}
                      id={`optionsUs.${index}`}
                      name={`optionsUs.${index}`}
                      inputProps={{ 'data-testid': `option-${index}-us` }}
                      value={option}
                      onChange={handleChange(`optionsUs.${index}`)}
                      error={
                        Array.isArray(touched.optionsUs) &&
                        touched.optionsUs[index] &&
                        !!(Array.isArray(errors.optionsUs) && errors.optionsUs[index])
                      }
                      helperText={
                        Array.isArray(touched.optionsUs) &&
                        touched.optionsUs[index] &&
                        Array.isArray(errors.optionsUs) &&
                        errors.optionsUs[index]
                      }
                      onBlur={() => setFieldTouched(`optionsUs.${index}`)}
                      disabled={formDisabled}
                    />
                    <IconButton
                      aria-label="remove"
                      data-testid={`option-${index}-remove-us`}
                      onClick={() => {
                        arrayHelpers.remove(index)
                        setFieldTouched('optionsUs')
                        if (values.answerUs === index) {
                          setFieldValue('answerUs', 0)
                        }
                      }}
                      disabled={formDisabled}>
                      <Clear />
                    </IconButton>
                  </div>
                )
              })}
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <TertiaryButton
                    data-testid="addComprehensionOptionUs"
                    onClick={() => {
                      arrayHelpers.push('')
                      setFieldTouched('optionsUs')
                    }}
                    disabled={values.optionsUs!.length >= 4 || formDisabled}>
                    Add
                  </TertiaryButton>
                </Grid>
                <Grid item>
                  {!isVideoOverlay && (
                    <TertiaryButton
                      data-testid="addComprehensionOptionUs"
                      onClick={async () => {
                        setFieldValue('question', await handleTranslationClick(values.questionUs || '', 'uk'))
                        for (let i = 0; i < values.optionsUs!.length; ++i) {
                          setFieldValue(`options.${i}`, await handleTranslationClick(values.optionsUs![i] || '', 'uk'))
                        }

                        setFieldValue('answer', values.answerUs)
                      }}
                      disabled={values.optionsUs!.length === 0 || values.questionUs === '' || formDisabled}>
                      Translate to UK
                    </TertiaryButton>
                  )}
                </Grid>
                <Grid item>
                  <Typography color="error" variant={'caption'}>
                    {touched.optionsUs && values.optionsUs!.length < 2 ? 'Min 2 options' : ''}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )
        }}
      </FieldArray>
    </>
  )
}
