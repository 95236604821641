import { Button, CircularProgress, Paper, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import useAxios from 'axios-hooks'
import React, { useState } from 'react'
import ClassifiedWord from '../ClassifiedWord/ClassifiedWord'
import { endpoints } from '../endpoints'
import GrammarContainer from '../GrammarContainer/GrammarContainer'
import StatsContainer from '../StatsContainer/StatsContainer'
import { CefrLevels, ClassifyResponse, Result } from '../types'
import WordForm from '../WordForm/WordForm'
import styles from './ClassificationTool.module.scss'

const ClassificationTool = () => {
  const [{ data, loading: isClassificationLoading, error }, scoreTextPOST] = useAxios<ClassifyResponse>(
    {
      url: endpoints.scoreText,
      method: 'POST'
    },
    { manual: true }
  )

  const [{ data: grammarData, error: grammarError }, scoreTextGrammarPOST] = useAxios<any>(
    {
      url: endpoints.scoreTextGrammar,
      method: 'POST'
    },
    { manual: true }
  )

  const [temporaryCefrLevels, setTemporaryCefrLevels] = useState<CefrLevels | null>(null)
  const [temporaryResult, setTemporaryResult] = useState<Result[] | null>(null)
  const [text, setText] = useState('')
  const classifyText = () => {
    if (text !== '') {
      setTemporaryCefrLevels(null)
      setTemporaryResult(null)
      scoreTextPOST({ params: { text } })
      scoreTextGrammarPOST({ params: { text } })
    }
  }
  const classifiedData =
    data &&
    ({
      ...data,
      cefr_levels: temporaryCefrLevels ?? data?.cefr_levels,
      result: temporaryResult ?? data?.result
    } as ClassifyResponse)
  return (
    <div className={styles.container}>
      <Paper className={`${styles.textContainer} ${styles.inputContainer}`}>
        <TextField
          value={text}
          onChange={(e) => setText(e.target.value)}
          rows={-1} // prevent height from being specified on the textarea element
          fullWidth
          placeholder="Text to classify"
          multiline
          variant="outlined"
        />
      </Paper>
      <div className={styles.buttonContainer}>
        <Button onClick={() => classifyText()} className={styles.button} color="primary" variant="contained">
          Classify Text
        </Button>
      </div>
      <Paper
        className={`${isClassificationLoading ? styles.loadingContainer : styles.textContainer} ${
          styles.classificationContainer
        }`}>
        {isClassificationLoading ? (
          <CircularProgress />
        ) : (
          classifiedData?.result?.map((result) => (
            <ClassifiedWord
              onDataChange={(newSentenceData: CefrLevels, newSentence: Result[]) => {
                setTemporaryCefrLevels(newSentenceData)
                setTemporaryResult(newSentence)
              }}
              key={result.id}
              result={result}
              sentence={classifiedData?.result || []}
            />
          ))
        )}
      </Paper>
      <WordForm />
      <div className={styles.stats}>
        {classifiedData && <StatsContainer data={classifiedData} />}
        {error && <Alert severity="error">{error.message}</Alert>}
      </div>

      <div className={styles.stats}>
        {grammarData && <GrammarContainer data={grammarData} />}
        {grammarError && <Alert severity="error">{grammarError.message}</Alert>}
      </div>
    </div>
  )
}

export default ClassificationTool
