import { Button, Radio, TextField, Typography } from '@material-ui/core'
import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'
import RecordingField from '../../../shared/components/RecordingField/RecordingField'
import styles from '../Comprehension.module.scss'
import { ComprehensionFormFields } from '../types'

interface AudioFormProps {
  handleTranslationClick: (text: string, translateTo: 'uk' | 'us') => Promise<string | undefined>
  handleChange: Function
  setFieldTouched: Function
  setFieldValue: Function
  values: ComprehensionFormFields
  touched: FormikTouched<ComprehensionFormFields>
  formDisabled: boolean
  errors: FormikErrors<ComprehensionFormFields>
}

export const AudioQuestionForm: React.FC<AudioFormProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  formDisabled,
  handleTranslationClick
}) => {
  return (
    <>
      <Typography variant="h6">UK</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="question"
        label="Question"
        placeholder="Enter the question"
        name="question"
        size="small"
        onChange={handleChange('question')}
        value={values.question}
        error={touched.question && !!errors.question}
        helperText={(touched.question && errors.question) || ''}
        onBlur={() => setFieldTouched('question')}
        disabled={formDisabled}
      />

      <Button
        onClick={async () => {
          setFieldValue('questionUs', await handleTranslationClick(values.question || '', 'us'))
        }}
        variant="outlined"
        color="primary"
        className={styles.translateButton}>
        Translate to US
      </Button>

      <RecordingField
        name="audioQuestion"
        file={values.audioQuestion}
        disabled={formDisabled}
        removeRecording={() => setFieldValue('audioQuestion', null)}
        uploadRecording={(file) => setFieldValue('audioQuestion', file)}
      />

      <Typography variant="h6">US</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="questionUs"
        label="Question"
        placeholder="Enter the question"
        name="questionUs"
        size="small"
        onChange={handleChange('questionUs')}
        value={values.questionUs}
        error={touched.questionUs && !!errors.questionUs}
        helperText={(touched.questionUs && errors.questionUs) || ''}
        onBlur={() => setFieldTouched('questionUs')}
        disabled={formDisabled}
      />

      <Button
        onClick={async () => {
          setFieldValue('question', await handleTranslationClick(values.question || '', 'uk'))
        }}
        variant="outlined"
        color="primary"
        className={styles.translateButton}>
        Translate to UK
      </Button>

      <RecordingField
        name="audioQuestionUs"
        file={values.audioQuestionUs}
        disabled={formDisabled}
        removeRecording={() => setFieldValue('audioQuestionUs', null)}
        uploadRecording={(file) => setFieldValue('audioQuestionUs', file)}
      />
    </>
  )
}

export const AudioAnswerForm: React.FC<AudioFormProps> = ({ values, setFieldValue, formDisabled }) => {
  const options = [values.audioOption1, values.audioOption2, values.audioOption3, values.audioOption4]
  const optionsUs = [values.audioOptionUs1, values.audioOptionUs2, values.audioOptionUs3, values.audioOptionUs4]
  return (
    <>
      <Typography variant="h6">UK</Typography>
      {options.map((option, i) => (
        <div className={styles.audioAnswer} key={`audioOption${i}`}>
          <Radio
            checked={values.answer === i}
            onChange={() => setFieldValue('answer', i)}
            value={i}
            name="option-answer"
            color="primary"
            style={{ marginRight: 8, alignSelf: 'center' }}
            inputProps={{ 'data-testid': `option-${i}-answer` } as React.InputHTMLAttributes<HTMLInputElement>}
            disabled={formDisabled || option === undefined}
          />
          <RecordingField
            name={`audioOption${i + 1}`}
            file={option}
            style={{ flex: 1 }}
            disabled={formDisabled}
            removeRecording={() => {
              setFieldValue(`audioOption${i + 1}`, undefined)
              if (values.answer === i) {
                const switchAnswerTo = options.findIndex((opt, index) => !!opt && index !== i)
                setFieldValue('answer', switchAnswerTo !== -1 ? switchAnswerTo : 0)
              }
            }}
            uploadRecording={(file) => setFieldValue(`audioOption${i + 1}`, file)}
          />
        </div>
      ))}
      <Typography variant="h6">US</Typography>
      {optionsUs.map((option, i) => (
        <div className={styles.audioAnswer} key={`audioOptionUs${i}`}>
          <Radio
            checked={values.answer === i}
            onChange={() => setFieldValue('answer', i)}
            value={i}
            name="option-answer"
            color="primary"
            style={{ marginRight: 8, alignSelf: 'center' }}
            inputProps={{ 'data-testid': `option-${i}-answer` } as React.InputHTMLAttributes<HTMLInputElement>}
            disabled={formDisabled || option === undefined}
          />
          <RecordingField
            name={`audioOptionUs${i + 1}`}
            file={option}
            style={{ flex: 1 }}
            disabled={formDisabled}
            removeRecording={() => {
              setFieldValue(`audioOptionUs${i + 1}`, null)
              if (values.answer === i) {
                const switchAnswerTo = options.findIndex((opt, index) => !!opt && index !== i)
                setFieldValue('answer', switchAnswerTo !== -1 ? switchAnswerTo : 0)
              }
            }}
            uploadRecording={(file) => setFieldValue(`audioOptionUs${i + 1}`, file)}
          />
        </div>
      ))}
    </>
  )
}
