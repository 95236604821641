import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Add, Clear } from '@material-ui/icons'
import { ErrorMessage, FieldArray } from 'formik'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import TertiaryButton from '../../shared/components/TertiaryButton/TertiaryButton'
import VideoUploader from '../../shared/components/VideoUploader/VideoUploader'
import { DEFAULT_CONVERSATION_POINTS } from '../../shared/constants/points'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { TARGET_COUNTRIES_ARRAY } from '../../store/services/Book/types'
import { ExerciseConversationModel, ExerciseFormFields, ExerciseType } from '../../store/services/Exercise/types'
import { VideoPreview } from '../../StudySet/components/VideoPreview/VideoPreview'
import { ExerciseFormFactory } from '../ExerciseFormFactory/ExerciseFormFactory'
import { ExerciseFormProps } from '../types'
import styles from './Conversation.module.scss'

const getValidationSchema = (bookChild: boolean) => {
  const baseFields = {
    points: ValidationRules.points,
    conversationElements: Yup.array().of(
      Yup.object().shape({
        video: ValidationRules.video,
        instructorScript: Yup.string().optional(),
        learnerScript: Yup.string().optional()
      })
    )
  }
  if (!bookChild) {
    return Yup.object().shape({
      ...baseFields,
      targetCountries: ValidationRules.targetCountries
    })
  } else {
    return Yup.object().shape({
      ...baseFields
    })
  }
}

const getInitialValues = (data?: ExerciseConversationModel, bookChild?: boolean) => {
  const baseFields: FormFields = {
    points: data?.points || DEFAULT_CONVERSATION_POINTS,
    conversationElements: data?.data.conversationElements || [
      { instructorScript: '', learnerScript: '', video: { _id: '', originalUrl: '' } }
    ],
    type: data?.type || ExerciseType.Conversation
  }

  if (!bookChild) {
    return {
      ...baseFields,
      difficultyLevel: data?.difficultyLevel || 0,
      targetCountries: data?.targetCountries || []
    }
  } else {
    return baseFields
  }
}

interface Props extends ExerciseFormProps {
  data?: ExerciseConversationModel
}

type FormFields = ExerciseFormFields<ExerciseConversationModel>

const Conversation = ({ data, formDisabled, bookChild, ...exerciseFormProps }: Props) => {
  const [uploadVideoIndex, setUploadVideoIndex] = React.useState<number | null>(null)
  const isNew = !data
  const handleSubmit = useCallback(
    ({ type, points, conversationElements, difficultyLevel, targetCountries }: FormFields) => {
      const preparedData: any = {
        type,
        points,
        data: JSON.stringify({ conversationElements })
      }

      if (!bookChild) {
        preparedData.difficultyLevel = difficultyLevel
        preparedData.targetCountries = targetCountries
      }

      return preparedData
    },
    [bookChild]
  )

  const initialValues = useMemo(() => getInitialValues(data, bookChild), [data, bookChild])
  const validationSchema = useMemo(() => getValidationSchema(bookChild), [bookChild])

  return (
    <ExerciseFormFactory
      {...exerciseFormProps}
      formDisabled={formDisabled}
      exerciseId={data?._id}
      bookChild={bookChild}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ handleChange, values, errors, touched, setFieldTouched, setFieldValue }) => {
        return (
          <>
            <Typography variant="h5" gutterBottom>
              {isNew ? 'Add Conversation' : 'Update Conversation'}
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="points"
              label="Stars"
              name="points"
              size="small"
              inputProps={{ min: 0 }}
              onChange={handleChange('points')}
              value={values.points}
              error={touched.points && !!errors.points}
              helperText={(touched.points && errors.points) || ''}
              onBlur={() => setFieldTouched('points')}
              disabled={formDisabled}
            />
            {!bookChild && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="difficultyLevel"
                    label="Difficulty level"
                    name="difficultyLevel"
                    size="small"
                    inputProps={{ min: 0, max: 100 }}
                    onChange={handleChange('difficultyLevel')}
                    value={values.difficultyLevel}
                    error={touched.difficultyLevel && !!errors.difficultyLevel}
                    helperText={(touched.difficultyLevel && errors.difficultyLevel) || ''}
                    onBlur={() => setFieldTouched('difficultyLevel')}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="targetCountries"
                    label="Target countries"
                    name="targetCountries"
                    size="small"
                    data-testid="targetCountries"
                    select
                    SelectProps={{ multiple: true }}
                    onChange={(e) => {
                      setFieldTouched('targetCountries')
                      return handleChange('targetCountries')(e)
                    }}
                    value={values.targetCountries}
                    error={touched.targetCountries && !!errors.targetCountries}
                    helperText={(touched.targetCountries && errors.targetCountries) || ''}
                    onBlur={() => setFieldTouched('targetCountries')}
                    disabled={formDisabled}
                    inputProps={{ 'data-testid': 'targetCountriesInput' }}>
                    {TARGET_COUNTRIES_ARRAY.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
            <Divider className={styles.divider} />
            <FieldArray name={'conversationElements'}>
              {(arrayHelpers) => (
                <>
                  {values.conversationElements.map((element, index) => (
                    <Grid container spacing={2} className={styles.row} key={index}>
                      <Grid item xs={3}>
                        {values.conversationElements[index]?.video?.originalUrl && (
                          <VideoPreview
                            src={values.conversationElements[index].video.originalUrl}
                            className={styles.video}
                          />
                        )}
                        {values.conversationElements[index]?.video?._id ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setUploadVideoIndex(index)}
                            disabled={formDisabled}>
                            Replace video
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setUploadVideoIndex(index)}
                            disabled={formDisabled}
                            data-testid="add-video-button">
                            Add video
                          </Button>
                        )}
                        <ErrorMessage name={`conversationElements.${index}.video._id`}>
                          {(msg) => <p className={styles.error}>{msg}</p>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          variant="outlined"
                          margin="none"
                          fullWidth
                          id={`conversationElements.${index}.instructorScript`}
                          label="Instructor script"
                          name={`conversationElements.${index}.instructorScript`}
                          size="small"
                          placeholder="Add script for the instructor"
                          multiline
                          onChange={handleChange(`conversationElements.${index}.instructorScript`)}
                          value={element.instructorScript}
                          onBlur={() => setFieldTouched(`conversationElements.${index}.instructorScript`)}
                          disabled={formDisabled}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id={`conversationElements.${index}.learnerScript`}
                          label="Learner script"
                          name={`conversationElements.${index}.learnerScript`}
                          size="small"
                          placeholder="Add script or semi-script for the learner (leave blank to end with the instructor video)"
                          multiline
                          onChange={handleChange(`conversationElements.${index}.learnerScript`)}
                          value={element.learnerScript}
                          onBlur={() => setFieldTouched(`conversationElements.${index}.learnerScript`)}
                          disabled={formDisabled}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {values.conversationElements.length > 1 && (
                          <IconButton
                            aria-label="remove"
                            onClick={() => arrayHelpers.remove(index)}
                            disabled={formDisabled}>
                            <Clear />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <TertiaryButton
                    onClick={() => {
                      arrayHelpers.push({
                        instructorScript: '',
                        learnerScript: '',
                        video: { _id: '', originalUrl: '' }
                      })
                    }}
                    disabled={formDisabled}>
                    <Add /> Add new section
                  </TertiaryButton>
                  <Dialog open={uploadVideoIndex !== null}>
                    <DialogTitle>Add video</DialogTitle>
                    <DialogContent>
                      <VideoUploader
                        onUploaded={(videoData) => {
                          setFieldValue(`conversationElements.${uploadVideoIndex}.video`, {
                            _id: videoData._id,
                            originalUrl: videoData.originalUrl
                          })
                          setUploadVideoIndex(null)
                        }}
                        onCancel={() => setUploadVideoIndex(null)}
                      />
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </FieldArray>
          </>
        )
      }}
    </ExerciseFormFactory>
  )
}

export default Conversation
