import { Dispatch } from 'redux'
import { ApiReqState } from '../../../shared/api/types'
import { Logger } from '../../../shared/logger/Logger'
import { RootState } from '../../types'
import { ContentType } from '../ContentTag/types'
import { DOMAINS_ACTIONS, DomainsActions, DomainsModel, DomainsReqState, DomainsSetData, DomainsState } from './types'
import ContentTagApi from '../ContentTag/contentTagApi'

export const DomainsInitialState: DomainsState = {
  data: undefined,
  reqState: ApiReqState.IDLE
}

const DomainsReducer = (state: DomainsState = DomainsInitialState, action: DomainsActions) => {
  switch (action.type) {
    case DOMAINS_ACTIONS.SET_DATA:
      return {
        ...state,
        data: action.data
      }
    case DOMAINS_ACTIONS.REQ_STATE:
      return {
        ...state,
        reqState: action.reqState
      }
    default:
      return state
  }
}

export default DomainsReducer

// ACTIONS
const setDomainsData = (data: DomainsModel): DomainsSetData => ({
  type: DOMAINS_ACTIONS.SET_DATA,
  data
})
const setDomainsReqState = (reqState: ApiReqState): DomainsReqState => ({
  type: DOMAINS_ACTIONS.REQ_STATE,
  reqState
})

export const getDomains = ({ contentId, contentType }: Omit<DomainsModel, 'domains'>) => async (
  dispatch: Dispatch<DomainsSetData | DomainsReqState>
) => {
  try {
    dispatch(setDomainsReqState(ApiReqState.PENDING))

    const data = await ContentTagApi.loadDomains({ contentId, contentType })

    dispatch(setDomainsData({ contentId, contentType, domains: data }))
    dispatch(setDomainsReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setDomainsReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

export const updateDomains = ({ contentId, contentType, domains }: DomainsModel) => async (
  dispatch: Dispatch<DomainsSetData | DomainsReqState>
) => {
  try {
    dispatch(setDomainsReqState(ApiReqState.PENDING))

    const { data } = await ContentTagApi.updateDomains({ contentId, contentType, tags: domains })

    dispatch(setDomainsData({ contentId, contentType, domains: data }))
    dispatch(setDomainsReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setDomainsReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

// SELECTORS
export const selectDomains = (state: RootState, contentId: string, contentType: ContentType) =>
  state.domains.data?.contentId === contentId && state.domains.data?.contentType === contentType
    ? state.domains.data.domains
    : []
export const selectDomainsReqState = (state: RootState) => state.domains.reqState
