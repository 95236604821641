import { Paper } from '@material-ui/core'
import React from 'react'
import styles from './GrammarContainer.module.scss'
import { CEFR_COLORS, ClassifyGrammarResponse, LevelPercentages } from '../types'

interface Props {
  data: ClassifyGrammarResponse
}

const GrammarContainer = ({ data }: Props) => {
  const getColor = (level: string) => {
    const [cefrLevel] = level.toLocaleLowerCase().split('.')
    return CEFR_COLORS[cefrLevel as keyof LevelPercentages]
  }

  return (
    <Paper className={styles.grammarContainer}>
      <ul className={styles.grammarList}>
        {data.grammar_stats.map((item) => (
          <li key={item.text}>
            <div className={styles.item}>
              <div className={styles.level} style={{ backgroundColor: item.level ? getColor(item.level) : 'white' }}>
                {item.level}
              </div>
              {item.text}
            </div>
            <div className={styles.tableWrapper}>
              <table className={styles.patternTable}>
                <tbody>
                  {item.patterns.map((pattern) => (
                    <tr key={JSON.stringify(pattern)}>
                      <td width={50}>
                        <div
                          className={styles.level}
                          style={{
                            backgroundColor: pattern.level ? getColor(pattern.level) : 'white',
                            color: 'black'
                          }}>
                          {pattern.level}
                        </div>
                      </td>
                      <td>{pattern.text}</td>
                      <td width={150} className={styles.patternName}>
                        {pattern.pattern_name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </li>
        ))}
      </ul>
      <p className={styles.overall}>
        Overall grammar level:{' '}
        <span className={styles.level} style={{ background: getColor(data.grammar_level) }}>
          {data.grammar_level}
        </span>
      </p>
    </Paper>
  )
}

export default GrammarContainer
