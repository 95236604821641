import { ApiReqState, Paginated, PaginatedStatus } from '../../../shared/api/types'
import { BookModel, TargetCountries } from '../Book/types'
import { VideoModel } from '../Video/types'

export interface ExerciseState {
  collection: {
    results: ExerciseModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
  bookExercises: {
    bookId: BookModel['_id'] | null
    results: ExerciseModel[]
    reqState: ApiReqState
  }
}

export enum EXERCISE_ACTIONS {
  LOAD_COLLECTION = 'EXERCISE__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'EXERCISE__LOAD_COLLECTION_REQ_STATE',
  NOT_ASSIGNED_SET_NEW = 'EXERCISE__NOT_ASSIGNED_SET_NEW',
  NOT_ASSIGNED_UPDATE = 'EXERCISE__NOT_ASSIGNED_UPDATE',
  NOT_ASSIGNED_DELETE = 'EXERCISE__NOT_ASSIGNED_DELETE',
  OF_BOOK_SET_RESULTS = 'EXERCISE__OF_BOOK_SET_RESULTS',
  OF_BOOK_SET_REQ_STATE = 'EXERCISE__OF_BOOK_SET_REQ_STATE',
  OF_BOOK_SET_NEW = 'EXERCISE__OF_BOOK_SET_NEW',
  OF_BOOK_UPDATE_SINGLE = 'EXERCISE__OF_BOOK_UPDATE_SINGLE',
  OF_BOOK_DELETE_SINGLE = 'EXERCISE__OF_BOOK_DELETE_SINGLE'
}

export type ExerciseModelBase<T extends ExerciseType, D> = {
  _id: string
  points: number
  imageUrl?: string
  recordingUrl?: string
  recordingUsUrl?: string
  type: T
  data: D

  // for assigned to book
  bookId?: BookModel['_id']

  // for not assigned to book
  difficultyLevel?: number
  targetCountries?: TargetCountries[]
}

export type ExerciseModel =
  | ExerciseReadSentenceModel
  | ExerciseReadWordRaceModel
  | ExerciseWordPracticeModel
  | ExerciseSentenceBuilderModel
  | ExerciseWordBuilderModel
  | ExerciseMultiChoiceQuestionModel
  | ExerciseConversationModel

export type ExerciseReadSentenceModel = ExerciseModelBase<ExerciseType.ReadSentence, ExerciseReadSentenceData>
export type ExerciseReadWordRaceModel = ExerciseModelBase<ExerciseType.ReadWordRace, ExerciseReadWordRaceData>
export type ExerciseWordPracticeModel = ExerciseModelBase<ExerciseType.WordPractice, ExerciseWordPracticeData>
export type ExerciseConversationModel = ExerciseModelBase<ExerciseType.Conversation, ExerciseConversationData>

export type ExerciseSentenceBuilderModel = ExerciseModelBase<
  ExerciseType.SentenceBuilder,
  ExerciseSentenceWordBuilderData
>
export type ExerciseWordBuilderModel = ExerciseModelBase<ExerciseType.WordBuilder, ExerciseSentenceWordBuilderData>
export type ExerciseMultiChoiceQuestionModel = ExerciseModelBase<
  ExerciseType.MultiChoiceQuestion,
  ExerciseMultiChoiceQuestionData
>

export enum ComprehensionType {
  TEXT = 'Text',
  AUDIO = 'Audio',
  IMAGE = 'Image'
}

export type ConversationElement = {
  video: VideoModel
  instructorScript?: string
  learnerScript?: string
}

export type ExerciseReadSentenceData = {
  sentence?: string
  sentenceUs: string
  imageTitle?: string
  imageAttribute?: string
}
export type ExerciseReadWordRaceData = { words: string[] }
export type ExerciseWordPracticeData = { words: string[] }
export type ExerciseConversationData = {
  conversationElements: ConversationElement[]
}
export type ExerciseSentenceWordBuilderData = {
  wordOrSentence?: string
  missingIndexes?: number[]
  wordOrSentenceUs: string
  missingIndexesUs: number[]
  distractors?: string[]
  distractorsUs?: string[]
  imageTitle?: string
  imageAttribute?: string
}

type ComprehensionOption = string | undefined | null

export type ExerciseMultiChoiceQuestionData = {
  answer?: number
  options?: string[]
  imageOption1?: ComprehensionOption
  imageOption2?: ComprehensionOption
  imageOption3?: ComprehensionOption
  imageOption4?: ComprehensionOption
  audioOption1?: ComprehensionOption
  audioOption2?: ComprehensionOption
  audioOption3?: ComprehensionOption
  audioOption4?: ComprehensionOption
  audioOptionUs1?: ComprehensionOption
  audioOptionUs2?: ComprehensionOption
  audioOptionUs3?: ComprehensionOption
  audioOptionUs4?: ComprehensionOption
  question?: string
  audioQuestion?: string
  audioQuestionUs?: string
  imageQuestion?: string
  answerUs: number
  optionsUs: string[]
  questionUs: string
  answerType: ComprehensionType
  questionType: ComprehensionType
  imageTitle?: string
  imageAttribute?: string
  imageOption1Attribute?: string
  imageOption2Attribute?: string
  imageOption3Attribute?: string
  imageOption4Attribute?: string
}

export type ExerciseFormFields<T extends ExerciseModel> = Pick<
  T,
  'points' | 'type' | 'difficultyLevel' | 'targetCountries'
> &
  T['data']

export enum ExerciseType {
  ReadWordRace = 'ReadWordRace',
  ReadSentence = 'ReadSentence',
  WordBuilder = 'WordBuilder',
  SentenceBuilder = 'SentenceBuilder',
  MultiChoiceQuestion = 'MultiChoiseQuestion',
  WordPractice = 'WordPractice',
  Conversation = 'Conversation'
}

export interface ExerciseLoadCollection {
  type: EXERCISE_ACTIONS.LOAD_COLLECTION
  collection: Paginated<ExerciseModel>
}

export interface ExerciseLoadCollectionReqState {
  type: EXERCISE_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export interface ExerciseNotAssignedSetNew {
  type: EXERCISE_ACTIONS.NOT_ASSIGNED_SET_NEW
  data: ExerciseModel
}

export interface ExerciseNotAssignedUpdate {
  type: EXERCISE_ACTIONS.NOT_ASSIGNED_UPDATE
  data: ExerciseModel
  exerciseId: ExerciseModel['_id']
}

export interface ExerciseNotAssignedDelete {
  type: EXERCISE_ACTIONS.NOT_ASSIGNED_DELETE
  exerciseId: ExerciseModel['_id']
}

export interface ExerciseOfBookSetResults {
  type: EXERCISE_ACTIONS.OF_BOOK_SET_RESULTS
  bookId: BookModel['_id']
  results: ExerciseModel[]
}

export interface ExerciseOfBookSetReqState {
  type: EXERCISE_ACTIONS.OF_BOOK_SET_REQ_STATE
  reqState: ApiReqState
}

export interface ExerciseOfBookSetNew {
  type: EXERCISE_ACTIONS.OF_BOOK_SET_NEW
  bookId: BookModel['_id']
  data: ExerciseModel
}

export interface ExerciseOfBookUpdateSingle {
  type: EXERCISE_ACTIONS.OF_BOOK_UPDATE_SINGLE
  bookId: BookModel['_id']
  data: ExerciseModel
  exerciseId: ExerciseModel['_id']
}

export interface ExerciseOfBookDeleteSingle {
  type: EXERCISE_ACTIONS.OF_BOOK_DELETE_SINGLE
  bookId: BookModel['_id']
  exerciseId: ExerciseModel['_id']
}

export type ExerciseActions =
  | ExerciseLoadCollection
  | ExerciseLoadCollectionReqState
  | ExerciseNotAssignedSetNew
  | ExerciseNotAssignedUpdate
  | ExerciseNotAssignedDelete
  | ExerciseOfBookSetResults
  | ExerciseOfBookSetReqState
  | ExerciseOfBookSetNew
  | ExerciseOfBookUpdateSingle
  | ExerciseOfBookDeleteSingle
