import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import * as React from 'react'
import { useCallback } from 'react'
import * as Yup from 'yup'
import { DEFAULT_EXERCISE_POINTS, DEFAULT_VIDEO_OVERLAY_EXERCISE_POINTS } from '../../shared/constants/points'
import { getModifiedValues } from '../../shared/helpers/getModifiedValues'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import ExerciseApi from '../../store/services/Exercise/exerciseApi'
import { ComprehensionType, ExerciseMultiChoiceQuestionModel, ExerciseType } from '../../store/services/Exercise/types'
import { ExerciseFormFactory } from '../ExerciseFormFactory/ExerciseFormFactory'
import { ExerciseFormProps } from '../types'
import styles from './Comprehension.module.scss'
import { AudioAnswerForm, AudioQuestionForm } from './ComprehensionForms/Audio'
import { ImageAnswerForm, ImageQuestionForm } from './ComprehensionForms/Image'
import { TextAnswerForm, TextQuestionForm } from './ComprehensionForms/Text'
import { ComprehensionFormFields, ComprehensionImage } from './types'
const validationSchema = Yup.object().shape({
  points: ValidationRules.points,
  questionUs: ValidationRules.required,
  answerUs: ValidationRules.requiredNum,
  imageTitle: ValidationRules.optionalWithMaxLength,
  imageAttribute: ValidationRules.optionalWithMaxLength,
  imageOption1Attribute: ValidationRules.optionalWithMaxLength,
  imageOption2Attribute: ValidationRules.optionalWithMaxLength,
  imageOption3Attribute: ValidationRules.optionalWithMaxLength,
  imageOption4Attribute: ValidationRules.optionalWithMaxLength,
  optionsUs: ValidationRules.options
})
interface Props extends ExerciseFormProps {
  data?: ExerciseMultiChoiceQuestionModel
  isVideoOverlay?: boolean
}

const setImagePayloadValue = ({
  field,
  imageData,
  initialImageData,
  values
}: {
  field: string
  imageData: ComprehensionImage
  initialImageData: ComprehensionImage
  values: { [key: string]: unknown }
}) => {
  const imageUploaded = imageData.blob instanceof Blob
  const imageDeleted = imageData.url === '' && initialImageData.url !== ''

  if (imageUploaded) {
    values[field] = imageData.blob
  } else if (imageDeleted) {
    values[field] = null
  } else {
    // no change, remove from payload
    delete values[field]
  }
}

const Comprehension: React.FC<Props> = ({ data, isVideoOverlay, formDisabled, ...exerciseFormProps }) => {
  const isNew = !data
  const defaultPoints = isVideoOverlay ? DEFAULT_VIDEO_OVERLAY_EXERCISE_POINTS : DEFAULT_EXERCISE_POINTS

  const initialValues: ComprehensionFormFields = isNew
    ? {
        points: defaultPoints,
        answer: 0,
        options: [],
        question: '',
        answerUs: 0,
        optionsUs: [],
        questionUs: '',
        type: ExerciseType.MultiChoiceQuestion,
        audioQuestion: undefined,
        audioQuestionUs: undefined,
        imageQuestion: { url: '' },
        questionType: ComprehensionType.TEXT,
        answerType: ComprehensionType.TEXT,
        imageOption1: { url: '' },
        imageOption2: { url: '' },
        imageOption3: { url: '' },
        imageOption4: { url: '' },
        audioOption1: undefined,
        audioOption2: undefined,
        audioOption3: undefined,
        audioOption4: undefined,
        audioOptionUs1: undefined,
        audioOptionUs2: undefined,
        audioOptionUs3: undefined,
        audioOptionUs4: undefined,
        imageTitle: '',
        imageAttribute: '',
        imageOption1Attribute: '',
        imageOption2Attribute: '',
        imageOption3Attribute: '',
        imageOption4Attribute: ''
      }
    : {
        points: data?.points || defaultPoints,
        answer: data?.data.answer || 0,
        options: data?.data.options || [],
        question: data?.data.question || '',
        answerUs: data?.data.answerUs || 0,
        optionsUs: data?.data.optionsUs || [],
        questionUs: data?.data.questionUs || '',
        type: data?.type || ExerciseType.MultiChoiceQuestion,
        audioQuestion: data?.data.audioQuestion || '',
        audioQuestionUs: data?.data.audioQuestionUs || '',
        imageQuestion: { url: data?.data.imageQuestion || '', blob: null },
        questionType: data?.data.questionType || ComprehensionType.TEXT,
        answerType: data?.data.answerType || ComprehensionType.TEXT,
        imageOption1: { url: data?.data.imageOption1 || '', blob: null },
        imageOption2: { url: data?.data.imageOption2 || '', blob: null },
        imageOption3: { url: data?.data.imageOption3 || '', blob: null },
        imageOption4: { url: data?.data.imageOption4 || '', blob: null },
        audioOption1: data?.data.audioOption1,
        audioOption2: data?.data.audioOption2,
        audioOption3: data?.data.audioOption3,
        audioOption4: data?.data.audioOption4,
        audioOptionUs1: data?.data.audioOptionUs1,
        audioOptionUs2: data?.data.audioOptionUs2,
        audioOptionUs3: data?.data.audioOptionUs3,
        audioOptionUs4: data?.data.audioOptionUs4,
        imageTitle: data?.data.imageTitle,
        imageAttribute: data?.data.imageAttribute,
        imageOption1Attribute: data?.data.imageOption1Attribute,
        imageOption2Attribute: data?.data.imageOption2Attribute,
        imageOption3Attribute: data?.data.imageOption3Attribute,
        imageOption4Attribute: data?.data.imageOption4Attribute
      }

  const handleSubmit = useCallback(
    ({
      type,
      points,
      question,
      options,
      answer,
      questionUs,
      optionsUs,
      answerUs,
      imageQuestion,
      answerType,
      questionType,
      imageOption1,
      imageOption2,
      imageOption3,
      imageOption4,
      audioOption1,
      audioOption2,
      audioOption3,
      audioOption4,
      audioOptionUs1,
      audioOptionUs2,
      audioOptionUs3,
      audioOptionUs4,
      audioQuestion,
      audioQuestionUs,
      imageTitle,
      imageAttribute,
      imageOption1Attribute,
      imageOption2Attribute,
      imageOption3Attribute,
      imageOption4Attribute
    }: ComprehensionFormFields) => {
      const values: { [key: string]: unknown } = {
        type,
        points,
        question,
        options,
        answer,
        questionUs,
        optionsUs,
        answerUs,
        imageQuestion,
        answerType,
        questionType,
        imageOption1,
        imageOption2,
        imageOption3,
        imageOption4,
        audioOption1,
        audioOption2,
        audioOption3,
        audioOption4,
        audioOptionUs1,
        audioOptionUs2,
        audioOptionUs3,
        audioOptionUs4,
        audioQuestion,
        audioQuestionUs,
        imageTitle,
        imageAttribute,
        imageOption1Attribute,
        imageOption2Attribute,
        imageOption3Attribute,
        imageOption4Attribute
      }

      setImagePayloadValue({
        field: 'imageQuestion',
        imageData: imageQuestion,
        initialImageData: initialValues.imageQuestion,
        values
      })
      setImagePayloadValue({
        field: 'imageOption1',
        imageData: imageOption1,
        initialImageData: initialValues.imageOption1,
        values
      })
      setImagePayloadValue({
        field: 'imageOption2',
        imageData: imageOption2,
        initialImageData: initialValues.imageOption2,
        values
      })
      setImagePayloadValue({
        field: 'imageOption3',
        imageData: imageOption3,
        initialImageData: initialValues.imageOption3,
        values
      })
      setImagePayloadValue({
        field: 'imageOption4',
        imageData: imageOption4,
        initialImageData: initialValues.imageOption4,
        values
      })

      if (isNew) {
        return values
      }

      const modifiedValues = getModifiedValues(values, initialValues)

      return modifiedValues
    },
    // eslint-disable-next-line
    [isNew]
  )

  const handleTranslationClick = useCallback(async (text: string, translateTo: 'uk' | 'us') => {
    try {
      const translation = await ExerciseApi.translateSpelling(text, translateTo)
      return translation.data
    } catch (e) {}
  }, [])

  return (
    <ExerciseFormFactory
      {...exerciseFormProps}
      formDisabled={formDisabled}
      exerciseId={data?._id}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ handleChange, values, errors, touched, setFieldTouched, setFieldValue, setValues }) => {
        const formProps = {
          handleTranslationClick,
          handleChange,
          setFieldTouched,
          setFieldValue,
          values,
          touched,
          errors,
          formDisabled: !!formDisabled
        }
        return (
          <div data-testid="ReadingPage">
            <Typography variant="h5" gutterBottom>
              {isNew ? 'Add' : 'Update'} Comprehension
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="points"
              label="Stars"
              name="points"
              size="small"
              inputProps={{ min: 0 }}
              onChange={handleChange('points')}
              value={values.points}
              error={touched.points && !!errors.points}
              helperText={(touched.points && errors.points) || ''}
              onBlur={() => setFieldTouched('points')}
              disabled={formDisabled}
            />

            {/* QUESTION SECTION START */}
            <Typography variant="h5">Question</Typography>
            <FormControl variant="outlined" fullWidth margin="normal" size="small">
              <InputLabel id="questionType">Question type</InputLabel>
              <Select
                labelId="questionType"
                name="questionType"
                id="questionType"
                value={values.questionType}
                onChange={handleChange('questionType')}
                label="Question type">
                {Object.values(ComprehensionType)
                  .filter((type) => (isVideoOverlay ? type === ComprehensionType.TEXT : true))
                  .map((type) => (
                    <MenuItem value={`${type}`} key={type}>
                      {type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {values.questionType === ComprehensionType.TEXT && (
              <TextQuestionForm {...formProps} isVideoOverlay={isVideoOverlay} />
            )}

            {values.questionType === ComprehensionType.IMAGE && <ImageQuestionForm {...formProps} />}
            {values.questionType === ComprehensionType.AUDIO && <AudioQuestionForm {...formProps} />}
            {/* QUESTION SECTION END */}

            {/* IMAGE TITLE SECTION START */}

            {values.questionType === ComprehensionType.IMAGE && (
              <>
                <Grid container spacing={2}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="imageTitle"
                    label="Image Title"
                    name="imageTitle"
                    size="small"
                    onChange={handleChange('imageTitle')}
                    value={values.imageTitle}
                    error={touched.imageTitle && !!errors.imageTitle}
                    helperText={(touched.imageTitle && errors.imageTitle) || ''}
                    onBlur={() => setFieldTouched('imageTitle')}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    minRows={3}
                    id="imageAttribute"
                    label="Image Attribution"
                    name="imageAttribute"
                    size="small"
                    onChange={handleChange('imageAttribute')}
                    value={values.imageAttribute}
                    error={touched.imageAttribute && !!errors.imageAttribute}
                    helperText={
                      (touched.imageAttribute && errors.imageAttribute) ||
                      'How to add a link: [Hyperlink text](http://www.example.com)'
                    }
                    onBlur={() => setFieldTouched('imageAttribute')}
                    disabled={formDisabled}
                  />
                </Grid>
              </>
            )}
            {/* IMAGE TITLE SECTION END */}

            <Divider className={styles.divider} />

            {/* ANSWER SECTION START */}
            <Typography variant="h5">Answer</Typography>
            <FormControl variant="outlined" fullWidth margin="normal" size="small">
              <TextField
                select
                name="answerType"
                id="answerType"
                error={!!errors.optionsUs}
                helperText={errors.optionsUs || ''}
                value={values.answerType}
                onChange={(e) => {
                  setValues(
                    {
                      ...values,
                      optionsUs: [],
                      options: [],
                      imageOption1: { url: '' },
                      imageOption2: { url: '' },
                      imageOption3: { url: '' },
                      imageOption4: { url: '' },
                      audioOption1: null,
                      audioOption2: null,
                      audioOption3: null,
                      audioOption4: null,
                      audioOptionUs1: null,
                      audioOptionUs2: null,
                      audioOptionUs3: null,
                      audioOptionUs4: null,
                      answerType: e.target.value as ComprehensionType
                    },
                    true
                  )
                }}
                label="Answer type">
                {Object.values(ComprehensionType)
                  .filter(
                    (type) =>
                      (type !== ComprehensionType.IMAGE || values.questionType !== ComprehensionType.IMAGE) &&
                      !(isVideoOverlay && type === ComprehensionType.AUDIO)
                  )
                  .map((type) => (
                    <MenuItem value={`${type}`} key={type}>
                      {type}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>

            {values.answerType === ComprehensionType.TEXT && (
              <TextAnswerForm {...formProps} isVideoOverlay={isVideoOverlay} />
            )}
            {values.answerType === ComprehensionType.IMAGE && (
              <ImageAnswerForm {...formProps} isVideoOverlay={isVideoOverlay} />
            )}
            {values.answerType === ComprehensionType.AUDIO && <AudioAnswerForm {...formProps} />}
            {/* ANSWER SECTION END */}
          </div>
        )
      }}
    </ExerciseFormFactory>
  )
}

export default Comprehension
