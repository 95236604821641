import {
  TeacherActions,
  TEACHER_ACTIONS,
  TeacherLoadCollectionReqState,
  TeacherModel,
  TeacherLoadCollection,
  TeacherState
} from './types'
import { Dispatch } from 'redux'
import { RootState } from '../../types'
import { createSelector } from 'reselect'
import { Logger } from '../../../shared/logger/Logger'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import StudentApi from './teacherApi'

export const TeacherInitialState: TeacherState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  }
}

const teacherReducer = (state: TeacherState = TeacherInitialState, action: TeacherActions) => {
  switch (action.type) {
    case TEACHER_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case TEACHER_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    default:
      return state
  }
}

export default teacherReducer

// ACTIONS
const setTeacherLoadReqState = (reqState: ApiReqState): TeacherLoadCollectionReqState => ({
  type: TEACHER_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setTeacherLoadCollection = (collection: Paginated<TeacherModel>): TeacherLoadCollection => ({
  type: TEACHER_ACTIONS.LOAD_COLLECTION,
  collection
})

export const getTeacherCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<TeacherLoadCollection | TeacherLoadCollectionReqState>
) => {
  try {
    dispatch(setTeacherLoadReqState(ApiReqState.PENDING))

    const collection = (await StudentApi.load(params)).data
    dispatch(setTeacherLoadCollection(collection))

    dispatch(setTeacherLoadReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setTeacherLoadReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(typeof e === 'string' ? e : undefined)
  }
}

// SELECTORS
export const selectTeacherCollection = (state: RootState) => state.teacher.collection
export const selectTeachers = createSelector(selectTeacherCollection, (collection) => collection.results)
export const selectTeachersStatus = createSelector(selectTeacherCollection, (collection) => collection.status)
export const selectTeachersReqState = createSelector(selectTeacherCollection, (collection) => collection.reqState)
