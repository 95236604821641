import React from 'react'
import styles from './VideoPreview.module.scss'

interface Props {
  src: string
  className?: string
}

export const VideoPreview = ({ src, className }: Props) => {
  return (
    <video className={`${styles.video} ${className || ''}`} src={src} controls>
      <source src={src} />
    </video>
  )
}
