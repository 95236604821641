import { FILE_SIZES } from '@astrid/shared/dist/constants'
import { Button, LinearProgress } from '@material-ui/core'
import axios, { CancelTokenSource } from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { VideoModel } from '../../../store/services/Video/types'
import VideoApi from '../../../store/services/Video/videoApi'
import { printApiMessage } from '../../api/apiMessages'
import { jsonToFormData } from '../../helpers/jsonToFormData'
import { AlertContext } from '../AlertContext/AlertContext'
import styles from './VideoUploader.module.scss'

interface Props {
  onUploaded?: (videoData: VideoModel) => void
  onCancel?: () => void
}

const VideoUploader: React.FC<Props> = ({ onUploaded, onCancel }) => {
  const { showAlert } = useContext(AlertContext)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const cancelTokenSource = useRef<CancelTokenSource>(axios.CancelToken.source())
  const [progress, setProgress] = useState<number | undefined>(undefined)

  const handleCancel = () => {
    cancelTokenSource.current.cancel()
    onCancel && onCancel()
  }

  const handleUpload = async () => {
    const file = fileInputRef.current?.files && fileInputRef.current?.files.length > 0 && fileInputRef.current?.files[0]

    if (file) {
      if (file.size > FILE_SIZES.MAX_VIDEO_MIB * 1024 * 1024) {
        showAlert(`File is too large, max ${FILE_SIZES.MAX_VIDEO_MIB} MiB`)
        return
      }
      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentage = Math.floor((progressEvent.loaded / file.size) * 100)
          setProgress(percentage)
        },
        cancelToken: cancelTokenSource.current.token
      }

      try {
        const { data } = await VideoApi.upload(jsonToFormData({ video: file, hasLength: true }), config)
        onUploaded && onUploaded(data)
      } catch (error) {
        if (!axios.isCancel(error)) {
          showAlert(printApiMessage(error))
        }
      }
    } else {
      showAlert('No video file selected', 'error')
    }
  }

  return (
    <div className={styles.videoUploader}>
      <div>
        <input
          ref={fileInputRef}
          type="file"
          accept="video/*"
          disabled={progress !== undefined}
          data-testid="video-upload"
        />
        {progress !== undefined && (
          <LinearProgress className={styles.progressBar} variant="determinate" value={progress} />
        )}
      </div>
      <Button variant="outlined" color="primary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={handleUpload} disabled={progress !== undefined}>
        Upload
      </Button>
    </div>
  )
}

export default VideoUploader
