import { BookType } from '@astrid/components'
import { Avatar, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { ValidationRules } from '../../../shared/helpers/ValidationRules'
import { selectBooks } from '../../../store/services/Book/bookReducer'
import { BookModel } from '../../../store/services/Book/types'
import { StudySetComponentBookModel, StudySetComponentType } from '../../../store/services/StudySet/types'
import { RootState } from '../../../store/types'
import { ComponentForm } from '../../components/ComponentForm/ComponentForm'
import { ComponentFormProps } from '../../types'
import styles from './Book.module.scss'

const validationSchema = Yup.object().shape({
  bookId: ValidationRules.required
})

export const BookForm = ({ data, onCancel, onSave }: ComponentFormProps<StudySetComponentBookModel>) => {
  const books = useSelector<RootState, BookModel[]>((state) => selectBooks(state))

  const handleSubmit = (values: { bookId: string }): StudySetComponentBookModel => {
    return {
      type: StudySetComponentType.Book,
      bookType: data.bookType || BookType.Listening,
      bookId: values.bookId
    }
  }

  return (
    <ComponentForm
      validationSchema={validationSchema}
      initialValues={{
        bookId: data.bookId || ''
      }}
      onSubmitForm={handleSubmit}
      onCancel={onCancel}
      onSave={onSave}>
      {({ setFieldValue, values, touched, errors, handleChange, setFieldTouched }) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={books.filter((book) => book.bookType === data.bookType! || book.bookType === BookType.Mix)}
                onChange={(_, value) => setFieldValue('bookId', value?._id || '')}
                value={books.find((book) => book._id === values.bookId) || null}
                getOptionLabel={(option) => option.title}
                renderOption={(option) => (
                  <div className={styles.option}>
                    <Avatar alt={option.title} src={option.imageUrl} variant="square" className={styles.avatar} />
                    <span>{option.title}</span>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Book"
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    error={'bookId' in touched && touched.bookId && 'bookId' in errors && !!errors.bookId}
                    helperText={('bookId' in touched && touched.bookId && 'bookId' in errors && errors.bookId) || ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        )
      }}
    </ComponentForm>
  )
}
