import { CEFRLevels } from '@astrid/components'
import {
  Button,
  CircularProgress,
  debounce,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { ArrowDropDown, Visibility, VisibilityOff } from '@material-ui/icons'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import DashboardToolbar from '../../Dashboard/DashboardToolbar/DashboardToolbar'
import { ROUTES } from '../../routes/routes'
import { ApiReqState } from '../../shared/api/types'
import SearchField from '../../shared/components/SearchField/SearchField'
import SelectCefrLevels from '../../shared/components/SelectCefrLevels/SelectCefrLevels'
import TableLink from '../../shared/components/TableLink/TableLink'
import TableOverlay from '../../shared/components/TableOverlay/TableOverlay'
import CollectionQueryHelper from '../../shared/helpers/CollectionQueryHelper'
import { Logger } from '../../shared/logger/Logger'
import {
  getBookCollection,
  selectBooks,
  selectBooksReqState,
  selectBooksStatus
} from '../../store/services/Book/bookReducer'
import { BookModel } from '../../store/services/Book/types'
import { CONTENT_TAG_AGE_OPTIONS } from '../../store/services/ContentTag/types'
import { RootState } from '../../store/types'
import styles from './BooksTable.module.scss'
import StatusFilter from './StatusFilter/StatusFilter'

export const BOOKS_PER_PAGE = 50

const BooksTable = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()
  const books = useSelector(selectBooks)
  const booksResStatus = useSelector(selectBooksStatus)
  const bookReqState = useSelector(selectBooksReqState)

  React.useEffect(() => {
    ;(async () => {
      try {
        const params = {
          limit: BOOKS_PER_PAGE,
          ...CollectionQueryHelper.getParamsFromQueryString(location.search)
        }
        await dispatch(getBookCollection(params))
      } catch (error) {
        Logger.log(error)
      }
    })()
  }, [location.search, dispatch])

  const handleCefrFilter = async (cefrLevels: CEFRLevels[]) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { cefrLevel: cefrLevels }, true)
    history.replace({ search: params })
  }

  const handleContentFilter = async (contentTag?: CONTENT_TAG_AGE_OPTIONS) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { contentTag }, true)
    history.replace({ search: params })
  }

  const handleStatusFilter = async (visible: boolean | null) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { visible }, true)
    history.replace({ search: params })
  }

  const handleChangePage = (page: number) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { page })
    history.replace({ search: params })
  }

  const handleSort = (key: keyof BookModel) => () => {
    const sort = CollectionQueryHelper.getToggledSortValueAsQueryString(key, location.search)
    const newParams = CollectionQueryHelper.buildQueryString(location.search, { sort: sort })
    history.replace({ search: newParams })
  }

  const handleSearchQuery = debounce((search: string) => {
    const params = CollectionQueryHelper.buildQueryString(history.location.search, { search }, true)
    history.replace({ search: params })
  }, 300)

  const { sort, filter, search, contentTag } = CollectionQueryHelper.getParamsFromQueryString(location.search)

  const isLoading = bookReqState === ApiReqState.IDLE || bookReqState === ApiReqState.PENDING

  return (
    <>
      <DashboardToolbar>
        <div className={styles.toolbarContent}>
          <Typography component="h1" variant="h6">
            Books
          </Typography>
          <Button color={'primary'} variant={'contained'} onClick={() => history.push(`${ROUTES.BOOK}/new`)}>
            Add new
          </Button>

          <div className={styles.toolbarFilters}>
            <RadioGroup
              aria-label="contentTag"
              name="contentTag"
              value={contentTag ?? ''}
              onChange={(e) => handleContentFilter((e.target.value as CONTENT_TAG_AGE_OPTIONS) || undefined)}
              row>
              <FormControlLabel value={''} control={<Radio />} label="All" />
              <FormControlLabel value={CONTENT_TAG_AGE_OPTIONS.ADULT} control={<Radio />} label="Adult" />
              <FormControlLabel value={CONTENT_TAG_AGE_OPTIONS.KID} control={<Radio />} label="Kid" />
            </RadioGroup>
            <SearchField
              containerClassName={styles.searchField}
              defaultValue={search}
              onChange={(e) => handleSearchQuery(e.target.value)}
              onClear={() => handleSearchQuery('')}
            />
          </div>
        </div>
      </DashboardToolbar>
      <TableContainer component={Paper} data-testid={'BooksTable'}>
        {!isLoading && books.length === 0 && (
          <TableOverlay>
            <Typography>Books not found.</Typography>
          </TableOverlay>
        )}
        {isLoading && (
          <TableOverlay data-testid="BooksTableLoader">
            <CircularProgress size={40} />
          </TableOverlay>
        )}
        <Table aria-label="customized table" className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 420 }}>
                <TableSortLabel
                  data-testid={'SortByTitleBookLabel'}
                  active={CollectionQueryHelper.isSortActive('title', sort)}
                  direction={CollectionQueryHelper.printSortDirection('title', sort)}
                  onClick={handleSort('title')}
                  IconComponent={ArrowDropDown}>
                  Book name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('author', sort)}
                  direction={CollectionQueryHelper.printSortDirection('author', sort)}
                  onClick={handleSort('author')}
                  IconComponent={ArrowDropDown}>
                  Author
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('cefrLevel', sort)}
                  direction={CollectionQueryHelper.printSortDirection('cefrLevel', sort)}
                  onClick={handleSort('cefrLevel')}
                  IconComponent={ArrowDropDown}>
                  CEFR
                </TableSortLabel>
                <SelectCefrLevels
                  selectedCefrLevels={filter?.cefrLevel || []}
                  onSelectedCefrLevelsChange={(cefrLevels) => handleCefrFilter(cefrLevels)}
                />
              </TableCell>
              <TableCell align="center">
                Status
                <StatusFilter
                  status={filter?.visible ?? null}
                  onSetStatusChange={(status) => handleStatusFilter(status)}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books.map((book) => (
              <TableRow hover key={book._id} data-testid={'BookListRow'}>
                <TableCell component="th" scope="row">
                  <TableLink to={`${ROUTES.BOOK}/${book._id}`}>{book.titleUs || book.title}</TableLink>
                </TableCell>
                <TableCell>
                  <TableLink to={`${ROUTES.BOOK}/${book._id}`}>{book.author}</TableLink>
                </TableCell>
                <TableCell align="center">
                  <TableLink to={`${ROUTES.BOOK}/${book._id}`}>{book.cefrLevel}</TableLink>
                </TableCell>
                <TableCell align="center">
                  <TableLink to={`${ROUTES.BOOK}/${book._id}`}>
                    {book.visible ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff color="disabled" fontSize="small" />
                    )}
                  </TableLink>
                </TableCell>
              </TableRow>
            ))}
            {books.length === 0 && (
              <TableRow className={styles.emptyRow} data-testid={'BookListEmptyRow'}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {booksResStatus && (
          <TablePagination
            component="div"
            colSpan={3}
            count={booksResStatus.total}
            rowsPerPage={BOOKS_PER_PAGE}
            rowsPerPageOptions={[]}
            page={booksResStatus.offset / BOOKS_PER_PAGE}
            onPageChange={(_, page) => handleChangePage(page)}
          />
        )}
      </TableContainer>
    </>
  )
}

export default BooksTable
