import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { Constants as AstridConstants } from '@astrid/shared'

import styles from './RecordingField.module.scss'

interface RecordingFieldProps {
  file: File | string | null | undefined
  name: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  removeRecording: () => void
  uploadRecording: (rec: File) => void
}

const RECORDING_SIZE_LIMIT_MIB = AstridConstants.FILE_SIZES.MAX_AUDIO_RECORDING_MIB

const RecordingField: React.FC<RecordingFieldProps> = ({
  file,
  name,
  removeRecording,
  uploadRecording,
  style,
  disabled = false,
  className
}) => {
  const [error, setError] = useState<'size' | 'format' | false>(false)

  const checkRecordingAndUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = /(\.wav|\.mp3)$/i
    const recordingFile = e.target.files?.item(0)
    const fileName = e.target.value
    if (recordingFile) {
      if (recordingFile.size > RECORDING_SIZE_LIMIT_MIB * 1024 * 1024) {
        setError('size')
        return
      }
      if (!allowedExtensions.exec(fileName)) {
        setError('format')
        return
      }
      setError(false)
      uploadRecording(recordingFile)
    }
  }

  if (file) {
    const fileFormat = (typeof file === 'string' ? file : file.name).split('.').pop()

    return (
      <div className={`${styles.player} ${className ? className : ''}`} style={style}>
        <audio controls>
          <source src={typeof file === 'string' ? file : URL.createObjectURL(file)} type={`audio/${fileFormat}`} />
        </audio>
        <Button onClick={removeRecording} variant="outlined" color="primary" disabled={disabled}>
          Delete recording
        </Button>
      </div>
    )
  }

  return (
    <div className={`${styles.upload} ${className ? className : ''}`} style={style}>
      <input
        type="file"
        name={`${name}-recording`}
        id={`${name}-recording`}
        accept=".mp3,.wav"
        disabled={disabled}
        onChange={checkRecordingAndUpload}
      />
      <label htmlFor={`${name}-recording`} aria-disabled={!!disabled}>
        + Add recording
      </label>
      {!!error && (
        <span>
          {error === 'size'
            ? `File too large. Limit is ${RECORDING_SIZE_LIMIT_MIB}MiB.`
            : 'Please use a mp3 or wav format for the recording.'}
        </span>
      )}
    </div>
  )
}

export default RecordingField
