import { Checkbox, FormControlLabel, IconButton, Popover } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React from 'react'
import styles from './StatusFilter.module.scss'

interface StatusFilterProps {
  status: boolean | null
  onSetStatusChange: (status: boolean | null) => void
}

const StatusFilter = ({ status, onSetStatusChange: setStatus }: StatusFilterProps) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'SelectCefrLevelsPopper' })

  const handleChange = (newStatus: boolean) => {
    if (newStatus === status) {
      setStatus(null)
    } else {
      setStatus(newStatus)
    }
  }

  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <FilterList />
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={styles.popperContainer}>
          <FormControlLabel
            label={`Published`}
            control={<Checkbox onChange={() => handleChange(true)} checked={status === true} />}
          />
          <FormControlLabel
            label={`Unpublished`}
            control={<Checkbox onChange={() => handleChange(false)} checked={status === false} />}
          />
        </div>
      </Popover>
    </>
  )
}

export default StatusFilter
