import * as React from 'react'
import DashboardNavigation from './DashboardNavigation/DashboardNavigation'
import styles from './Dashboard.module.scss'

interface Props {
  children: React.ReactElement
}

const Dashboard: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.dashboard} data-testid={'Dashboard'}>
      <DashboardNavigation />
      <main className={styles.container}>{children}</main>
    </div>
  )
}

export default Dashboard
