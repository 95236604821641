import { BookType } from '@astrid/components'
import { ExerciseType } from '../store/services/Exercise/types'
import { StudySetComponentType } from '../store/services/StudySet/types'

export const studySetComponentOptions = [
  { type: StudySetComponentType.Book, bookType: BookType.Listening },
  { type: StudySetComponentType.Book, bookType: BookType.Reading },
  { type: StudySetComponentType.Video },
  { type: StudySetComponentType.Animation },
  { type: StudySetComponentType.SpeakingChallenge },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.WordBuilder },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.SentenceBuilder },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.MultiChoiceQuestion },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.WordPractice },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.Conversation },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.ReadSentence, sentenceType: BookType.Reading },
  { type: StudySetComponentType.Exercise, exerciseType: ExerciseType.ReadSentence, sentenceType: BookType.Listening }
] as const
