import { Box, Card, CardContent } from '@material-ui/core'
import { VolumeUp } from '@material-ui/icons'
import React from 'react'
import CustomizedAvatar from '../../../../Book/CustomizedAvatar/CustomizedAvatar'
import { ExerciseMultiChoiceQuestionModel } from '../../../../store/services/Exercise/types'
import styles from './ComprehensionView.module.scss'

interface Props {
  exercise: ExerciseMultiChoiceQuestionModel
}

export const ComprehensionView = ({ exercise }: Props) => {
  return (
    <Card>
      <CardContent>
        <Box display="flex" gridGap={4} alignItems="center">
          {!!exercise.data.imageQuestion && (
            <img
              className={styles.img}
              src={exercise.data.imageQuestion}
              alt={exercise.data.imageAttribute || exercise.data.imageTitle || 'image question'}
            />
          )}
          <div>
            <div>{exercise.data.questionUs}</div>
            <Box display="flex" gridGap={4} alignItems="center">
              {exercise.data.optionsUs.map((option) => (
                <div key={option} className={styles.option}>
                  {option}
                </div>
              ))}

              {exercise.data.imageOption1 && <CustomizedAvatar url={exercise.data.imageOption1} />}
              {exercise.data.imageOption2 && <CustomizedAvatar url={exercise.data.imageOption2} />}
              {exercise.data.imageOption3 && <CustomizedAvatar url={exercise.data.imageOption3} />}
              {exercise.data.imageOption4 && <CustomizedAvatar url={exercise.data.imageOption4} />}

              {exercise.data.audioOptionUs1 && <VolumeUp />}
              {exercise.data.audioOptionUs2 && <VolumeUp />}
              {exercise.data.audioOptionUs3 && <VolumeUp />}
              {exercise.data.audioOptionUs4 && <VolumeUp />}
            </Box>
          </div>
        </Box>
      </CardContent>
    </Card>
  )
}
