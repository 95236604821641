import { GetReadyIcon, PracticeIcon, SpeakingChallengeIcon, LearnIcon } from '@astrid/components'
import { SectionTag } from '../store/services/StudySet/types'

export interface SectionTagOption {
  type: SectionTag
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title: string
}

export const sectionTags: SectionTagOption[] = [
  { type: SectionTag.GetReady, Icon: GetReadyIcon, title: 'Get ready' },
  { type: SectionTag.Learn, Icon: LearnIcon, title: 'Learn' },
  { type: SectionTag.Practice, Icon: PracticeIcon, title: 'Practice' },
  { type: SectionTag.SpeakingChallenge, Icon: SpeakingChallengeIcon, title: 'Speaking challenge' }
]
