import { AxiosPromise } from 'axios'
import API from '../../../shared/api/API'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { BookModel } from '../Book/types'
import { ExerciseModel } from './types'

class ExerciseApi {
  static load = ({ limit = 10, page = 0, sort, filter, search }: PaginatedParams = {}): AxiosPromise<
    Paginated<ExerciseModel>
  > =>
    API.get(`/exercise`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $filter: filter && CollectionQueryHelper.filterParamsToQueryString(filter),
        $search: search?.toLocaleLowerCase()
      })
    })

  static loadSingle = (id: string) => API.get(`/exercise/${id}`)

  static create = (data: FormData): AxiosPromise<ExerciseModel> => API.post(`/exercise`, data)

  static loadBookExercises = (bookId: BookModel['_id']): AxiosPromise<ExerciseModel[]> =>
    API.get(`/book/${bookId}/exercises`)

  static createForBook = (data: FormData, bookId: BookModel['_id']): AxiosPromise<ExerciseModel> =>
    API.post(`/book/${bookId}/exercises`, data)

  static updateForBook = (
    data: FormData,
    bookId: BookModel['_id'],
    exerciseId: ExerciseModel['_id']
  ): AxiosPromise<ExerciseModel> => API.put(`/book/${bookId}/exercise/${exerciseId}`, data)

  static update = (data: FormData, id: ExerciseModel['_id']): AxiosPromise<ExerciseModel> =>
    API.put(`/exercise/${id}`, data)

  static delete = (id: ExerciseModel['_id']): AxiosPromise<ExerciseModel> => API.delete(`/exercise/${id}`)

  static deleteForBook = (bookId: BookModel['_id'], exerciseId: ExerciseModel['_id']): AxiosPromise<ExerciseModel> =>
    API.delete(`/book/${bookId}/exercise/${exerciseId}`)

  static translateSpelling = (text: string, translateTo: 'uk' | 'us'): AxiosPromise<string> =>
    API.get(`/translator?text=${text}&translateTo=${translateTo}`)

  static copyExercise = (id: ExerciseModel['_id']): AxiosPromise<ExerciseModel> => API.post(`/exercise/${id}/duplicate`)
}

export default ExerciseApi
