import { CEFRLevels } from '@astrid/components'
import { CONTENT_TAG_AGE_OPTIONS } from '../../store/services/ContentTag/types'
export interface Paginated<T> {
  results: Array<T>
  status: PaginatedStatus
}

export interface PaginatedParams {
  limit?: number
  page?: number
  sort?: ApiSortModel
  search?: string
  filter?: ApiFilterModel
  contentTag?: CONTENT_TAG_AGE_OPTIONS
}

export interface PaginatedStatus {
  total: number
  offset: number
}

export type ApiSortModel = { [key: string]: 1 | -1 }

export type ApiFilterModel = {
  visible?: boolean | null
  cefrLevel?: CEFRLevels[]
  [key: string]: string | string[] | boolean | undefined | null
}

export enum ApiReqState {
  IDLE,
  PENDING,
  RESOLVED,
  REJECTED,
  FINISHED
}
