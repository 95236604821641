import { Avatar, Chip, Typography } from '@material-ui/core'
import React, { ReactNode, SyntheticEvent } from 'react'
import { CEFR_COLORS, WORD_CEFR_LEVELS } from '../types'
import styles from './CEFRBadge.module.scss'

interface CEFRBadgeProps {
  cefrLevel: WORD_CEFR_LEVELS
  children: ReactNode
  'aria-controls'?: string | undefined
  'aria-describedby'?: string | undefined
  'aria-haspopup'?: true | undefined
  onClick?: (event: SyntheticEvent<any>) => void
}
const CEFRBadge = ({ cefrLevel, children, onClick }: CEFRBadgeProps) => {
  return (
    <Chip
      onClick={onClick}
      size="medium"
      clickable
      className={styles.otherWordContainer}
      onDelete={onClick}
      deleteIcon={
        <Avatar style={{ backgroundColor: CEFR_COLORS[cefrLevel], color: 'black' }}>
          <Typography>{cefrLevel === WORD_CEFR_LEVELS.NONE ? 'N' : cefrLevel?.toUpperCase() ?? '-'}</Typography>
        </Avatar>
      }
      label={children}
    />
  )
}
export default CEFRBadge
