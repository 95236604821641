import * as React from 'react'
import { NavLink } from 'react-router-dom'

import { ROUTES } from '../../routes/routes'
import NavButton from '../../shared/components/NavButton/NavButton'
import AuthApi from '../../store/services/Auth/authApi'
import { ReactComponent as AstridLogo } from '../../assets/astrid_logo.svg'
import { selectAuthedUser } from '../../store/services/Auth/authReducer'
import styles from './DashboardNavigation.module.scss'
import { useSelector } from 'react-redux'

const logout = () => {
  AuthApi.removeUserToken()
  window.location.reload()
}

const DashboardNavigation: React.FC = () => {
  const user = useSelector(selectAuthedUser)
  const privileges = user.privileges
  const shouldShowContentSection =
    privileges.manageBooks || privileges.manageStudySets || privileges.manageMiniCourses || privileges.manageShelves

  return (
    <div className={styles.container}>
      <NavLink to={ROUTES.ROOT} className={styles.logo}>
        <AstridLogo />
        ADMIN
      </NavLink>
      <nav className={styles.nav}>
        <ul className={styles.menu}>
          {!shouldShowContentSection ? null : (
            <li>
              <div className={styles.group}>Content</div>
              <ul className={styles.submenu}>
                {!privileges.manageBooks ? null : (
                  <li>
                    <NavButton
                      exact
                      to={ROUTES.ROOT}
                      isActive={(props) => !!props || window.location.pathname.includes(ROUTES.BOOK)}>
                      Books
                    </NavButton>
                  </li>
                )}
                {!privileges.manageStandaloneExercises ? null : (
                  <li>
                    <NavButton to={ROUTES.EXERCISE}>Exercises</NavButton>
                  </li>
                )}

                {!privileges.manageStudySets ? null : (
                  <li>
                    <NavButton to={ROUTES.STUDY_SETS}>Study Sets</NavButton>
                  </li>
                )}
                {!privileges.manageMiniCourses ? null : (
                  <li>
                    <NavButton to={ROUTES.MINI_COURSE}>Mini Courses</NavButton>
                  </li>
                )}
                {!privileges.manageShelves ? null : (
                  <li>
                    <NavButton to={ROUTES.SHELF}>Shelves</NavButton>
                  </li>
                )}
              </ul>
            </li>
          )}
          {!privileges.manageSchoolUsers ? null : (
            <li>
              <div className={styles.group}>Users</div>
              <ul className={styles.submenu}>
                <li>
                  <NavButton to={ROUTES.TUTORS}>Tutors</NavButton>
                </li>
                <li>
                  <NavButton to={ROUTES.STUDENTS}>Students</NavButton>
                </li>
                <li>
                  <NavButton to={ROUTES.TEACHERS}>Teachers</NavButton>
                </li>
                <li>
                  <NavButton to={ROUTES.SCHOOLS}>Schools</NavButton>
                </li>
              </ul>
            </li>
          )}
          {!privileges.manageSettings ? null : (
            <li>
              <NavButton to={ROUTES.CERF_TOOL}>CEFR Tool</NavButton>
            </li>
          )}
          {!privileges.manageSettings ? null : (
            <li>
              <NavButton to={ROUTES.SETTINGS}>Admin settings</NavButton>
            </li>
          )}
          <li>
            <NavButton to={'#'} withoutActiveStatus onClick={logout}>
              Log out
            </NavButton>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default DashboardNavigation
