import * as React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import initStore from './store/store'
import RouteStack from './routes/RouteStack'
import './App.css'
import './shared/constants/variables.module.scss'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import theme from './shared/constants/MuiTheme'
import { AlertProvider } from './shared/components/AlertContext/AlertContext'

function App() {
  const store = initStore()
  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <StylesProvider injectFirst>
          <CssBaseline />
          <ReduxProvider store={store}>
            <RouteStack />
          </ReduxProvider>
        </StylesProvider>
      </AlertProvider>
    </ThemeProvider>
  )
}

export default App
