import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router'

import useSWR from 'swr'
import { ROUTES } from '../../routes/routes'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import ContentTagApi from '../../store/services/ContentTag/contentTagApi'
import { ContentType } from '../../store/services/ContentTag/types'
import MiniCourseApi from '../../store/services/MiniCourse/miniCourse.api'
import { MiniCourseContentEntry } from '../../store/services/MiniCourse/miniCourse.types'
import TopicsApi from '../../store/services/Topics/topicsApi'
import MiniCourseContent from '../MiniCourseContent/MiniCourseContent'
import MiniCourseForm from '../MiniCourseForm/MiniCourseForm'

interface MiniCourseParams {
  miniCourseId: string
}

export const MINI_COURSE_NEW_ID = 'new'

const MiniCourseSingle = () => {
  const { showAlert } = useContext(AlertContext)
  const history = useHistory()
  const { miniCourseId: id } = useParams<MiniCourseParams>()
  const shouldLoad = id && id !== MINI_COURSE_NEW_ID
  const { data: miniCourse, mutate } = useSWR(shouldLoad ? `load-single/${id}` : null, () =>
    MiniCourseApi.loadSingle(id)
  )
  const { data: industries } = useSWR('all-industries', () => ContentTagApi.loadAllIndustries())
  const { data: industryTags } = useSWR(shouldLoad ? `industries/${id}` : null, () =>
    ContentTagApi.loadIndustries({ contentId: id, contentType: ContentType.MiniCourse })
  )
  const { data: topics } = useSWR('all-topics', () => TopicsApi.getAll().then((res) => res.data))
  const { data: topicTags } = useSWR(shouldLoad ? `topics/${id}` : null, () =>
    TopicsApi.load({ contentId: id, contentType: ContentType.MiniCourse }).then((res) => res.data)
  )

  const handleCreate = async ({
    formData,
    industryTags,
    topicTags
  }: {
    formData: FormData
    industryTags: string[]
    topicTags: string[]
  }) => {
    try {
      const result = await MiniCourseApi.create(formData)
      await ContentTagApi.updateIndustries({
        contentType: ContentType.MiniCourse,
        contentId: result._id,
        tags: industryTags
      })
      await TopicsApi.update({ contentType: ContentType.MiniCourse, contentId: result._id, topics: topicTags })
      history.push(`${ROUTES.MINI_COURSE}/${result._id}`)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleUpdate = async ({
    formData,
    industryTags,
    topicTags
  }: {
    formData: FormData
    industryTags: string[]
    topicTags: string[]
  }) => {
    try {
      const data = await MiniCourseApi.update(id, formData)
      await ContentTagApi.updateIndustries({ contentType: ContentType.MiniCourse, contentId: id, tags: industryTags })
      await TopicsApi.update({ contentType: ContentType.MiniCourse, contentId: id, topics: topicTags })
      await mutate(data)
      showAlert('Mini course updated succesfully', 'success')
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handlePublish = async (active: boolean) => {
    try {
      const data = await (active ? MiniCourseApi.publish(id) : MiniCourseApi.unpublish(id))
      await mutate(data)
      showAlert(active ? 'Mini course published' : 'Mini course unpublished', 'success')
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleDelete = async () => {
    try {
      await MiniCourseApi.delete(id)
      history.push(ROUTES.MINI_COURSE)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleUpdateContent = async (content: MiniCourseContentEntry[]) => {
    try {
      const data = await MiniCourseApi.updateContent(id, content)
      mutate(data)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const contentLocked = !miniCourse || miniCourse.active

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MiniCourseForm
          miniCourse={miniCourse}
          industryTags={industryTags ?? []}
          industries={industries ?? []}
          topics={topics ?? []}
          topicTags={topicTags ?? []}
          disabled={miniCourse?.active}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          onPublish={() => handlePublish(true)}
          onUnpublish={() => handlePublish(false)}
        />
      </Grid>
      <Grid item xs={6}>
        <MiniCourseContent
          content={miniCourse?.contents ?? []}
          disabled={contentLocked}
          onUpdate={handleUpdateContent}
        />
      </Grid>
    </Grid>
  )
}

export default MiniCourseSingle
