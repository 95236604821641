import { Button, FormControl, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import { endpoints } from '../endpoints'
import { AUTHORS, WORD_CEFR_LEVELS, WORD_FORM_CEFR_LEVELS } from '../types'
import styles from './WordForm.module.scss'

const validationSchema = Yup.object().shape({
  word: Yup.string().required('"Word" is missing'),
  cefr: Yup.mixed<WORD_CEFR_LEVELS>().oneOf(Object.values(WORD_CEFR_LEVELS)).required('"CEFR" is missing'),
  sense: Yup.string().required('"Word" is missing'),
  source: Yup.mixed<AUTHORS>().oneOf(Object.values(AUTHORS)).required('"Source" is missing'),
  note: Yup.string().required('"Note" is missing')
})
const WordForm = () => {
  const { showAlert } = useContext(AlertContext)
  const { values, setFieldValue, errors, submitForm, touched, handleChange } = useFormik<{
    word: string
    cefr: WORD_CEFR_LEVELS
    sense: string
    source: AUTHORS
    note: string
  }>({
    initialValues: { word: '', cefr: WORD_CEFR_LEVELS.A1, sense: '', source: AUTHORS.Dani, note: '' },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axios
        .post(`${endpoints.insertWord}`, {
          word: values.word,
          cefr: values.cefr,
          source: values.source,
          note: values.note,
          sense: values.sense
        })
        .then(() => {
          showAlert(`${values.word} inserted succesfully.`, 'success')
        })
        .catch(() => {
          showAlert(`${values.word} inserted unsuccesfully.`, 'error')
        })
    }
  })

  const handleCefrChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setFieldValue('cefr', e.target.value, true)
  }
  const handleSourceChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setFieldValue('source', e.target.value, true)
  }
  return (
    <Paper className={styles.container}>
      <Typography className={styles.headerText} component="h1">
        Add a word to the database
      </Typography>
      <div className={styles.wordFormContainer}>
        <div className={styles.rowContainer}>
          <TextField
            className={styles.textField}
            fullWidth
            id="word"
            name="word"
            label="Word"
            value={values.word}
            onChange={handleChange}
            error={touched.word && Boolean(errors.word)}
            helperText={touched.word && errors.word}
            variant="outlined"
          />
          <TextField
            className={styles.textField}
            fullWidth
            id="sense"
            name="sense"
            label="Sense"
            type="sense"
            value={values.sense}
            onChange={handleChange}
            error={touched.sense && Boolean(errors.sense)}
            helperText={touched.sense && errors.sense}
            variant="outlined"
          />
        </div>
        <div className={styles.rowContainer}>
          <FormControl className={styles.selectControl}>
            <TextField
              variant="outlined"
              id="source"
              value={values.source}
              required
              onChange={handleSourceChange}
              label="Source"
              select>
              {Object.values(AUTHORS).map((pos) => (
                <MenuItem key={pos} value={pos}>
                  {pos.toString().toUpperCase()}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl className={styles.selectControl}>
            <TextField variant="outlined" id="cefr" value={values.cefr} onChange={handleCefrChange} label="CEFR" select>
              {Object.values(WORD_FORM_CEFR_LEVELS).map((cefrLevel) => (
                <MenuItem key={cefrLevel} value={cefrLevel}>
                  {cefrLevel?.toUpperCase()}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        <TextField
          className={styles.noteField}
          fullWidth
          id="note"
          name="note"
          label="Note"
          type="note"
          value={values.note}
          onChange={handleChange}
          error={touched.note && Boolean(errors.note)}
          helperText={touched.note && errors.note}
          variant="outlined"
          multiline
          rows={4}
        />
      </div>
      <Button onClick={() => submitForm()} color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </Paper>
  )
}

export default WordForm
