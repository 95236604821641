import { Button, Radio, TextField, Typography } from '@material-ui/core'
import { FormikErrors, FormikTouched } from 'formik'
import React, { useContext } from 'react'
import { AlertContext } from '../../../shared/components/AlertContext/AlertContext'
import ImageField from '../../../shared/components/ImageField/ImageField'
import { COMPREHENSION_IMAGE_HEIGHT, COMPREHENSION_IMAGE_WIDTH } from '../../../shared/constants/imageSizes'
import { ComprehensionFormFields, ComprehensionImages } from '../types'
import styles from '../Comprehension.module.scss'

interface ImageFormProps {
  handleTranslationClick: (text: string, translateTo: 'uk' | 'us') => Promise<string | undefined>
  handleChange: Function
  setFieldTouched: Function
  setFieldValue: Function
  values: ComprehensionFormFields
  touched: FormikTouched<ComprehensionFormFields>
  formDisabled: boolean
  errors: FormikErrors<ComprehensionFormFields>
  isVideoOverlay?: boolean
}

export const ImageQuestionForm: React.FC<ImageFormProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  formDisabled,
  handleTranslationClick
}) => {
  const { showAlert } = useContext(AlertContext)

  return (
    <>
      <Typography variant="h6">UK</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="question"
        label="Question"
        placeholder="Enter the question"
        name="question"
        size="small"
        onChange={handleChange('question')}
        value={values.question}
        error={touched.question && !!errors.question}
        helperText={(touched.question && errors.question) || ''}
        onBlur={() => setFieldTouched('question')}
        disabled={formDisabled}
      />

      <Button
        onClick={async () => {
          setFieldValue('questionUs', await handleTranslationClick(values.question || '', 'us'))
        }}
        variant="outlined"
        color="primary"
        className={styles.translateButton}>
        Translate to US
      </Button>

      <Typography variant="h6">US</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="questionUs"
        label="Question"
        placeholder="Enter the question"
        name="questionUs"
        size="small"
        onChange={handleChange('questionUs')}
        value={values.questionUs}
        error={touched.questionUs && !!errors.questionUs}
        helperText={(touched.questionUs && errors.questionUs) || ''}
        onBlur={() => setFieldTouched('questionUs')}
        disabled={formDisabled}
      />

      <Button
        onClick={async () => {
          setFieldValue('question', await handleTranslationClick(values.question || '', 'uk'))
        }}
        variant="outlined"
        color="primary"
        className={styles.translateButton}>
        Translate to UK
      </Button>

      <ImageField
        showAlert={showAlert}
        name="imageQuestion"
        style={{ width: COMPREHENSION_IMAGE_WIDTH, height: COMPREHENSION_IMAGE_HEIGHT }}
        label="Upload question image"
        value={values.imageQuestion.url}
        onChange={handleChange('imageQuestion')}
        onRemove={() => setFieldValue('imageQuestion', { url: '', blob: undefined })}
        error={touched.imageQuestion && !!errors.imageQuestion}
        helperText={touched.imageQuestion && !!errors.imageQuestion ? (errors.imageQuestion.blob as string) : ''}
        disabled={formDisabled}
        width={COMPREHENSION_IMAGE_WIDTH}
        height={null}
        onBlur={() => setFieldTouched('imageQuestion')}
        aspectRatio={COMPREHENSION_IMAGE_WIDTH / COMPREHENSION_IMAGE_HEIGHT}
        cropperProps={{ locked: false }}
      />
    </>
  )
}

export const ImageAnswerForm: React.FC<ImageFormProps> = ({
  values,
  setFieldTouched,
  setFieldValue,
  formDisabled,
  handleChange,
  isVideoOverlay
}) => {
  const { showAlert } = useContext(AlertContext)

  const options = isVideoOverlay
    ? [values.imageOption1, values.imageOption2]
    : [values.imageOption1, values.imageOption2, values.imageOption3, values.imageOption4]
  return (
    <>
      {options.map((option, index) => (
        <div key={`${option}${index}`} className={styles.imageOption}>
          <Radio
            checked={values.answer === index}
            onChange={() => setFieldValue('answer', index)}
            value={index}
            name="option-answer"
            color="primary"
            style={{ marginRight: 8 }}
            inputProps={{ 'data-testid': `option-${index}-answer` } as React.InputHTMLAttributes<HTMLInputElement>}
            disabled={formDisabled || option === undefined || option === null}
          />
          <div>
            <ImageField
              showAlert={showAlert}
              name={`imageOption${index + 1}`}
              style={{ width: COMPREHENSION_IMAGE_WIDTH, height: COMPREHENSION_IMAGE_HEIGHT }}
              label="Upload answer image"
              value={values[`imageOption${index + 1}` as keyof ComprehensionImages].url}
              onChange={handleChange(`imageOption${index + 1}`)}
              onRemove={() => setFieldValue(`imageOption${index + 1}`, { url: '', blob: undefined })}
              disabled={formDisabled}
              width={COMPREHENSION_IMAGE_WIDTH}
              height={null}
              onBlur={() => setFieldTouched(`imageOption${index + 1}`)}
              aspectRatio={COMPREHENSION_IMAGE_WIDTH / COMPREHENSION_IMAGE_HEIGHT}
              cropperProps={{ locked: false }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              minRows={3}
              id={`imageOption${index + 1}Attribute`}
              label={`Image Option ${index + 1} Attribution`}
              name={`imageOption${index + 1}Attribute`}
              size="small"
              onChange={handleChange(`imageOption${index + 1}Attribute`)}
              value={values[`imageOption${index + 1}Attribute` as keyof ComprehensionFormFields]}
              helperText="How to add a link: [Hyperlink text](http://www.example.com)"
              disabled={formDisabled}
            />
          </div>
        </div>
      ))}
    </>
  )
}
