import { ApiReqState } from '../../../shared/api/types'
import { ContentType } from '../ContentTag/types'

export interface DomainsState {
  data?: DomainsModel
  reqState: ApiReqState
}

export enum DOMAINS_ACTIONS {
  REQ_STATE = 'DOMAINS__REQ_STATE',
  SET_DATA = 'DOMAINS__SET_DATA'
}

export interface DomainsModel {
  contentType: ContentType
  contentId: string
  domains: string[]
}

export interface DomainsReqState {
  type: DOMAINS_ACTIONS.REQ_STATE
  reqState: ApiReqState
}

export interface DomainsSetData {
  type: DOMAINS_ACTIONS.SET_DATA
  data: DomainsModel
}

export type DomainsActions = DomainsReqState | DomainsSetData
