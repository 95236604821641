import { Button, Card, CardContent, Grid, MenuItem, SvgIcon, TextField, Typography } from '@material-ui/core'
import { DragIndicator } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ConfirmPopup } from '../../../shared/components/ConfirmPopup/ConfirmPopup'
import DraggableCard from '../../../shared/components/DraggableCard/DraggableCard'
import {
  SectionTag,
  StudySetComponentModel,
  StudySetComponentRequest,
  StudySetComponentType,
  StudySetSection
} from '../../../store/services/StudySet/types'
import { Animation } from '../../content/Animation/Animation'
import { AnimationForm } from '../../content/Animation/AnimationForm'
import { Book } from '../../content/Book/Book'
import { BookForm } from '../../content/Book/BookForm'
import { Exercise } from '../../content/Exercise/Exercise'
import { ExerciseForm } from '../../content/Exercise/ExerciseForm'
import { Video } from '../../content/Video/Video'
import { VideoForm } from '../../content/Video/VideoForm'
import { SectionTagOption } from '../../sectionTags'
import { studySetComponentOptions } from '../../studySetComponentOptions'
import { ComponentMode, StudySetComponentOption } from '../../types'
import { ComponentSelector } from '../ComponentSelector/ComponentSelector'
import { ComponentTitle } from '../ComponentTitle/ComponentTitle'
import styles from './Section.module.scss'

interface Draggable {
  id: string
  component: StudySetComponentModel
}

interface Props {
  section: StudySetSection
  componentMode: ComponentMode
  availableSectionTagOptions: SectionTagOption[]
  onSaveSection: (sectionTag: SectionTag) => void
  onDeleteSection: () => void
  onMoveComponent: (startIndex: number, endIndex: number) => void
  onSaveComponent: (data: StudySetComponentModel | StudySetComponentRequest, componentIndex?: number) => Promise<void>
  onCancelComponent: () => void
  onDeleteComponent: (componentIndex: number) => void
  onAddComponent: (option: StudySetComponentOption) => void
  onEditComponent: (componentIndex: number) => void
}

export const Section = ({
  section,
  componentMode,
  availableSectionTagOptions,
  onSaveSection,
  onDeleteSection,
  onMoveComponent,
  onCancelComponent,
  onSaveComponent,
  onDeleteComponent,
  onAddComponent,
  onEditComponent
}: Props) => {
  const [draggables, setDraggables] = useState<Draggable[]>([])
  const [hoveredDraggableIndex, setHoveredDraggableIndex] = useState<number | null>(null)

  useEffect(() => {
    setDraggables(
      section.components.map((component, index) => ({
        id: `${section.sectionName}--${component.type}--${index}`,
        component
      }))
    )
  }, [section])

  const swapDraggables = (indexA: number, indexB: number) => {
    if (draggables) {
      const reordered = [...draggables]

      const temp = draggables[indexA]
      reordered[indexA] = reordered[indexB]
      reordered[indexB] = temp

      setDraggables(reordered)
    }
  }

  const isEditingComponent = (index: number) =>
    componentMode.type === 'editing' && componentMode.componentIndex === index

  const componentFormProps = <T extends StudySetComponentOption | StudySetComponentModel>(
    data: T,
    componentIndex?: number
  ) => ({
    data,
    onSave: (requestData: StudySetComponentModel | StudySetComponentRequest) =>
      onSaveComponent(requestData, componentIndex),
    onCancel: onCancelComponent
  })

  const canDrag = componentMode.type === 'editable' && draggables.length > 1
  const disableSectionTagSelector = componentMode.type !== 'editable' || availableSectionTagOptions.length < 2

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.sectionHeader}>
                <TextField
                  className={styles.sectionSelector}
                  select
                  SelectProps={{ IconComponent: disableSectionTagSelector ? () => null : undefined }} // hide dropdown arrow if disabled
                  disabled={disableSectionTagSelector}
                  label="Section tag"
                  variant="outlined"
                  size="small"
                  value={section.sectionName}
                  onChange={(event) => onSaveSection(event.target.value as SectionTag)}
                  name="selectedOption">
                  {availableSectionTagOptions.map(({ type, title, Icon }) => (
                    <MenuItem key={type} value={type}>
                      <div className={styles.sectionSelectItem}>
                        <SvgIcon>
                          <Icon />
                        </SvgIcon>
                        {title}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
                {componentMode.type === 'editable' && (
                  <ConfirmPopup
                    onConfirm={onDeleteSection}
                    inactive={section.components.length === 0}
                    text="All components in the section will be deleted. Are you sure?"
                    cancelText="No"
                    confirmText="Yes, delete the section">
                    <Button color="primary" variant="outlined">
                      Delete section
                    </Button>
                  </ConfirmPopup>
                )}
              </div>
            </Grid>

            <DndProvider backend={HTML5Backend}>
              {draggables.map((draggable, index) => (
                <Grid key={draggable.id} item xs={12}>
                  <div className={`${hoveredDraggableIndex === index ? styles.dropLocation : ''}`}>
                    <DraggableCard
                      id={draggable.id}
                      index={index}
                      moveCard={(dragIndex, hoverIndex) => {
                        setHoveredDraggableIndex(hoverIndex)
                        swapDraggables(dragIndex, hoverIndex)
                      }}
                      dropCard={(startIndex, endIndex) => {
                        setHoveredDraggableIndex(null)
                        onMoveComponent(startIndex, endIndex)
                      }}
                      canDrag={canDrag}>
                      <Card className={styles.card}>
                        <div className={styles.draggableContainer}>
                          <DragIndicator className={`${styles.dragIndicator} ${canDrag ? '' : styles.hidden}`} />
                          <CardContent className={styles.content}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant="h3" className={styles.componentHeading}>
                                  <ComponentTitle componentInfo={draggable.component} />
                                </Typography>
                              </Grid>
                              {isEditingComponent(index) ? (
                                <Grid item xs={12}>
                                  {draggable.component.type === StudySetComponentType.Book && (
                                    <BookForm {...componentFormProps(draggable.component, index)} />
                                  )}
                                  {draggable.component.type === StudySetComponentType.Exercise && (
                                    <ExerciseForm {...componentFormProps(draggable.component, index)} />
                                  )}
                                  {draggable.component.type === StudySetComponentType.Video && (
                                    <VideoForm {...componentFormProps(draggable.component, index)} />
                                  )}
                                  {draggable.component.type === StudySetComponentType.Animation && (
                                    <AnimationForm {...componentFormProps(draggable.component, index)} />
                                  )}
                                </Grid>
                              ) : (
                                <>
                                  <Grid item xs={10}>
                                    {draggable.component.type === StudySetComponentType.Book && (
                                      <Book data={draggable.component} />
                                    )}
                                    {draggable.component.type === StudySetComponentType.Exercise && (
                                      <Exercise exercise={draggable.component.exercise} />
                                    )}
                                    {draggable.component.type === StudySetComponentType.Video && (
                                      <Video data={draggable.component} />
                                    )}
                                    {draggable.component.type === StudySetComponentType.Animation && (
                                      <Animation data={draggable.component} />
                                    )}
                                  </Grid>
                                  <Grid item xs={2}>
                                    {componentMode.type === 'editable' && (
                                      <div className={styles.buttons}>
                                        <ConfirmPopup onConfirm={() => onDeleteComponent(index)}>
                                          <Button color="primary" variant="outlined">
                                            Delete
                                          </Button>
                                        </ConfirmPopup>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => onEditComponent(index)}>
                                          Edit
                                        </Button>
                                      </div>
                                    )}
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </CardContent>
                        </div>
                      </Card>
                    </DraggableCard>
                  </div>
                </Grid>
              ))}
            </DndProvider>

            <Grid item xs={12}>
              {componentMode.type === 'adding' &&
                componentMode.option.type !== StudySetComponentType.SpeakingChallenge && (
                  <Card className={styles.placeholder}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3" className={styles.componentHeading}>
                            <ComponentTitle componentInfo={componentMode.option} />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {componentMode.option.type === StudySetComponentType.Book && (
                            <BookForm {...componentFormProps(componentMode.option)} />
                          )}
                          {componentMode.option.type === StudySetComponentType.Exercise && (
                            <ExerciseForm {...componentFormProps(componentMode.option)} />
                          )}
                          {componentMode.option.type === StudySetComponentType.Video && (
                            <VideoForm {...componentFormProps(componentMode.option)} />
                          )}
                          {componentMode.option.type === StudySetComponentType.Animation && (
                            <AnimationForm {...componentFormProps(componentMode.option)} />
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )}
              {componentMode.type === 'editable' && (
                <div className={styles.selector}>
                  <ComponentSelector
                    buttonTitle="Add to section"
                    options={studySetComponentOptions.filter(
                      (option) => option.type !== StudySetComponentType.SpeakingChallenge
                    )}
                    onAdd={(option) => onAddComponent(option)}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
