import { createTheme } from '@material-ui/core/styles'

const colors = {
  primary: '#000000',
  secondary: '#9E9E9E',

  border: '#EEEEEE',

  textPrimary: '#000000',
  textSecondary: '#9E9E9E'
}

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif'
  },
  palette: {
    primary: {
      light: '#333333',
      main: colors.primary,
      dark: '#000000',
      contrastText: '#fff'
    },
    secondary: {
      light: '#EEEEEE',
      main: colors.secondary,
      dark: '#23253c',
      contrastText: '#fff'
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary
    }
  },
  shape: {
    borderRadius: 4
  },
  overrides: {
    MuiTypography: {
      h5: {
        fontWeight: 500
      }
    },
    MuiButton: {
      root: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      },
      containedPrimary: {
        '&:disabled': {
          background: 'rgba(0, 0, 0, 0.5)',
          color: '#fff'
        }
      }
    },
    MuiPaper: {
      elevation1: {
        border: `1px solid ${colors.border}`,
        boxShadow: 'none'
      }
    },
    MuiTableContainer: {
      root: {
        boxShadow: 'none',
        border: `1px solid ${colors.border}`,
        position: 'relative'
      }
    },
    MuiTableCell: {
      root: {
        borderBottomColor: colors.border
      },
      head: {
        textTransform: 'uppercase',
        color: colors.textSecondary
      },
      body: {
        fontWeight: 500
      }
    },
    MuiTableSortLabel: {
      root: {
        '&.MuiTableSortLabel-active': {
          color: `${colors.textSecondary}`
        },
        '&.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
          color: `${colors.primary}!important`
        }
      }
    }
  }
})

export default theme
