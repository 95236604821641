import API from '../../../shared/api/API'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import { CreateShelfBody, ShelfApiDoc, ShelfApiDocForList, ShelfContent, UpdateShelfBody } from './shelf.types'

type ShelfLoadResponse = Paginated<ShelfApiDocForList>

class ShelfApi {
  static load = (
    { limit = 10, page = 0, sort, filter, search }: PaginatedParams = {},
    signal?: AbortSignal
  ): Promise<ShelfLoadResponse> =>
    API.get(`/shelf`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $filter: filter && CollectionQueryHelper.filterParamsToQueryString(filter),
        $search: search?.toLocaleLowerCase()
      }),
      signal
    }).then((res) => res.data)

  static loadSingle = (id: ShelfApiDoc['_id']): Promise<ShelfApiDoc> => API.get(`/shelf/${id}`).then((res) => res.data)

  static create = (data: CreateShelfBody): Promise<ShelfApiDoc> => API.post('/shelf', data).then((res) => res.data)

  static delete = (id: ShelfApiDoc['_id']) => API.delete(`/shelf/${id}`)

  static publish = (id: ShelfApiDoc['_id']): Promise<ShelfApiDoc> =>
    API.put(`/shelf/${id}/publish`, { active: true }).then((res) => res.data)

  static unpublish = (id: ShelfApiDoc['_id']): Promise<ShelfApiDoc> =>
    API.put(`/shelf/${id}/publish`, { active: false }).then((res) => res.data)

  static update = (id: ShelfApiDoc['_id'], data: UpdateShelfBody): Promise<ShelfApiDoc> =>
    API.patch(`/shelf/${id}`, data).then((res) => res.data)

  static setContents = (id: ShelfApiDoc['_id'], contents: ShelfContent[]): Promise<ShelfApiDoc> =>
    API.post(`/shelf/${id}/contents`, { contents }).then((res) => res.data)
}

export default ShelfApi
