import * as React from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import DashboardToolbar from '../Dashboard/DashboardToolbar/DashboardToolbar'
import { RouteComponentProps } from 'react-router-dom'
import SearchField from '../shared/components/SearchField/SearchField'
import { connect, ConnectedProps } from 'react-redux'
import styles from './StudentsTable.module.scss'
import { ApiReqState, ApiSortModel } from '../shared/api/types'
import { RootState } from '../store/types'
import { ArrowDropDown } from '@material-ui/icons'
import CollectionQueryHelper from '../shared/helpers/CollectionQueryHelper'
import TableOverlay from '../shared/components/TableOverlay/TableOverlay'
import { StudentModel } from '../store/services/Student/types'
import {
  selectStudents,
  selectStudentsStatus,
  selectStudentsReqState,
  getStudentCollection
} from '../store/services/Student/studentReducer'

interface State {
  sortParams: ApiSortModel
  searchQuery: string
}

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {}

export const STUDENTS_PER_PAGE = 10

class StudentsTable extends React.Component<Props, State> {
  state: State = {
    sortParams: {},
    searchQuery: ''
  }

  componentDidMount(): void {
    this.loadStudents()
  }

  loadStudents = async (page: number = 0) => {
    try {
      const { searchQuery, sortParams } = this.state
      await this.props.getStudentCollection({ limit: STUDENTS_PER_PAGE, page, sort: sortParams, search: searchQuery })
    } catch (e) {}
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.loadStudents(newPage)
  }

  handleSortRequest = (key: keyof StudentModel) => (e: React.MouseEvent<unknown>) => {
    this.setState(
      (state) => ({ sortParams: CollectionQueryHelper.setSortValue(key, state.sortParams) }),
      () => {
        this.loadStudents(0)
      }
    )
  }

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value }, () => {
      this.loadStudents(0)
    })
  }

  render() {
    const { students, studentsResStatus, studentReqState } = this.props
    const { sortParams, searchQuery } = this.state
    const emptyRows = STUDENTS_PER_PAGE - students.length

    const isLoading = studentReqState === ApiReqState.IDLE || studentReqState === ApiReqState.PENDING

    return (
      <>
        <DashboardToolbar>
          <Typography component="h1" variant="h6">
            Students
          </Typography>
          <SearchField
            containerClassName={styles.searchField}
            value={searchQuery}
            onChange={this.handleSearchQuery}
            onClear={() => this.handleSearchQuery({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
          />
        </DashboardToolbar>
        <TableContainer component={Paper}>
          {!isLoading && students.length === 0 && (
            <TableOverlay>
              <Typography>Students not found.</Typography>
            </TableOverlay>
          )}
          {isLoading && (
            <TableOverlay data-testid="StudentsTableLoader">
              <CircularProgress size={40} />
            </TableOverlay>
          )}
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 420 }}>
                  <TableSortLabel
                    data-testid={'SortByNameStudentLabel'}
                    active={CollectionQueryHelper.isSortActive('firstName', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('firstName', sortParams)}
                    onClick={this.handleSortRequest('firstName')}
                    IconComponent={ArrowDropDown}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('email', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('email', sortParams)}
                    onClick={this.handleSortRequest('email')}
                    IconComponent={ArrowDropDown}>
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('school', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('school', sortParams)}
                    onClick={this.handleSortRequest('school')}
                    IconComponent={ArrowDropDown}>
                    School
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('class', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('class', sortParams)}
                    onClick={this.handleSortRequest('class')}
                    IconComponent={ArrowDropDown}>
                    Class
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('teacher', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('teacher', sortParams)}
                    onClick={this.handleSortRequest('teacher')}
                    IconComponent={ArrowDropDown}>
                    Teacher
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student) => (
                <TableRow
                  hover
                  key={student._id}
                  // onClick={() => history.push(`${ROUTES.STUDENT}/${student._id}`)}
                  data-testid={'StudentListRow'}>
                  <TableCell component="th" scope="row">
                    {student.firstName} {student.lastName}
                  </TableCell>

                  <TableCell>{student.email}</TableCell>
                  <TableCell>{student.school}</TableCell>
                  <TableCell>{student.class}</TableCell>
                  <TableCell>{student.teacher}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 59 * emptyRows }} data-testid={'StudentListEmptyRow'}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {studentsResStatus ? (
            <TablePagination
              component="div"
              colSpan={3}
              count={studentsResStatus.total}
              rowsPerPage={STUDENTS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={studentsResStatus.offset / STUDENTS_PER_PAGE}
              onPageChange={this.handleChangePage}
            />
          ) : (
            <span>Not loaded yet</span>
          )}
        </TableContainer>
      </>
    )
  }
}

const connector = connect(
  (state: RootState) => ({
    students: selectStudents(state),
    studentsResStatus: selectStudentsStatus(state),
    studentReqState: selectStudentsReqState(state)
  }),
  { getStudentCollection }
)

export default connector(StudentsTable)
