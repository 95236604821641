const messages: { [key in string]: string } = {
  ADMIN_WRONG_CREDENTIALS: 'You have entered an invalid email or password.',
  DEFAULT: 'Something went wrong, try again later.',
  TUTOR_EXISTS: 'A tutor with the provided email already exists.'
}

export const printApiMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return messages[error.message] || error.message || messages.DEFAULT
  }

  if (typeof error === 'string') {
    return messages[error] || error || messages.DEFAULT
  }

  return messages.DEFAULT
}
