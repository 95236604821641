import { BookType } from '@astrid/components'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import * as React from 'react'
import { useCallback, useContext } from 'react'
import * as Yup from 'yup'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import ImageField, { CroppedResult } from '../../shared/components/ImageField/ImageField'
import RecordingField from '../../shared/components/RecordingField/RecordingField'
import { READING_PAGE_IMAGE_HEIGHT, READING_PAGE_IMAGE_WIDTH } from '../../shared/constants/imageSizes'
import { DEFAULT_EXERCISE_POINTS, DEFAULT_VIDEO_OVERLAY_EXERCISE_POINTS } from '../../shared/constants/points'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import ExerciseApi from '../../store/services/Exercise/exerciseApi'
import { ExerciseFormFields, ExerciseReadSentenceModel, ExerciseType } from '../../store/services/Exercise/types'
import { ExerciseFormFactory } from '../ExerciseFormFactory/ExerciseFormFactory'
import { ExerciseFormProps } from '../types'
import styles from './ReadingPage.module.scss'

const validationSchema = Yup.object().shape({
  points: ValidationRules.points,
  sentence: Yup.string().max(400, 'Maximum 400 characters'),
  sentenceUs: ValidationRules.sentence,
  imageTitle: ValidationRules.optionalWithMaxLength,
  imageAttribute: ValidationRules.optionalWithMaxLength
})

interface Props extends ExerciseFormProps {
  data?: ExerciseReadSentenceModel
  bookType?: BookType
  isVideoOverlay?: boolean
  quickEdit?: boolean
}

type FormFields = ExerciseFormFields<ExerciseReadSentenceModel> & {
  image: CroppedResult | { url: string; blob?: null }
  recordingUk: File | string | null | undefined
  recordingUs: File | string | null | undefined
  imageTitle: string
  imageAttribute: string
}

const ReadingPage = ({ data, bookType, isVideoOverlay, formDisabled, quickEdit, ...exerciseFormProps }: Props) => {
  const { showAlert } = useContext(AlertContext)
  const isNew = !data
  const defaultPoints = isVideoOverlay ? DEFAULT_VIDEO_OVERLAY_EXERCISE_POINTS : DEFAULT_EXERCISE_POINTS
  const handleSubmit = useCallback(
    ({
      image,
      points,
      sentence,
      sentenceUs,
      type,
      recordingUk,
      recordingUs,
      imageTitle,
      imageAttribute
    }: FormFields) => {
      const preparedData: any = {
        type,
        points,
        data: JSON.stringify({ sentence, sentenceUs, imageTitle, imageAttribute })
      }

      if (image?.blob instanceof Blob) {
        preparedData.image = image.blob
      } else if (!image.url) {
        preparedData.image = null
      }

      if (recordingUk instanceof File || recordingUk === null) {
        preparedData.recordingUk = recordingUk
      }

      if (recordingUs instanceof File || recordingUs === null) {
        preparedData.recordingUs = recordingUs
      }

      return preparedData
    },
    // eslint-disable-next-line
    [isNew]
  )

  const handleTranslationClick = useCallback(async (text: string, translateTo: 'uk' | 'us') => {
    try {
      const translation = await ExerciseApi.translateSpelling(text, translateTo)
      return translation.data
    } catch (e) {}
  }, [])

  return (
    <ExerciseFormFactory
      {...exerciseFormProps}
      formDisabled={formDisabled}
      exerciseId={data?._id}
      isCopyable={!isVideoOverlay}
      hideFooter={quickEdit}
      initialValues={
        isNew
          ? {
              image: {
                url: '',
                blob: undefined
              },
              recordingUk: null,
              recordingUs: null,
              points: defaultPoints,
              sentenceUs: '',
              type: ExerciseType.ReadSentence,
              imageTitle: '',
              imageAttribute: ''
            }
          : {
              image: { url: data?.imageUrl || '', blob: null },
              points: data?.points || defaultPoints,
              sentence: data?.data.sentence || '',
              sentenceUs: data?.data.sentenceUs || '',
              recordingUk: data?.recordingUrl,
              recordingUs: data?.recordingUsUrl,
              type: data?.type || ExerciseType.ReadSentence,
              imageTitle: data?.data.imageTitle || '',
              imageAttribute: data?.data.imageAttribute || ''
            }
      }
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ handleChange, submitForm, values, errors, touched, setFieldTouched, setFieldValue, dirty }) => {
        return quickEdit ? (
          <div className={styles.quickEditForm}>
            <TextField
              multiline
              margin="normal"
              required
              fullWidth
              id="sentenceUs"
              name="sentenceUs"
              size="small"
              onChange={handleChange('sentenceUs')}
              value={values.sentenceUs}
              error={touched.sentenceUs && !!errors.sentenceUs}
              helperText={(touched.sentenceUs && errors.sentenceUs) || ''}
              onBlur={() => setFieldTouched('sentenceUs')}
              disabled={formDisabled}
              InputProps={{ disableUnderline: true }}
              className={styles.quickEditInputField}
            />
            {dirty && Object.keys(errors).length === 0 && (
              <Button
                onClick={async () => {
                  setFieldValue('sentence', await handleTranslationClick(values.sentenceUs || '', 'uk'))
                  submitForm()
                }}
                className={styles.quickSaveButton}
                variant="contained"
                color="primary"
                size="small">
                Update
              </Button>
            )}
          </div>
        ) : (
          <div data-testid="ReadingPage">
            <Grid container spacing={4}>
              <Grid item xs>
                <Typography variant="h5" gutterBottom>
                  {isNew ? 'Add new' : 'Update'} {bookType === BookType.Listening ? 'listening page' : 'reading page'}
                </Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="points"
                  label="Stars"
                  name="points"
                  size="small"
                  inputProps={{ min: 0 }}
                  onChange={handleChange('points')}
                  value={values.points}
                  error={touched.points && !!errors.points}
                  helperText={(touched.points && errors.points) || ''}
                  onBlur={() => setFieldTouched('points')}
                  disabled={formDisabled}
                />
              </Grid>
              {!isVideoOverlay && (
                <Grid item xs>
                  <ImageField
                    showAlert={showAlert}
                    data-testid={'readingPageImage'}
                    name="image"
                    style={{ width: READING_PAGE_IMAGE_WIDTH, height: READING_PAGE_IMAGE_HEIGHT }}
                    label="Upload page cover"
                    value={values.image.url}
                    onChange={handleChange('image')}
                    onRemove={() => setFieldValue('image', { url: undefined, blob: undefined })}
                    error={touched.image && !!errors.image}
                    helperText={touched.image && !!errors.image ? (errors.image.blob as string) : ''}
                    disabled={formDisabled}
                    width={READING_PAGE_IMAGE_WIDTH}
                    height={null}
                    onBlur={() => setFieldTouched('image')}
                    aspectRatio={READING_PAGE_IMAGE_WIDTH / READING_PAGE_IMAGE_HEIGHT}
                    cropperProps={{ locked: false }}
                  />
                </Grid>
              )}
            </Grid>
            {!isVideoOverlay && (
              <>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="imageTitle"
                    label="Image Title"
                    name="imageTitle"
                    size="small"
                    onChange={handleChange('imageTitle')}
                    value={values.imageTitle}
                    error={touched.imageTitle && !!errors.imageTitle}
                    helperText={(touched.imageTitle && errors.imageTitle) || ''}
                    onBlur={() => setFieldTouched('imageTitle')}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    minRows={3}
                    id="imageAttribute"
                    label="Image Attribution"
                    name="imageAttribute"
                    size="small"
                    onChange={handleChange('imageAttribute')}
                    value={values.imageAttribute}
                    error={touched.imageAttribute && !!errors.imageAttribute}
                    helperText={(touched.imageAttribute && errors.imageAttribute) || ''}
                    onBlur={() => setFieldTouched('imageAttribute')}
                    disabled={formDisabled}
                  />
                </Grid>
              </>
            )}
            <Grid container spacing={4}>
              {!isVideoOverlay && (
                <Grid item xs>
                  <Typography variant="h5">UK</Typography>
                  <TextField
                    multiline
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="sentence"
                    placeholder="UK page text"
                    name="sentence"
                    inputProps={{ style: { height: 158 } }}
                    onChange={handleChange('sentence')}
                    value={values.sentence}
                    error={touched.sentence && !!errors.sentence}
                    helperText={(touched.sentence && errors.sentence) || ''}
                    onBlur={() => setFieldTouched('sentence')}
                    disabled={formDisabled}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () =>
                      setFieldValue('sentenceUs', await handleTranslationClick(values.sentence || '', 'us'))
                    }
                    fullWidth
                    disabled={!values.sentence || formDisabled}>
                    Translate to US spelling
                  </Button>
                  <RecordingField
                    name="recordingUk"
                    file={values.recordingUk}
                    className={styles.audio}
                    disabled={formDisabled}
                    removeRecording={() => setFieldValue('recordingUk', null)}
                    uploadRecording={(file) => setFieldValue('recordingUk', file)}
                  />
                </Grid>
              )}
              <Grid item xs>
                {!isVideoOverlay && <Typography variant="h5">US</Typography>}
                <TextField
                  multiline
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="sentenceUs"
                  placeholder={isVideoOverlay ? 'Page text' : 'US page text'}
                  name="sentenceUs"
                  inputProps={{ style: { height: 158 } }}
                  onChange={handleChange('sentenceUs')}
                  value={values.sentenceUs}
                  error={touched.sentenceUs && !!errors.sentenceUs}
                  helperText={(touched.sentenceUs && errors.sentenceUs) || ''}
                  onBlur={() => setFieldTouched('sentenceUs')}
                  disabled={formDisabled}
                />
                {!isVideoOverlay && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () =>
                      setFieldValue('sentence', await handleTranslationClick(values.sentenceUs || '', 'uk'))
                    }
                    fullWidth
                    disabled={!values.sentenceUs || formDisabled}>
                    Translate to UK spelling
                  </Button>
                )}
                {!isVideoOverlay && (
                  <RecordingField
                    name="recordingUs"
                    file={values.recordingUs}
                    className={styles.audio}
                    disabled={formDisabled}
                    removeRecording={() => setFieldValue('recordingUs', null)}
                    uploadRecording={(file) => setFieldValue('recordingUs', file)}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )
      }}
    </ExerciseFormFactory>
  )
}

export default ReadingPage
