import { Box, Button, Dialog, DialogContent, FormHelperText, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import Comprehension from '../../../Exercise/Comprehension/Comprehension'
import Conversation from '../../../Exercise/Conversation/Conversation'
import ReadingPage from '../../../Exercise/ReadingPage/ReadingPage'
import SentenceBuilder from '../../../Exercise/SentenceBuilder/SentenceBuilder'
import WordBuilder from '../../../Exercise/WordBuilder/WordBuilder'
import WordPractice from '../../../Exercise/WordPractice/WordPractice'
import { ValidationRules } from '../../../shared/helpers/ValidationRules'
import { Logger } from '../../../shared/logger/Logger'
import ExerciseApi from '../../../store/services/Exercise/exerciseApi'
import {
  ExerciseConversationModel,
  ExerciseModel,
  ExerciseMultiChoiceQuestionModel,
  ExerciseReadSentenceModel,
  ExerciseSentenceBuilderModel,
  ExerciseType,
  ExerciseWordBuilderModel,
  ExerciseWordPracticeModel
} from '../../../store/services/Exercise/types'
import StudySetApi from '../../../store/services/StudySet/studySetApi'
import { selectStudySetSingle } from '../../../store/services/StudySet/studySetReducer'
import {
  StudySetComponentExerciseModel,
  StudySetComponentExerciseRequest,
  StudySetComponentType
} from '../../../store/services/StudySet/types'
import { RootState } from '../../../store/types'
import { ComponentForm } from '../../components/ComponentForm/ComponentForm'
import { ComponentFormProps } from '../../types'
import { Exercise } from './Exercise'

const validationSchema = Yup.object().shape({
  exerciseId: ValidationRules.required
})

type Props = ComponentFormProps<StudySetComponentExerciseModel, StudySetComponentExerciseRequest>

export const ExerciseForm = ({ data, onCancel, onSave }: Props) => {
  const studySetId = useSelector<RootState, string | undefined>((state) => selectStudySetSingle(state).data?._id)
  const [showDialog, setShowDialog] = useState<boolean>(!data.exerciseId)

  const handleSubmit = (values: { exerciseId: string }): StudySetComponentExerciseRequest => {
    return {
      type: StudySetComponentType.Exercise,
      exerciseId: values.exerciseId,
      sentenceType: data.sentenceType
    }
  }

  const exerciseType = data.exerciseType || data.exercise?.type

  return (
    <ComponentForm
      validationSchema={validationSchema}
      initialValues={{
        exerciseId: data.exerciseId || ''
      }}
      onSubmitForm={handleSubmit}
      onCancel={onCancel}
      onSave={onSave}>
      {({ values, errors, handleSubmit, setFieldValue }) => {
        const exerciseProps = {
          onSubmitSuccess: () => setShowDialog(false),
          onCancel: () => setShowDialog(false),
          createExercise: async (exerciseData: FormData) => {
            if (studySetId) {
              try {
                const { data } = await StudySetApi.createStudySetExercise(studySetId, exerciseData)
                setFieldValue('exerciseId', data._id)
                handleSubmit()
              } catch (e) {
                Logger.log(e)
              }
            }
          },
          updateExercise: async (exerciseData: FormData) => {
            try {
              await ExerciseApi.update(exerciseData, values.exerciseId)
              handleSubmit()
            } catch (e) {
              Logger.log(e)
            }
          },
          bookChild: true
        }

        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {data.exercise && (
                  <Box display="inline-block" marginBottom={1}>
                    <Exercise exercise={data.exercise as ExerciseModel} />
                  </Box>
                )}

                <Box>
                  <Button
                    onClick={() => setShowDialog(true)}
                    variant={values.exerciseId ? 'outlined' : 'contained'}
                    color="primary">
                    {values.exerciseId ? 'Edit exercise' : 'Create exercise'}
                  </Button>
                </Box>
                {errors.exerciseId && <FormHelperText error>{errors.exerciseId}</FormHelperText>}
              </Grid>
            </Grid>
            <Dialog open={showDialog} onClose={(_, reason) => reason === 'escapeKeyDown' && setShowDialog(false)}>
              <DialogContent style={{ minWidth: 600 }}>
                {exerciseType === ExerciseType.WordBuilder && (
                  <WordBuilder {...exerciseProps} data={data.exercise as ExerciseWordBuilderModel} />
                )}
                {exerciseType === ExerciseType.SentenceBuilder && (
                  <SentenceBuilder {...exerciseProps} data={data.exercise as ExerciseSentenceBuilderModel} />
                )}
                {exerciseType === ExerciseType.MultiChoiceQuestion && (
                  <Comprehension {...exerciseProps} data={data.exercise as ExerciseMultiChoiceQuestionModel} />
                )}
                {exerciseType === ExerciseType.ReadSentence && (
                  <ReadingPage
                    {...exerciseProps}
                    data={data.exercise as ExerciseReadSentenceModel}
                    bookType={data.sentenceType}
                  />
                )}
                {exerciseType === ExerciseType.WordPractice && (
                  <WordPractice {...exerciseProps} data={data.exercise as ExerciseWordPracticeModel} />
                )}
                {exerciseType === ExerciseType.Conversation && (
                  <Conversation {...exerciseProps} data={data.exercise as ExerciseConversationModel} />
                )}
              </DialogContent>
            </Dialog>
          </>
        )
      }}
    </ComponentForm>
  )
}
