export interface Result {
  token: string
  id: number
  to_label: boolean
  cefr_level?: WORD_CEFR_LEVELS
  level_source?: any
  pos: string
  ner: string
  lemma: string
  word_sense?: WordSense
  other_word_senses: WordSense[]
  note?: any
  syllables: string
  syllables_ipa: string
  tense?: any
}

export interface GrammarStats {
  text: string
  level: WORD_CEFR_LEVELS
  start: number
  end: number
  patterns: Pattern[]
}

export interface Pattern {
  text: string
  level: WORD_CEFR_LEVELS
  pattern_name: string
  start_char: number
  end_char: number
}

export interface GrammarResult {
  token: string
  id: number
  to_label: boolean
  cefr_level?: WORD_CEFR_LEVELS
  level_source?: any
  pos: string
  ner: string
  lemma: string
  word_sense?: WordSense
  other_word_senses: WordSense[]
  note?: any
  syllables: string
  tense?: any
}

export enum WORD_CEFR_LEVELS {
  A1 = 'a1',
  A2 = 'a2',
  B1 = 'b1',
  B2 = 'b2',
  C1 = 'c1',
  C2 = 'c2',
  NONE = 'None'
}

export enum WORD_FORM_CEFR_LEVELS {
  A1 = 'a1',
  A2 = 'a2',
  B1 = 'b1',
  B2 = 'b2',
  C1 = 'c1',
  C2 = 'c2'
}
export interface Info {
  nr_prepositions: number
  prepositions: any[]
  nr_nouns: number
  nouns: any[]
  nr_possesives: number
  possesives: any[]
  future_tenses: any[]
  present_tenses: any[]
  past_tenses: any[]
  persons: any[]
  locations: any[]
  number_of_tokens: number
  number_of_sentences: number
  avg_sentence_length: number
  mtld_score: number
}

export interface LevelPercentages {
  a1: number[]
  a2: number[]
  b1: number[]
  b2: number[]
  c1: number[]
  c2: number[]
  None: number[]
}

export interface CefrLevels {
  level_percentages: LevelPercentages
  vocab_level?: string
  grammar_level?: any
  length_level?: any
}

export interface ClassifyResponse {
  result: Result[]
  info: Info
  cefr_levels: CefrLevels
}

export interface ClassifyGrammarResponse {
  grammar_stats: GrammarStats[]
  grammar_level: WORD_CEFR_LEVELS
}

export enum CEFR_COLORS {
  a1 = '#6379FF',
  a2 = '#B8F9FF',
  b1 = '#8FFD8C',
  b2 = '#F3FF6C',
  c1 = '#FFB453',
  c2 = '#FF794F',
  None = '#E80F62'
}

export enum POS {
  ADJ = 'ADJ',
  ADP = 'ADP',
  ADV = 'ADV',
  AUX = 'AUX',
  CONJ = 'CONJ',
  CCONJ = 'CCONJ',
  DET = 'DET',
  INTJ = 'INTJ',
  NOUN = 'NOUN',
  NUM = 'NUM',
  PART = 'PART',
  PRON = 'PRON',
  PROPN = 'PROPN',
  PUNCT = 'PUNCT',
  SCONJ = 'SCONJ',
  SYM = 'SYM',
  VERB = 'VERB',
  X = 'X',
  SPACE = 'SPACE',
  NNP = 'NNP',
  NN = 'NN'
}

export enum AUTHORS {
  Rachel = 'Rachel',
  Joe = 'Joe',
  Dani = 'Dani'
}

export interface WordSense {
  sense: string
  level_source: string
  cefr_level: WORD_CEFR_LEVELS
  note: string
}
