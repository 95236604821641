import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { ExerciseConversationModel } from '../../../../store/services/Exercise/types'
import { VideoPreview } from '../../../components/VideoPreview/VideoPreview'
import styles from './ConversationView.module.scss'

interface Props {
  exercise: ExerciseConversationModel
}
export const ConversationView = ({ exercise }: Props) => {
  return (
    <div className={styles.conversation}>
      {exercise.data.conversationElements.map((element, index) => (
        <Box display="flex" gridGap={16} key={index}>
          {element.video.originalUrl && <VideoPreview src={element.video.originalUrl} className={styles.video} />}
          <div>
            {element.instructorScript && (
              <div className={styles.instructions}>
                <Typography variant="h4">Instructor script</Typography>
                <Typography variant="body2" color="textSecondary">
                  {element.instructorScript}
                </Typography>
              </div>
            )}

            {element.learnerScript && (
              <div className={styles.instructions}>
                <Typography variant="h4">Learner script</Typography>
                <Typography variant="body2" color="textSecondary">
                  {element.learnerScript}
                </Typography>
              </div>
            )}
          </div>
        </Box>
      ))}
    </div>
  )
}
