import { PaginatedStatus, ApiReqState, Paginated } from '../../../shared/api/types'

export interface TutorState {
  collection: {
    results: TutorModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
}

export enum TUTOR_ACTIONS {
  LOAD_COLLECTION = 'TUTOR__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'TUTOR__LOAD_COLLECTION_REQ_STATE',
  UPDATE = 'TUTOR__UPDATE',
  SET_NEW = 'TUTOR__SET_NEW'
}

export enum TutorStatus {
  ACCEPTING_NEW = 'accepting-new',
  NOT_ACCEPTING_NEW = 'not-accepting-new',
  DISABLED = 'disabled'
}

export interface TutorModel {
  _id: string
  email: string
  firstName: string
  lastName: string
  status: TutorStatus
  userCount: number
  biography?: string
  avatarUrl?: string
  introductionVideo?: string
}

export interface TutorCreateModel extends Pick<TutorModel, 'email' | 'firstName' | 'lastName'> {}

export interface TutorLoadCollection {
  type: TUTOR_ACTIONS.LOAD_COLLECTION
  collection: Paginated<TutorModel>
}

export interface TutorLoadCollectionReqState {
  type: TUTOR_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export interface TutorUpdate {
  type: TUTOR_ACTIONS.UPDATE
  data: TutorModel
}

export interface TutorSetNew {
  type: TUTOR_ACTIONS.SET_NEW
  data: TutorModel
}

export type TutorActions = TutorLoadCollection | TutorLoadCollectionReqState | TutorUpdate | TutorSetNew
