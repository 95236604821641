import { PaginatedStatus, ApiReqState, Paginated } from '../../../shared/api/types'

export interface StudentState {
  collection: {
    results: StudentModel[]
    status?: PaginatedStatus
    reqState: ApiReqState
  }
}

export enum STUDENT_ACTIONS {
  LOAD_COLLECTION = 'STUDENT__LOAD_COLLECTION',
  LOAD_COLLECTION_REQ_STATE = 'STUDENT__LOAD_COLLECTION_REQ_STATE'
}

export interface StudentModel {
  _id: string
  email: string
  class: string
  teacher: string
  firstName: string
  lastName: string
  school: string
}

export interface StudentLoadCollection {
  type: STUDENT_ACTIONS.LOAD_COLLECTION
  collection: Paginated<StudentModel>
}

export interface StudentLoadCollectionReqState {
  type: STUDENT_ACTIONS.LOAD_COLLECTION_REQ_STATE
  reqState: ApiReqState
}

export type StudentActions = StudentLoadCollection | StudentLoadCollectionReqState
