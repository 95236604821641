import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import BookSingle from '../Book/BookSingle/BookSingle'
import BooksTable from '../Book/BooksTable/BooksTable'
import CefrTool from '../CefrTool/CefrTool'
import Dashboard from '../Dashboard/Dashboard'
import ExercisesTable from '../Exercise/ExercisesTable/ExercisesTable'
import MiniCourseSingle from '../MiniCourse/MiniCourseSingle/MiniCourseSingle'
import MiniCourseTable from '../MiniCourse/MiniCourseTable/MiniCourseTable'
import SchoolsTable from '../Schools/SchoolsTable'
import Settings from '../Settings/Settings'
import ShelfSingle from '../Shelf/ShelfSingle/ShelfSingle'
import ShelfTable from '../Shelf/ShelfTable/ShelfTable'
import StudentsTable from '../Students/StudentsTable'
import StudySetSingle from '../StudySet/StudySetSingle/StudySetSingle'
import StudySetsTable from '../StudySet/StudySetsTable/StudySetsTable'
import TeachersTable from '../Teachers/TeachersTable'
import TutorsTable from '../Tutors/TutorsTable'
import { selectAuthedUser } from '../store/services/Auth/authReducer'
import { ROUTES } from './routes'

export const AuthedRouteStack = () => {
  const privileges = useSelector(selectAuthedUser).privileges
  return (
    <Dashboard>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={privileges.manageBooks ? BooksTable : () => null} />
        {!privileges.manageBooks ? null : (
          <Route
            path={ROUTES.BOOK}
            render={({ match: { url } }) => (
              <>
                <Route exact path={`${url}`} component={() => <Redirect to={ROUTES.ROOT} />} />
                <Route path={`${url}/:id/:exerciseType?/:exerciseId?`} component={BookSingle} />
              </>
            )}
          />
        )}
        {!privileges.manageStandaloneExercises ? null : (
          <Route path={`${ROUTES.EXERCISE}/:exerciseType?/:exerciseId?`} component={ExercisesTable} />
        )}
        {!privileges.manageStudySets ? null : (
          <Route
            path={ROUTES.STUDY_SETS}
            render={() => (
              <>
                <Route exact path={ROUTES.STUDY_SETS} component={StudySetsTable} />
                <Route path={`${ROUTES.STUDY_SETS}/:studySetId`} component={StudySetSingle} />
              </>
            )}
          />
        )}
        {!privileges.manageMiniCourses ? null : (
          <Route
            path={ROUTES.MINI_COURSE}
            render={() => (
              <>
                <Route exact path={`${ROUTES.MINI_COURSE}`} component={MiniCourseTable} />
                <Route path={`${ROUTES.MINI_COURSE}/:miniCourseId`} component={MiniCourseSingle} />
              </>
            )}
          />
        )}
        {!privileges.manageShelves ? null : (
          <Route
            path={ROUTES.SHELF}
            render={() => (
              <>
                <Route exact path={`${ROUTES.SHELF}`} component={ShelfTable} />
                <Route path={`${ROUTES.SHELF}/:shelfId`} component={ShelfSingle} />
              </>
            )}
          />
        )}
        {!privileges.manageSchoolUsers ? null : (
          <Route
            path={[ROUTES.SCHOOLS, ROUTES.STUDENTS, ROUTES.TEACHERS, ROUTES.TUTORS]}
            render={() => (
              <>
                <Route path={ROUTES.SCHOOLS} component={SchoolsTable} />
                <Route path={ROUTES.STUDENTS} component={StudentsTable} />
                <Route path={ROUTES.TEACHERS} component={TeachersTable} />
                <Route path={`${ROUTES.TUTORS}/:tutorId?`} component={TutorsTable} />
              </>
            )}
          />
        )}
        {!privileges.manageSettings ? null : (
          <Route
            path={[ROUTES.CERF_TOOL, ROUTES.SETTINGS]}
            render={() => (
              <>
                <Route path={ROUTES.CERF_TOOL} component={CefrTool} />
                <Route path={ROUTES.SETTINGS} component={Settings} />
              </>
            )}
          />
        )}
        <Redirect to={ROUTES.ROOT} />
      </Switch>
    </Dashboard>
  )
}
