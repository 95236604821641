import { Button, Grid } from '@material-ui/core'
import { Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React from 'react'
import styles from './ComponentForm.module.scss'

interface ComponentFormProps<T, Values> extends Omit<FormikConfig<Values>, 'onSubmit'> {
  onSubmitForm: (values: Values) => T
  onCancel?: () => void
  onSave: (data: T) => Promise<void>
  children: (props: FormikProps<Values>) => React.ReactNode
}

export const ComponentForm = <T, Values extends FormikValues = FormikValues>({
  children,
  validationSchema,
  initialValues,
  onCancel,
  onSave,
  onSubmitForm
}: ComponentFormProps<T, Values>) => {
  const handleSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
    const preparedData = onSubmitForm(values)

    try {
      await onSave(preparedData)
    } catch {
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<Values>
      enableReinitialize={true}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}>
      {(formikProps) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={10}>
              {typeof children === 'function' ? children(formikProps) : children}
            </Grid>
            <Grid item xs={2}>
              <div className={styles.buttons}>
                <Button disabled={formikProps.isSubmitting} color="primary" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={formikProps.isSubmitting}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={() => formikProps.handleSubmit()}>
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        )
      }}
    </Formik>
  )
}
