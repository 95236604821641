import API from '../../../shared/api/API'
import { AxiosPromise } from 'axios'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import { TeacherModel } from './types'

type TeacherLoadResponse = Paginated<TeacherModel>

class TeacherApi {
  static load = ({ limit = 10, page = 0, sort, search }: PaginatedParams = {}): AxiosPromise<TeacherLoadResponse> =>
    API.get(`/statistics/teacher`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $search: search?.toLocaleLowerCase()
      })
    })
}

export default TeacherApi
