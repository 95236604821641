import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import { FieldArray, Formik } from 'formik'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ExerciseTypeSelector from '../../Exercise/components/ExerciseTypeSelector/ExerciseTypeSelector'
import Comprehension from '../../Exercise/Comprehension/Comprehension'
import ReadingPage from '../../Exercise/ReadingPage/ReadingPage'
import SentenceBuilder from '../../Exercise/SentenceBuilder/SentenceBuilder'
import WordBuilder from '../../Exercise/WordBuilder/WordBuilder'
import WordPractice from '../../Exercise/WordPractice/WordPractice'
import WordRace from '../../Exercise/WordRace/WordRace'
import { ROUTES } from '../../routes/routes'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import DraggableCard from '../../shared/components/DraggableCard/DraggableCard'
import {
  COMPREHENSION_IMAGE_HEIGHT,
  COMPREHENSION_IMAGE_WIDTH,
  READING_PAGE_IMAGE_HEIGHT,
  READING_PAGE_IMAGE_WIDTH
} from '../../shared/constants/imageSizes'
import { jsonToFormData } from '../../shared/helpers/jsonToFormData'
import { getBookSingle, selectBookSingleData, updateBookSingle } from '../../store/services/Book/bookReducer'
import { BookModel } from '../../store/services/Book/types'
import { getContentTags, selectContentTags } from '../../store/services/ContentTag/contentTagReducer'
import { ContentTagModel, ContentType } from '../../store/services/ContentTag/types'
import {
  copyExerciseForBook,
  createExerciseForBook,
  deleteExerciseForBook,
  getExercisesForBook,
  selectBookExercises,
  updateExerciseForBook
} from '../../store/services/Exercise/exerciseReducer'
import {
  getExerciseTypeDisplayName,
  getExerciseTypeInitialsName,
  printExerciseContent,
  sortExercisesByIdsArray
} from '../../store/services/Exercise/helpers'
import {
  ExerciseModel,
  ExerciseMultiChoiceQuestionModel,
  ExerciseReadSentenceModel,
  ExerciseReadWordRaceModel,
  ExerciseSentenceBuilderModel,
  ExerciseType,
  ExerciseWordBuilderModel,
  ExerciseWordPracticeModel
} from '../../store/services/Exercise/types'
import { getTopics, selectTopics } from '../../store/services/Topics/topicsReducer'
import { RootState } from '../../store/types'
import BookForm from '../BookForm/BookForm'
import CustomizedAvatar from '../CustomizedAvatar/CustomizedAvatar'
import styles from './BookSingle.module.scss'
import BottomDescription from './BottomDescription/BottomDescription'
import { getDomains, selectDomains } from '../../store/services/Domains/domainsReducer'

const BookSingle: React.FC<RouteComponentProps<{
  id: string
  exerciseType: ExerciseType
  exerciseId: ExerciseModel['_id']
}>> = ({ match, history }) => {
  const { id: bookId, exerciseType, exerciseId } = match.params
  const isNewBookRoute = bookId === 'new'

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()
  const { showAlert } = React.useContext(AlertContext)

  useEffect(() => {
    if (!isNewBookRoute) {
      ;(async () => {
        try {
          const book = ((await dispatch(getBookSingle(bookId))) as unknown) as BookModel
          await Promise.all([
            dispatch(getExercisesForBook(book._id)),
            dispatch(getContentTags({ contentId: bookId, contentType: ContentType.Book })),
            dispatch(getTopics({ contentId: bookId, contentType: ContentType.Book })),
            dispatch(getDomains({ contentId: bookId, contentType: ContentType.Book }))
          ])
        } catch (e) {
          history.push(ROUTES.BOOK)
        }
      })()
    }
    // eslint-disable-next-line
  }, [bookId])

  const reorderExercises = useCallback(
    async (values: { exercises: ExerciseModel[] }) => {
      const exercisesIds = values.exercises.map(({ _id }) => _id)
      try {
        await dispatch(updateBookSingle(jsonToFormData({ exercises: exercisesIds }), bookId))
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    // eslint-disable-next-line
    [bookId]
  )

  const bookData = useSelector((state: RootState) => selectBookSingleData(state, match.params.id))
  const bookExercises = useSelector((state: RootState) => selectBookExercises(state, match.params.id))
  const contentTags = useSelector<RootState, ContentTagModel | undefined>((state) =>
    selectContentTags(state, match.params.id, ContentType.Book)
  )
  const topics = useSelector<RootState, string[]>((state) => selectTopics(state, match.params.id, ContentType.Book))
  const domains = useSelector<RootState, string[]>((state) => selectDomains(state, match.params.id, ContentType.Book))

  const [bookEditState, setBookEditState] = useState(true)
  const exercisesDisabled = bookEditState || bookData?.visible

  const exerciseFormProps = useMemo(
    () => ({
      onSubmitSuccess: () => history.push(`${ROUTES.BOOK}/${bookId}`),
      onCancel: () => history.push(`${ROUTES.BOOK}/${bookId}`),
      createExercise: async (data: FormData) => {
        await dispatch(createExerciseForBook(bookId, data))
      },
      updateExercise: async (data: FormData, exerciseId: string) => {
        await dispatch(updateExerciseForBook(bookId, data, exerciseId))
      },
      deleteExercise: async (exerciseId: string) => {
        await dispatch(deleteExerciseForBook(bookId, exerciseId))
      },
      copyExercise: async (exerciseId: string) => {
        await dispatch(copyExerciseForBook(bookId, exerciseId))
      },
      formDisabled: exercisesDisabled,
      bookChild: true
    }),
    [bookId, exercisesDisabled, dispatch, history]
  )

  const [exerciseData, nextExerciseData, prevExerciseData] = useMemo(() => {
    const currentExercise = bookExercises.find(({ _id }) => _id === exerciseId)

    if (currentExercise) {
      const currentExerciseIndex = bookExercises.findIndex((exercise) => exercise === currentExercise)
      const nextExercise = bookExercises.find((_, index) => index === currentExerciseIndex + 1)
      const prevExercise = bookExercises.find((_, index) => index === currentExerciseIndex - 1)

      return [currentExercise, nextExercise, prevExercise]
    }

    return [currentExercise]
  }, [bookExercises, exerciseId])

  return (
    <>
      <div className={styles.root}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {isNewBookRoute ? 'Add New Book' : 'Book info'}
                </Typography>
                <BookForm
                  data={bookData}
                  contentTags={contentTags}
                  topics={topics}
                  domains={domains}
                  onEditable={(editable) => setBookEditState(editable)}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Book Content
                </Typography>
                <Formik
                  enableReinitialize={true}
                  initialValues={{ exercises: sortExercisesByIdsArray(bookExercises, bookData?.exercises || []) }}
                  onSubmit={reorderExercises}>
                  {({ values, handleSubmit }) => {
                    return (
                      <List>
                        <Divider />
                        <DndProvider backend={HTML5Backend}>
                          <FieldArray name={'exercises'}>
                            {(arrayHelpers) => (
                              <>
                                {values.exercises.map((exercise, index) => (
                                  <DraggableCard
                                    key={exercise._id}
                                    id={exercise._id}
                                    index={index}
                                    moveCard={(dragIndex, hoverIndex) => {
                                      arrayHelpers.swap(dragIndex, hoverIndex)
                                    }}
                                    dropCard={() => {
                                      handleSubmit()
                                    }}
                                    canDrag={!exercisesDisabled}>
                                    <ListItem data-testid={'BookSingleExerciseItem'}>
                                      <div className={styles.listItemContainer}>
                                        <ListItemText primary={getExerciseTypeDisplayName(exercise.type)} />
                                        <div className={styles.listItemInnerContainer}>
                                          {exercise.type === ExerciseType.WordBuilder && exercise.recordingUsUrl && (
                                            <Paper className={styles.promptContainer}>
                                              Prompt:
                                              {exercise.recordingUsUrl && <VolumeUpIcon />}
                                            </Paper>
                                          )}
                                          <div className={styles.listItemRow}>
                                            <ListItemAvatar>
                                              {exercise.imageUrl ? (
                                                <CustomizedAvatar
                                                  url={exercise.imageUrl}
                                                  width={80}
                                                  height={80 / (READING_PAGE_IMAGE_WIDTH / READING_PAGE_IMAGE_HEIGHT)}
                                                />
                                              ) : (
                                                <Paper className={styles.exerciseItemAvatarPaper}>
                                                  {getExerciseTypeInitialsName(exercise)}
                                                </Paper>
                                              )}
                                            </ListItemAvatar>
                                            <Paper className={styles.exerciseItemContent}>
                                              {exercise.type === ExerciseType.MultiChoiceQuestion &&
                                                !!exercise.data.imageQuestion && (
                                                  <CustomizedAvatar
                                                    url={exercise.data.imageQuestion}
                                                    width={COMPREHENSION_IMAGE_WIDTH * 0.2}
                                                    height={COMPREHENSION_IMAGE_HEIGHT * 0.2}
                                                  />
                                                )}
                                              {exercise.type === ExerciseType.SentenceBuilder ||
                                              exercise.type === ExerciseType.WordBuilder ? (
                                                exercise.data.wordOrSentenceUs
                                                  ?.split(exercise.type === ExerciseType.SentenceBuilder ? ' ' : '')
                                                  .map((word, ind) => (
                                                    <div key={ind}>
                                                      {exercise.data.missingIndexesUs?.includes(ind) ? (
                                                        <Chip variant="outlined" label={word} />
                                                      ) : (
                                                        word
                                                      )}
                                                    </div>
                                                  ))
                                              ) : exercise.type === ExerciseType.ReadSentence ? (
                                                <ReadingPage {...exerciseFormProps} data={exercise} quickEdit />
                                              ) : (
                                                printExerciseContent(exercise)
                                              )}
                                              {exercise.type === ExerciseType.MultiChoiceQuestion &&
                                                exercise.data.audioQuestionUs && <VolumeUpIcon />}
                                            </Paper>
                                            <div className={styles.arrowContainer}>
                                              <IconButton
                                                disabled={index === 0}
                                                onClick={() => {
                                                  arrayHelpers.move(index, index - 1)
                                                  handleSubmit()
                                                }}>
                                                <ArrowUpward />
                                              </IconButton>
                                              <IconButton
                                                disabled={index + 1 === values.exercises.length}
                                                onClick={() => {
                                                  arrayHelpers.move(index, index + 1)
                                                  handleSubmit()
                                                }}>
                                                <ArrowDownward />
                                              </IconButton>
                                            </div>
                                          </div>
                                          <div className={styles.listItemRow}>
                                            <div>
                                              <BottomDescription exercise={exercise} />
                                            </div>
                                            <Button
                                              className={styles.exerciseEdit}
                                              variant="outlined"
                                              onClick={() =>
                                                history.push(
                                                  `${ROUTES.BOOK}/${bookId}/${exercise.type}/${exercise._id}`
                                                )
                                              }>
                                              {exercisesDisabled ? 'Show' : 'Edit'}
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </ListItem>
                                    <Divider />
                                  </DraggableCard>
                                ))}
                              </>
                            )}
                          </FieldArray>
                        </DndProvider>
                      </List>
                    )
                  }}
                </Formik>
                <ExerciseTypeSelector
                  onAdd={(exerciseType) => history.push(`${ROUTES.BOOK}/${bookId}/${exerciseType}/new`)}
                  disabled={exercisesDisabled}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Dialog onClose={() => history.push(`${ROUTES.BOOK}/${bookId}`)} open={!!exerciseType && !!exerciseId}>
        {/*
          Add key as exerciseId so DialogContent render again to have the updated exerciseFormProps
          Without key, the update is not work when user press "previous exercise" or "next exercise"
        */}
        <DialogContent key={exerciseId} style={{ minWidth: 600 }}>
          {exerciseType === ExerciseType.ReadSentence && (
            <ReadingPage {...exerciseFormProps} data={exerciseData as ExerciseReadSentenceModel} />
          )}
          {exerciseType === ExerciseType.MultiChoiceQuestion && (
            <Comprehension {...exerciseFormProps} data={exerciseData as ExerciseMultiChoiceQuestionModel} />
          )}
          {exerciseType === ExerciseType.SentenceBuilder && (
            <SentenceBuilder {...exerciseFormProps} data={exerciseData as ExerciseSentenceBuilderModel} />
          )}
          {exerciseType === ExerciseType.WordBuilder && (
            <WordBuilder {...exerciseFormProps} data={exerciseData as ExerciseWordBuilderModel} />
          )}
          {exerciseType === ExerciseType.ReadWordRace && (
            <WordRace {...exerciseFormProps} data={exerciseData as ExerciseReadWordRaceModel} />
          )}
          {exerciseType === ExerciseType.WordPractice && (
            <WordPractice {...exerciseFormProps} data={exerciseData as ExerciseWordPracticeModel} />
          )}
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            disabled={!prevExerciseData}
            onClick={() => {
              history.push(`${ROUTES.BOOK}/${bookId}/${prevExerciseData?.type}/${prevExerciseData?._id}`)
            }}>
            Previous Exercise
          </Button>
          <Button
            disabled={!nextExerciseData}
            onClick={() => {
              history.push(`${ROUTES.BOOK}/${bookId}/${nextExerciseData?.type}/${nextExerciseData?._id}`)
            }}>
            Next Exercise
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BookSingle
