import API from '../../../shared/api/API'
import { AxiosPromise } from 'axios'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import {
  MoveComponentModel,
  SectionTag,
  StudySetComponentModel,
  StudySetComponentRequest,
  StudySetComponentSpeakingChallengeModel,
  StudySetComponentSpeakingChallengeRequest,
  StudySetExerciseModel,
  StudySetModel
} from './types'

type StudySetLoadResponse = Paginated<StudySetModel>

class StudySetApi {
  // Study set
  static load = (
    { limit = 10, page = 0, sort, filter, search }: PaginatedParams = {},
    signal?: AbortSignal
  ): AxiosPromise<StudySetLoadResponse> =>
    API.get(`/lesson-template`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $filter: filter && CollectionQueryHelper.filterParamsToQueryString(filter),
        $search: search?.toLocaleLowerCase()
      }),
      signal
    })

  static loadSingle = (id: StudySetModel['_id']): AxiosPromise<StudySetModel> => API.get(`/lesson-template/${id}`)

  static create = (data: FormData): AxiosPromise<StudySetModel> => API.post('/lesson-template', data)

  static delete = (id: StudySetModel['_id']) => API.delete(`/lesson-template/${id}`)

  static duplicate = (id: StudySetModel['_id']): AxiosPromise<StudySetModel> =>
    API.post(`/lesson-template/${id}/duplicate`)

  static update = (id: StudySetModel['_id'], data: FormData): AxiosPromise<StudySetModel> =>
    API.patch(`/lesson-template/${id}`, data)

  static publish = (id: StudySetModel['_id']): AxiosPromise<StudySetModel> =>
    API.patch(`/lesson-template/${id}/active`, { active: true })

  static unpublish = (id: StudySetModel['_id']): AxiosPromise<StudySetModel> =>
    API.patch(`/lesson-template/${id}/active`, { active: false })

  // Component
  static createComponent = (
    id: StudySetModel['_id'],
    sectionIndex: number,
    data: StudySetComponentModel | StudySetComponentRequest
  ): AxiosPromise<StudySetComponentModel> =>
    API.post(`/lesson-template/${id}/sections/${sectionIndex}/components`, data)

  static updateComponent = (
    id: StudySetModel['_id'],
    sectionIndex: number,
    componentIndex: number,
    data: StudySetComponentModel | StudySetComponentRequest
  ): AxiosPromise<StudySetComponentModel> =>
    API.put(`/lesson-template/${id}/section/${sectionIndex}/components/${componentIndex}`, data)

  static deleteComponent = (id: StudySetModel['_id'], sectionIndex: number, componentIndex: number) =>
    API.delete(`/lesson-template/${id}/section/${sectionIndex}/components/${componentIndex}`)

  static moveComponent = (
    id: StudySetModel['_id'],
    sectionIndex: number,
    data: MoveComponentModel
  ): AxiosPromise<StudySetModel> => API.patch(`/lesson-template/${id}/section/${sectionIndex}/components/move`, data)

  // Exercise
  static createStudySetExercise = (id: StudySetModel['_id'], data: FormData): AxiosPromise<StudySetExerciseModel> =>
    API.post(`/lesson-template/${id}/exercise`, data)

  static updateSpeakingChallenge = (
    id: StudySetModel['_id'],
    data: StudySetComponentSpeakingChallengeModel | StudySetComponentSpeakingChallengeRequest
  ): AxiosPromise<StudySetComponentSpeakingChallengeModel> => API.put(`/lesson-template/${id}/speaking-challenge`, data)

  static deleteSpeakingChallenge = (id: StudySetModel['_id']): AxiosPromise<void> =>
    API.delete(`/lesson-template/${id}/speaking-challenge`)

  static createSection = (id: StudySetModel['_id'], sectionTag: SectionTag): AxiosPromise<void> =>
    API.post(`/lesson-template/${id}/sections`, { sectionName: sectionTag })

  static updateSection = (id: StudySetModel['_id'], sectionIndex: number, sectionTag: SectionTag): AxiosPromise<void> =>
    API.patch(`/lesson-template/${id}/sections/${sectionIndex}`, { sectionName: sectionTag })

  static deleteSection = (id: StudySetModel['_id'], sectionIndex: number): AxiosPromise<void> =>
    API.delete(`/lesson-template/${id}/sections/${sectionIndex}`)
}

export default StudySetApi
