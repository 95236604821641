import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, selectUser } from '../../store/services/Auth/authReducer'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import styles from './AuthWall.module.scss'

interface Props {
  authPage: React.ReactElement
}

const AuthWall: React.FC<Props> = ({ children, authPage }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getUser())
      } catch (e) {
      } finally {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [])

  const loggedIn = useSelector(selectUser)

  if (loading) {
    return (
      <div className={styles.loaderWrapper}>
        <CircularProgress size={40} />
      </div>
    )
  } else if (!loggedIn) {
    return authPage
  } else {
    return <>{children}</>
  }
}

export default AuthWall
