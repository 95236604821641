import { Button, Card, CardContent, Divider, IconButton, TextField, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { DndProvider } from 'react-dnd'
import DraggableCard from '../../shared/components/DraggableCard/DraggableCard'

import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes/routes'
import { ShelfContent, ShelfContentType } from '../../store/services/Shelf/shelf.types'
import styles from './ShelfContent.module.scss'

interface Draggable {
  id: string
  component: ShelfContent
}

interface FormFields {
  contentId: string
}

interface ShelfContentsListProps {
  contents: ShelfContent[]
  disabled?: boolean
  onUpdate: (content: ShelfContent[]) => void
}

const ShelfContentsList: React.FC<ShelfContentsListProps> = ({ contents, disabled, onUpdate }) => {
  const [hoveredDraggableIndex, setHoveredDraggableIndex] = useState<number | null>(null)
  const [draggables, setDraggables] = useState<Draggable[]>([])

  useEffect(() => {
    setDraggables(
      contents.map((component, index) => ({
        id: `${component.contentType}--${index}`,
        component
      }))
    )
  }, [contents])

  const swapDraggables = (indexA: number, indexB: number) => {
    if (draggables) {
      const reordered = [...draggables]

      const temp = draggables[indexA]
      reordered[indexA] = reordered[indexB]
      reordered[indexB] = temp

      setDraggables(reordered)
    }
  }

  const submitForm = (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    const { contentId } = values
    onUpdate([...contents, { contentId: contentId.trim(), contentType: ShelfContentType.MiniCourse }])
    helpers.resetForm()
  }

  const deleteContent = (id: string) =>
    onUpdate(draggables.filter((draggable) => id !== draggable.id).map((draggable) => draggable.component))

  const handleDrop = () => onUpdate(draggables.map((draggable) => draggable.component))

  const checkDisableSubmit = (values: FormFields) => disabled

  const dragDisabled = disabled || draggables.length < 2

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography display="block" variant="h5" component="h2" gutterBottom>
          Contents
        </Typography>

        {draggables.length === 0 ? (
          <Typography color="secondary">No content</Typography>
        ) : (
          <ol className={styles.content}>
            <DndProvider backend={HTML5Backend}>
              {draggables.map(({ id, component }, index) => (
                <li key={id} className={`${hoveredDraggableIndex === index ? styles.dropLocation : ''}`}>
                  <DraggableCard
                    id={id}
                    index={index}
                    moveCard={(dragIndex, hoverIndex) => {
                      setHoveredDraggableIndex(hoverIndex)
                      swapDraggables(dragIndex, hoverIndex)
                    }}
                    dropCard={() => {
                      setHoveredDraggableIndex(null)
                      handleDrop()
                    }}
                    canDrag={!dragDisabled}>
                    <Card className={styles.contentCard} key={index}>
                      <Typography className={styles.contentType} color="secondary">
                        {component.contentType === ShelfContentType.MiniCourse ? 'MiniCourse' : 'Unknown'}
                      </Typography>

                      {component.contentType === ShelfContentType.MiniCourse ? (
                        <Link to={`${ROUTES.MINI_COURSE}/${component.contentId}`}>
                          MiniCourse ID: {component.contentId}
                        </Link>
                      ) : null}

                      {!disabled && (
                        <IconButton
                          aria-label="delete"
                          className={styles.deleteButton}
                          size="small"
                          onClick={() => deleteContent(id)}>
                          <Delete fontSize="inherit" />
                        </IconButton>
                      )}
                    </Card>
                  </DraggableCard>
                </li>
              ))}
            </DndProvider>
          </ol>
        )}

        <Divider className={styles.divider} />

        <Formik<FormFields>
          initialValues={{
            contentId: ''
          }}
          enableReinitialize={true}
          onSubmit={submitForm}>
          {({ values, handleChange, handleSubmit }) => {
            return (
              <div className={styles.addForm}>
                <TextField
                  id="contentId"
                  className={styles.rowItem}
                  variant="outlined"
                  name="contentId"
                  // TODO: change to ContentID and ContentType when we support more contents
                  label="MiniCourse ID"
                  disabled={disabled}
                  size="small"
                  margin="none"
                  value={values.contentId}
                  onChange={handleChange('contentId')}
                />

                <Button
                  className={`${styles.submitButton} ${styles.rowItem}`}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={checkDisableSubmit(values)}
                  onClick={() => handleSubmit()}>
                  Add content
                </Button>
              </div>
            )
          }}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default ShelfContentsList
