import { CEFRLevels } from '@astrid/components'
import { CONTENT_TAG_AGE_OPTIONS } from '../../store/services/ContentTag/types'
import { ApiFilterModel, ApiSortModel, PaginatedParams } from '../api/types'

export enum QueryParams {
  SORT = 'sort',
  PAGE = 'page',
  SEARCH = 'search',
  FILTER_VISIBLE = 'visible',
  FILTER_CEFR_LEVEL = 'cefrLevel',
  FILTER_CONTENT_TAG = 'contentTag'
}

class CollectionQueryHelper {
  static isSortActive = (key: string, params?: ApiSortModel) => params && key in params

  static getSortValue = (key: string, params: ApiSortModel | undefined) => {
    if (!params) {
      return undefined
    }

    if (!CollectionQueryHelper.isSortActive(key, params)) {
      return undefined
    } else {
      return params![key]
    }
  }

  static setSortValue = (key: string, params: ApiSortModel | undefined, defaultValue: 1 | -1 = 1): ApiSortModel => {
    if (!CollectionQueryHelper.getSortValue(key, params)) {
      return { [key]: defaultValue }
    } else {
      return { [key]: CollectionQueryHelper.getSortValue(key, params) === -1 ? 1 : -1 }
    }
  }

  static printSortDirection = (key: string, params: ApiSortModel | undefined) => {
    if (!params) {
      return undefined
    }

    if (!CollectionQueryHelper.isSortActive(key, params)) {
      return undefined
    } else {
      return params[key] > 0 ? 'asc' : 'desc'
    }
  }

  static sortParamsToQueryString = (sort: ApiSortModel) =>
    sort
      ? Object.entries(sort)
          .map(([key, value]) => `${key}:${value}`)
          .join(',')
      : ''

  static isFilterValueActive = (key: string, value: string | boolean, params: ApiFilterModel) => {
    const multiple = Array.isArray(params[key])
    if (multiple) {
      return (params[key] as string[]).includes(value as string)
    } else {
      return params[key] === value
    }
  }

  static setFilterValue = (key: string, value: string, params: ApiFilterModel, multiple?: boolean): ApiFilterModel => {
    if (multiple) {
      if (!params[key]) {
        return { ...params, [key]: [value] }
      } else if (Array.isArray(params[key])) {
        return (params[key] as string[]).includes(value)
          ? { ...params, [key]: (params[key] as string[]).filter((option) => option !== value) }
          : { ...params, [key]: [...(params[key] as string[]), value] }
      } else {
        return params
      }
    } else {
      return { ...params, [key]: value }
    }
  }

  static filterParamsToQueryString = (filter: ApiFilterModel) =>
    filter
      ? Object.entries(filter)
          .map(([key, value]) => {
            const multiple = Array.isArray(value)
            if (multiple) {
              return (value as string[]).map((v) => `${key}:${v}`).join(',')
            } else {
              return `${key}:${value}`
            }
          })
          .join(',')
      : ''

  static contentTagParamsToQueryString = (contentTag?: CONTENT_TAG_AGE_OPTIONS) => contentTag ?? ''

  static getBooleanFromQueryParam = (param: string | null) => {
    if (param === 'true') return true
    if (param === 'false') return false
    return null
  }

  static getSortFromQueryParam = (param: string | null): ApiSortModel | undefined => {
    const [sortKey, sortOrder] = param ? param.split(':') : []

    if (sortKey && sortOrder) {
      const sortOrderNumber = parseInt(sortOrder)

      if (sortOrderNumber === -1 || sortOrderNumber === 1) {
        return { [sortKey]: sortOrderNumber }
      }
    }
  }

  static getToggledSortValueAsQueryString = (key: string, queryString: string) => {
    const params = CollectionQueryHelper.getParamsFromQueryString(queryString)
    const sort = CollectionQueryHelper.setSortValue(key, params.sort)
    return sort[key] ? `${key}:${sort[key]}` : null
  }

  static getParamsFromQueryString = (queryString: string): PaginatedParams => {
    const params = new URLSearchParams(queryString)
    const [sortParam, pageParam, searchParam, statusFilterParam, cefrFilterParam, contentTagFilterParam] = [
      params.get(QueryParams.SORT),
      params.get(QueryParams.PAGE),
      params.get(QueryParams.SEARCH),
      params.get(QueryParams.FILTER_VISIBLE),
      params.getAll(QueryParams.FILTER_CEFR_LEVEL),
      params.get(QueryParams.FILTER_CONTENT_TAG)
    ]

    const page = pageParam ? parseInt(pageParam) : 0
    const sort = CollectionQueryHelper.getSortFromQueryParam(sortParam) || {}
    const search = searchParam || ''
    const cefrLevel = cefrFilterParam as CEFRLevels[]
    const contentTag = (contentTagFilterParam as CONTENT_TAG_AGE_OPTIONS) || undefined
    const visible = CollectionQueryHelper.getBooleanFromQueryParam(statusFilterParam)
    const filter = {
      ...(cefrLevel.length ? { cefrLevel } : undefined),
      ...(visible !== null ? { visible } : undefined)
    }

    return { page, sort, search, filter, contentTag }
  }

  private static isRemoved = (value: any) => value === null || value === undefined || value === ''

  static buildQueryString = (
    init: string | undefined,
    newParams: { [key in QueryParams]?: any },
    resetPagination?: boolean
  ): string => {
    const params = new URLSearchParams(init)

    for (const [key, value] of Object.entries(newParams)) {
      params.delete(key)

      if (Array.isArray(value)) {
        for (const item of value) {
          if (!CollectionQueryHelper.isRemoved(value)) {
            params.append(key, item.toString())
          }
        }
      } else {
        if (!CollectionQueryHelper.isRemoved(value)) {
          params.set(key, value.toString())
        }
      }
    }

    if (resetPagination) {
      params.delete(QueryParams.PAGE)
    }

    return params.toString()
  }
}

export default CollectionQueryHelper
