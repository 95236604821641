import API from '../../../shared/api/API'
import { AxiosPromise } from 'axios'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import { TutorCreateModel, TutorModel } from './types'

type TutorLoadResponse = Paginated<TutorModel>

class TutorApi {
  static load = ({ limit = 10, page = 0, sort, search }: PaginatedParams = {}): AxiosPromise<TutorLoadResponse> =>
    API.get(`/statistics/tutor`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $search: search?.toLocaleLowerCase()
      })
    })

  static create = (data: TutorCreateModel): AxiosPromise<TutorModel> => API.post('/tutor', data)

  static update = (id: TutorModel['_id'], data: Partial<TutorModel>): AxiosPromise<TutorModel> =>
    API.patch(`/tutor/${id}`, data)
}

export default TutorApi
