import {
  TutorActions,
  TUTOR_ACTIONS,
  TutorLoadCollectionReqState,
  TutorModel,
  TutorLoadCollection,
  TutorState,
  TutorUpdate,
  TutorSetNew,
  TutorCreateModel
} from './types'
import { Dispatch } from 'redux'
import { RootState } from '../../types'
import { createSelector } from 'reselect'
import { Logger } from '../../../shared/logger/Logger'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import TutorApi from './tutorApi'

export const TutorInitialState: TutorState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  }
}

const tutorReducer = (state: TutorState = TutorInitialState, action: TutorActions) => {
  switch (action.type) {
    case TUTOR_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case TUTOR_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    case TUTOR_ACTIONS.SET_NEW:
      return {
        ...state,
        collection: {
          ...state.collection,
          results: [action.data, ...state.collection.results]
        }
      }
    case TUTOR_ACTIONS.UPDATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          results: state.collection.results.map((tutor) => (tutor._id === action.data._id ? action.data : tutor))
        }
      }
    default:
      return state
  }
}

export default tutorReducer

// ACTIONS
const setTutorLoadReqState = (reqState: ApiReqState): TutorLoadCollectionReqState => ({
  type: TUTOR_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setTutorLoadCollection = (collection: Paginated<TutorModel>): TutorLoadCollection => ({
  type: TUTOR_ACTIONS.LOAD_COLLECTION,
  collection
})

export const getTutorCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<TutorLoadCollection | TutorLoadCollectionReqState>
) => {
  try {
    dispatch(setTutorLoadReqState(ApiReqState.PENDING))

    const collection = (await TutorApi.load(params)).data
    dispatch(setTutorLoadCollection(collection))

    dispatch(setTutorLoadReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setTutorLoadReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e as string)
  }
}

export const createTutor = (tutorData: TutorCreateModel) => async (
  dispatch: Dispatch<TutorSetNew>
): Promise<TutorModel> => {
  try {
    const { data } = await TutorApi.create(tutorData)
    dispatch(setNewTutor(data))
    return data
  } catch (e) {
    Logger.log(e)
    throw new Error(e as string)
  }
}

export const updateTutor = (tutorId: string, tutorData: Partial<TutorModel>) => async (
  dispatch: Dispatch<TutorUpdate>
): Promise<TutorModel> => {
  try {
    const { data } = await TutorApi.update(tutorId, tutorData)
    dispatch(setTutor(data))
    return data
  } catch (e) {
    Logger.log(e)
    throw new Error(e as string)
  }
}

export const setNewTutor = (data: TutorModel): TutorSetNew => ({
  type: TUTOR_ACTIONS.SET_NEW,
  data
})

export const setTutor = (data: TutorModel): TutorUpdate => ({
  type: TUTOR_ACTIONS.UPDATE,
  data
})

// SELECTORS
export const selectTutorCollection = (state: RootState) => state.tutor.collection
export const selectTutors = createSelector(selectTutorCollection, (collection) => collection.results)
export const selectTutorsStatus = createSelector(selectTutorCollection, (collection) => collection.status)
export const selectTutorsReqState = createSelector(selectTutorCollection, (collection) => collection.reqState)
