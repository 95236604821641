import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Login from '../Auth/Login/Login'
import AuthWall from './AuthWall/AuthWall'
import { AuthedRouteStack } from './AuthedRouteStack'

const RouteStack: React.FC = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthWall authPage={<Login />}>
        <AuthedRouteStack />
      </AuthWall>
    </Router>
  )
}

export default RouteStack
