import { Card, CardContent } from '@material-ui/core'
import React from 'react'
import { ExerciseWordBuilderModel } from '../../../../store/services/Exercise/types'
import styles from './WordBuilderView.module.scss'

interface Props {
  exercise: ExerciseWordBuilderModel
}

export const WorldBuilderView = ({ exercise }: Props) => {
  return (
    <Card>
      <CardContent>
        {exercise.data.wordOrSentenceUs.split('').map((character, index) => (
          <span
            key={`${character}__${index}`}
            className={`${styles.letter} ${exercise.data.missingIndexesUs?.includes(index) ? styles.missing : ''}`}>
            {character}
          </span>
        ))}
        <div>Distractors: {exercise.data.distractorsUs?.join(', ')}</div>
      </CardContent>
    </Card>
  )
}
