import { Button, Chip, Divider, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { FieldArray } from 'formik'
import * as React from 'react'
import { useCallback, useContext, useMemo } from 'react'
import * as Yup from 'yup'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import ImageField, { CroppedResult } from '../../shared/components/ImageField/ImageField'
import RecordingField from '../../shared/components/RecordingField/RecordingField'
import TertiaryButton from '../../shared/components/TertiaryButton/TertiaryButton'
import { DEFAULT_EXERCISE_POINTS, DEFAULT_VIDEO_OVERLAY_EXERCISE_POINTS } from '../../shared/constants/points'
import { getModifiedValues } from '../../shared/helpers/getModifiedValues'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { TARGET_COUNTRIES_ARRAY } from '../../store/services/Book/types'
import ExerciseApi from '../../store/services/Exercise/exerciseApi'
import { ExerciseFormFields, ExerciseType, ExerciseWordBuilderModel } from '../../store/services/Exercise/types'
import BuilderSegment from '../components/BuilderSegment/BuilderSegment'
import { ExerciseFormFactory } from '../ExerciseFormFactory/ExerciseFormFactory'
import { ExerciseFormProps } from '../types'
import styles from './WordBuilder.module.scss'

const getValidationSchema = (bookChild: boolean) => {
  const baseFields = {
    points: ValidationRules.points,
    wordOrSentenceUs: ValidationRules.required.max(20, 'Maximum 20 characters'),
    missingIndexesUs: Yup.array().min(1, 'Required'),
    imageTitle: ValidationRules.optionalWithMaxLength,
    imageAttribute: ValidationRules.optionalWithMaxLength
  }
  if (!bookChild) {
    return Yup.object().shape({
      ...baseFields,
      targetCountries: ValidationRules.targetCountries,
      difficultyLevel: ValidationRules.difficultyLevel
    })
  } else {
    return Yup.object().shape({
      ...baseFields
    })
  }
}

const getInitialValues = (data?: ExerciseWordBuilderModel, bookChild?: boolean, isVideoOverlay?: boolean) => {
  const defaultPoints = isVideoOverlay ? DEFAULT_VIDEO_OVERLAY_EXERCISE_POINTS : DEFAULT_EXERCISE_POINTS

  const baseFields: FormFields = {
    points: data?.points || defaultPoints,
    wordOrSentence: data?.data.wordOrSentence || '',
    missingIndexes: data?.data.missingIndexes || [],
    wordOrSentenceUs: data?.data.wordOrSentenceUs || '',
    missingIndexesUs: data?.data.missingIndexesUs || [],
    distractors: data?.data.distractors || [],
    distractorsUs: data?.data.distractorsUs || [],
    distractor: '',
    distractorUs: '',
    image: { url: data?.imageUrl || '', blob: null },
    recording: data?.recordingUrl || data?.recordingUsUrl || '',
    selectionMode: data ? data.data.missingIndexesUs.length > 0 : false,
    selectionModeUs: data && data.data.missingIndexesUs ? data.data.missingIndexesUs?.length > 0 : false,
    type: data?.type || ExerciseType.WordBuilder,
    imageTitle: data?.data.imageTitle || '',
    imageAttribute: data?.data.imageAttribute || ''
  }

  if (!bookChild) {
    return {
      ...baseFields,
      difficultyLevel: data?.difficultyLevel || 0,
      targetCountries: data?.targetCountries || []
    }
  } else {
    return baseFields
  }
}

interface Props extends ExerciseFormProps {
  data?: ExerciseWordBuilderModel
  isVideoOverlay?: boolean
}

type FormFields = ExerciseFormFields<ExerciseWordBuilderModel> & {
  selectionMode: boolean
  selectionModeUs: boolean
  distractor: string
  distractorUs: string
  image: CroppedResult | { url: string; blob?: null }
  recording: File | string | null | undefined
  imageTitle: string
  imageAttribute: string
}

const WordBuilder: React.FC<Props> = ({ data, isVideoOverlay, formDisabled, bookChild, ...exerciseFormProps }) => {
  const { showAlert } = useContext(AlertContext)
  const isNew = !data
  const handleSubmit = useCallback(
    ({
      type,
      points,
      wordOrSentence,
      wordOrSentenceUs,
      missingIndexes,
      missingIndexesUs,
      difficultyLevel,
      distractors,
      distractorsUs,
      targetCountries,
      image,
      recording,
      imageTitle,
      imageAttribute
    }: FormFields) => {
      const values = { type, points }
      const dataValues = {
        wordOrSentence,
        missingIndexes,
        distractors,
        distractorsUs,
        wordOrSentenceUs,
        missingIndexesUs,
        imageTitle,
        imageAttribute
      }

      let preparedData: any = {}
      if (isNew) {
        preparedData = values
      } else {
        const modifiedValues = getModifiedValues(values, initialValues) as any
        preparedData = modifiedValues
      }
      const modifiedDataValues = getModifiedValues(dataValues, initialValues) as any
      if (Object.keys(modifiedDataValues)) {
        preparedData.data = JSON.stringify(modifiedDataValues)
      }
      if (image?.blob instanceof Blob) {
        preparedData.image = image.blob
      }

      if (image?.blob === null && image?.url === null) {
        preparedData.image = null
      }

      if (recording instanceof File || recording === null) {
        preparedData.recordingUs = recording
      }

      if (!bookChild) {
        preparedData.difficultyLevel = difficultyLevel
        preparedData.targetCountries = targetCountries
      }

      return preparedData
    },
    // eslint-disable-next-line
    [isNew, bookChild]
  )

  const handleTranslationClick = useCallback(async (text: string, translateTo: 'uk' | 'us') => {
    try {
      const translation = await ExerciseApi.translateSpelling(text, translateTo)
      return translation.data
    } catch (e) {}
  }, [])

  const initialValues = useMemo(() => getInitialValues(data, bookChild, isVideoOverlay), [
    data,
    bookChild,
    isVideoOverlay
  ])
  const validationSchema = useMemo(() => getValidationSchema(bookChild), [bookChild])

  return (
    <ExerciseFormFactory
      {...exerciseFormProps}
      formDisabled={formDisabled}
      exerciseId={data?._id}
      bookChild={bookChild}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      submitDisabled={({ dirty, isSubmitting, values }) =>
        !dirty || isSubmitting || values.missingIndexesUs.length === 0
      }>
      {({ handleChange, values, errors, touched, setFieldTouched, setFieldValue }) => {
        return (
          <div data-testid="ReadingPage">
            <Typography variant="h5" gutterBottom>
              {isNew ? 'Add' : 'Update'} Word Craft
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="points"
              label="Stars"
              name="points"
              size="small"
              inputProps={{ min: 0 }}
              onChange={handleChange('points')}
              value={values.points}
              error={touched.points && !!errors.points}
              helperText={(touched.points && errors.points) || ''}
              onBlur={() => setFieldTouched('points')}
              disabled={formDisabled}
            />
            {!bookChild && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="difficultyLevel"
                    label="Difficulty level"
                    name="difficultyLevel"
                    size="small"
                    inputProps={{ min: 0, max: 100 }}
                    onChange={handleChange('difficultyLevel')}
                    value={values.difficultyLevel}
                    error={touched.difficultyLevel && !!errors.difficultyLevel}
                    helperText={(touched.difficultyLevel && errors.difficultyLevel) || ''}
                    onBlur={() => setFieldTouched('difficultyLevel')}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="targetCountries"
                    label="Target countries"
                    name="targetCountries"
                    size="small"
                    data-testid="targetCountries"
                    select
                    SelectProps={{ multiple: true }}
                    onChange={(e) => {
                      setFieldTouched('targetCountries')
                      return handleChange('targetCountries')(e)
                    }}
                    value={values.targetCountries}
                    error={touched.targetCountries && !!errors.targetCountries}
                    helperText={(touched.targetCountries && errors.targetCountries) || ''}
                    onBlur={() => setFieldTouched('targetCountries')}
                    disabled={formDisabled}
                    inputProps={{ 'data-testid': 'targetCountriesInput' }}>
                    {TARGET_COUNTRIES_ARRAY.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
            {!isVideoOverlay && (
              <>
                <Typography variant="h5">UK</Typography>
                {!values.selectionMode && (
                  <Grid container spacing={2} alignItems={'flex-start'}>
                    <Grid item xs={7}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="wordOrSentence"
                        label="UK word"
                        placeholder="Enter a UK word"
                        name="wordOrSentence"
                        size="small"
                        onChange={(e) => {
                          setFieldValue('missingIndexes', [])
                          return handleChange('wordOrSentence')({
                            target: { value: e.target.value.replace(/ /g, '') }
                          } as React.ChangeEvent<any>)
                        }}
                        value={values.wordOrSentence}
                        error={touched.wordOrSentence && !!errors.wordOrSentence}
                        helperText={(touched.wordOrSentence && errors.wordOrSentence) || ''}
                        onBlur={() => setFieldTouched('wordOrSentence')}
                        disabled={formDisabled}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TertiaryButton
                        className={styles.saveSentenceBtn}
                        fullWidth
                        data-testid="saveWordBtn"
                        onClick={() => setFieldValue('selectionMode', true)}
                        disabled={values.wordOrSentence === '' || !!errors.wordOrSentence || formDisabled}>
                        Save
                      </TertiaryButton>
                    </Grid>
                    <Grid item xs={3}>
                      <TertiaryButton
                        className={styles.saveSentenceBtn}
                        fullWidth
                        data-testid="translateToUsBtn"
                        onClick={async () =>
                          setFieldValue(
                            'wordOrSentenceUs',
                            await handleTranslationClick(values.wordOrSentence || '', 'us')
                          )
                        }
                        disabled={
                          values.wordOrSentence === '' ||
                          !!errors.wordOrSentence ||
                          formDisabled ||
                          values.selectionModeUs
                        }>
                        Translate to US
                      </TertiaryButton>
                    </Grid>
                  </Grid>
                )}
                {values.selectionMode && (
                  <>
                    <FieldArray name={'missingIndexes'}>
                      {(arrayHelpers) => {
                        return (
                          <div>
                            <Grid container alignItems={'center'}>
                              <Grid item xs={6}>
                                <Typography variant="subtitle2">Select wrong letters</Typography>
                              </Grid>
                              <Grid item xs={6} container justifyContent="flex-end">
                                <Button
                                  endIcon={<Clear />}
                                  onClick={() => {
                                    setFieldValue('selectionMode', false)
                                    setFieldValue('missingIndexes', [])
                                  }}
                                  disabled={formDisabled}>
                                  Delete word
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              {values.wordOrSentence
                                ?.split('')
                                .filter((s) => s !== '')
                                .map((segment, index) => {
                                  return (
                                    <Grid item key={index}>
                                      <BuilderSegment
                                        index={`${index}`}
                                        value={segment}
                                        checked={values.missingIndexesUs.includes(index)}
                                        add={() => arrayHelpers.push(index)}
                                        remove={() => arrayHelpers.remove(values.missingIndexesUs.indexOf(index))}
                                        disabled={formDisabled}
                                      />
                                    </Grid>
                                  )
                                })}
                            </Grid>
                          </div>
                        )
                      }}
                    </FieldArray>
                    <FieldArray name="distractors">
                      {(arrayHelpers) => {
                        return (
                          <div className={styles.marginTop}>
                            <Grid container alignItems={'flex-start'}>
                              <Grid item xs={6}>
                                <Typography variant="subtitle2">Add distractors</Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} spacing={1}>
                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  id="distractor"
                                  label="Distractor"
                                  placeholder="Enter a distractor"
                                  name="distractor"
                                  size="small"
                                  onChange={handleChange('distractor')}
                                  value={values.distractor}
                                  disabled={formDisabled}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  disabled={values.distractors!.length > 3 || values.distractor === ''}
                                  onClick={() => {
                                    arrayHelpers.push(values.distractor)
                                    setFieldValue('distractor', '')
                                  }}>
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              {values.distractors?.map((word, index) => (
                                <Grid item key={index}>
                                  <Chip label={word} color="primary" onDelete={() => arrayHelpers.remove(index)} />
                                </Grid>
                              ))}
                            </Grid>
                          </div>
                        )
                      }}
                    </FieldArray>
                  </>
                )}
              </>
            )}
            <Divider className={styles.divider} />
            {!isVideoOverlay && <Typography variant="h5">US</Typography>}
            {!values.selectionModeUs && (
              <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid item xs={7}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    id="wordOrSentenceUs"
                    label={isVideoOverlay ? 'Word' : 'US word'}
                    placeholder={isVideoOverlay ? 'Enter a word' : 'Enter a US word'}
                    name="wordOrSentenceUs"
                    size="small"
                    onChange={(e) => {
                      setFieldValue('missingIndexesUs', [])
                      return handleChange('wordOrSentenceUs')({
                        target: { value: e.target.value.replace(/ /g, '') }
                      } as React.ChangeEvent<any>)
                    }}
                    value={values.wordOrSentenceUs}
                    error={touched.wordOrSentenceUs && !!errors.wordOrSentenceUs}
                    helperText={(touched.wordOrSentenceUs && errors.wordOrSentenceUs) || ''}
                    onBlur={() => setFieldTouched('wordOrSentenceUs')}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TertiaryButton
                    className={styles.saveSentenceBtn}
                    fullWidth
                    data-testid="saveWordBtnUs"
                    onClick={() => setFieldValue('selectionModeUs', true)}
                    disabled={values.wordOrSentenceUs === '' || !!errors.wordOrSentenceUs || formDisabled}>
                    Save
                  </TertiaryButton>
                </Grid>
                <Grid item xs={3}>
                  {!isVideoOverlay && (
                    <TertiaryButton
                      className={styles.saveSentenceBtn}
                      fullWidth
                      data-testid="translateToUkBtn"
                      onClick={async () =>
                        setFieldValue(
                          'wordOrSentence',
                          await handleTranslationClick(values.wordOrSentenceUs || '', 'uk')
                        )
                      }
                      disabled={
                        values.wordOrSentenceUs === '' ||
                        !!errors.wordOrSentenceUs ||
                        formDisabled ||
                        values.selectionMode
                      }>
                      Translate to UK
                    </TertiaryButton>
                  )}
                </Grid>
              </Grid>
            )}
            {values.selectionModeUs && (
              <>
                <FieldArray name={'missingIndexesUs'}>
                  {(arrayHelpers) => {
                    return (
                      <div>
                        <Grid container alignItems={'center'}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2">Select wrong letters</Typography>
                          </Grid>
                          <Grid item xs={6} container justifyContent="flex-end">
                            <Button
                              endIcon={<Clear />}
                              onClick={() => {
                                setFieldValue('selectionModeUs', false)
                                setFieldValue('missingIndexesUs', [])
                              }}
                              data-testid={'delete-word-us'}
                              disabled={formDisabled}>
                              Delete word
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          {values
                            .wordOrSentenceUs!.split('')
                            .filter((s) => s !== '')
                            .map((segment, index) => {
                              return (
                                <Grid item key={index}>
                                  <BuilderSegment
                                    index={`US${index}`}
                                    value={segment}
                                    checked={values.missingIndexesUs!.includes(index)}
                                    add={() => arrayHelpers.push(index)}
                                    remove={() => arrayHelpers.remove(values.missingIndexesUs!.indexOf(index))}
                                    disabled={formDisabled}
                                  />
                                </Grid>
                              )
                            })}
                        </Grid>
                      </div>
                    )
                  }}
                </FieldArray>
                <FieldArray name="distractorsUs">
                  {(arrayHelpers) => {
                    return (
                      <div className={styles.marginTop}>
                        <Grid container alignItems={'flex-start'}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2">Add distractors</Typography>
                          </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} spacing={1}>
                          <Grid item xs={6}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              id="distractorUs"
                              label={isVideoOverlay ? 'Distractor' : 'US distractor'}
                              placeholder={isVideoOverlay ? 'Enter a distractor' : 'Enter a US distractor'}
                              name="distractorUs"
                              size="small"
                              onChange={handleChange('distractorUs')}
                              value={values.distractorUs}
                              disabled={formDisabled}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              disabled={values.distractorsUs!.length > 3 || values.distractorUs === ''}
                              onClick={() => {
                                arrayHelpers.push(values.distractorUs)
                                setFieldValue('distractorUs', '')
                              }}>
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          {values.distractorsUs?.map((word, index) => (
                            <Grid item key={index}>
                              <Chip label={word} color="primary" onDelete={() => arrayHelpers.remove(index)} />
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    )
                  }}
                </FieldArray>
              </>
            )}
            {!isVideoOverlay && (
              <>
                <Divider className={styles.divider} />

                <Typography variant="h5">Prompt</Typography>

                <Typography variant="subtitle2">Upload optional image prompt</Typography>
                <div>
                  <Grid container direction="column">
                    <Grid>
                      <ImageField
                        showAlert={showAlert}
                        data-testid={'wordBuilderImage'}
                        name="image"
                        style={{ width: 288, height: 162 }}
                        label="Upload support image"
                        value={values.image.url}
                        onChange={handleChange('image')}
                        disabled={formDisabled}
                        onBlur={() => setFieldTouched('image')}
                        aspectRatio={null}
                        cropperProps={{ locked: false }}
                      />
                    </Grid>
                    <Grid>
                      <Button
                        onClick={() => setFieldValue('image', { url: null, blob: null })}
                        variant="outlined"
                        color="primary">
                        Delete image
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="imageTitle"
                        label="Image Title"
                        name="imageTitle"
                        size="small"
                        onChange={handleChange('imageTitle')}
                        value={values.imageTitle}
                        error={touched.imageTitle && !!errors.imageTitle}
                        helperText={(touched.imageTitle && errors.imageTitle) || ''}
                        onBlur={() => setFieldTouched('imageTitle')}
                        disabled={formDisabled}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="imageAttribute"
                        label="Image Attribution"
                        name="imageAttribute"
                        size="small"
                        onChange={handleChange('imageAttribute')}
                        value={values.imageAttribute}
                        error={touched.imageAttribute && !!errors.imageAttribute}
                        helperText={(touched.imageAttribute && errors.imageAttribute) || ''}
                        onBlur={() => setFieldTouched('imageAttribute')}
                        disabled={formDisabled}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className={styles.marginTop}>
                  <Typography variant="subtitle2">Upload optional audio prompt</Typography>
                  <RecordingField
                    name="recording"
                    file={values.recording}
                    className={styles.marginTop}
                    disabled={formDisabled}
                    removeRecording={() => setFieldValue('recording', null)}
                    uploadRecording={(file) => setFieldValue('recording', file)}
                  />
                </div>
              </>
            )}
          </div>
        )
      }}
    </ExerciseFormFactory>
  )
}

export default WordBuilder
