import * as React from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import DashboardToolbar from '../Dashboard/DashboardToolbar/DashboardToolbar'
import { RouteComponentProps } from 'react-router-dom'
import SearchField from '../shared/components/SearchField/SearchField'
import { connect, ConnectedProps } from 'react-redux'
import styles from './TeachersTable.module.scss'
import { ApiReqState, ApiSortModel } from '../shared/api/types'
import { RootState } from '../store/types'
import { ArrowDropDown } from '@material-ui/icons'
import CollectionQueryHelper from '../shared/helpers/CollectionQueryHelper'
import TableOverlay from '../shared/components/TableOverlay/TableOverlay'
import { TeacherModel } from '../store/services/Teacher/types'
import {
  selectTeachers,
  selectTeachersStatus,
  selectTeachersReqState,
  getTeacherCollection
} from '../store/services/Teacher/teacherReducer'

interface State {
  sortParams: ApiSortModel
  searchQuery: string
}

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {}

export const TEACHERS_PER_PAGE = 10

class TeachersTable extends React.Component<Props, State> {
  state: State = {
    sortParams: {},
    searchQuery: ''
  }

  componentDidMount(): void {
    this.loadTeachers()
  }

  loadTeachers = async (page: number = 0) => {
    try {
      const { searchQuery, sortParams } = this.state
      await this.props.getTeacherCollection({ limit: TEACHERS_PER_PAGE, page, sort: sortParams, search: searchQuery })
    } catch (e) {}
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.loadTeachers(newPage)
  }

  handleSortRequest = (key: keyof TeacherModel) => (e: React.MouseEvent<unknown>) => {
    this.setState(
      (state) => ({ sortParams: CollectionQueryHelper.setSortValue(key, state.sortParams) }),
      () => {
        this.loadTeachers(0)
      }
    )
  }

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value }, () => {
      this.loadTeachers(0)
    })
  }

  render() {
    const { teachers, teachersResStatus, teacherReqState } = this.props
    const { sortParams, searchQuery } = this.state
    const emptyRows = TEACHERS_PER_PAGE - teachers.length

    const isLoading = teacherReqState === ApiReqState.IDLE || teacherReqState === ApiReqState.PENDING

    return (
      <>
        <DashboardToolbar>
          <Typography component="h1" variant="h6">
            Teachers
          </Typography>
          <SearchField
            containerClassName={styles.searchField}
            value={searchQuery}
            onChange={this.handleSearchQuery}
            onClear={() => this.handleSearchQuery({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
          />
        </DashboardToolbar>
        <TableContainer component={Paper}>
          {!isLoading && teachers.length === 0 && (
            <TableOverlay>
              <Typography>Teachers not found.</Typography>
            </TableOverlay>
          )}
          {isLoading && (
            <TableOverlay data-testid="TeachersTableLoader">
              <CircularProgress size={40} />
            </TableOverlay>
          )}
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    data-testid={'SortByFirstNameTeacherLabel'}
                    active={CollectionQueryHelper.isSortActive('firstName', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('firstName', sortParams)}
                    onClick={this.handleSortRequest('firstName')}
                    IconComponent={ArrowDropDown}>
                    First name
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('lastName', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('lastName', sortParams)}
                    onClick={this.handleSortRequest('lastName')}
                    IconComponent={ArrowDropDown}>
                    Last name
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('email', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('email', sortParams)}
                    onClick={this.handleSortRequest('email')}
                    IconComponent={ArrowDropDown}>
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 420 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('school', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('school', sortParams)}
                    onClick={this.handleSortRequest('school')}
                    IconComponent={ArrowDropDown}>
                    School
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('classCount', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('classCount', sortParams)}
                    onClick={this.handleSortRequest('classCount')}
                    IconComponent={ArrowDropDown}>
                    No of classes
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('studentsCount', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('studentsCount', sortParams)}
                    onClick={this.handleSortRequest('studentsCount')}
                    IconComponent={ArrowDropDown}>
                    No of students
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers.map((teacher) => (
                <TableRow
                  hover
                  key={teacher._id}
                  // onClick={() => history.push(`${ROUTES.TEACHER}/${teacher._id}`)}
                  data-testid={'TeacherListRow'}>
                  <TableCell component="th" scope="row">
                    {teacher.firstName}
                  </TableCell>

                  <TableCell>{teacher.lastName}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  <TableCell>{teacher.school}</TableCell>
                  <TableCell>{teacher.classCount}</TableCell>
                  <TableCell>{teacher.studentsCount}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 59 * emptyRows }} data-testid={'TeacherListEmptyRow'}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {teachersResStatus ? (
            <TablePagination
              component="div"
              colSpan={3}
              count={teachersResStatus.total}
              rowsPerPage={TEACHERS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={teachersResStatus.offset / TEACHERS_PER_PAGE}
              onPageChange={this.handleChangePage}
            />
          ) : (
            <span>Not loaded yet</span>
          )}
        </TableContainer>
      </>
    )
  }
}

const connector = connect(
  (state: RootState) => ({
    teachers: selectTeachers(state),
    teachersResStatus: selectTeachersStatus(state),
    teacherReqState: selectTeachersReqState(state)
  }),
  { getTeacherCollection }
)

export default connector(TeachersTable)
