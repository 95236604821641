import { Card, CardContent, Chip } from '@material-ui/core'
import React from 'react'
import { ExerciseWordPracticeModel } from '../../../../store/services/Exercise/types'
import styles from './WordPracticeView.module.scss'

interface Props {
  exercise: ExerciseWordPracticeModel
}

export const WordPracticeView = ({ exercise }: Props) => {
  return (
    <Card>
      <CardContent>
        {exercise.data.words.map((word, index) => (
          <Chip key={`${word}__${index}`} className={styles.word} label={word} />
        ))}
      </CardContent>
    </Card>
  )
}
