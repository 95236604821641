/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Container, TextField, Button, Avatar, Typography, CircularProgress } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import * as Yup from 'yup'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { AuthCredentials } from '../../store/services/Auth/types'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../store/services/Auth/authReducer'
import styles from './Login.module.scss'
import { useCallback, useContext } from 'react'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import { HelperText } from '@astrid/components'

const GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME = 'handleRecaptchaCheck'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email,
  password: ValidationRules.required
})

const Login: React.FC = () => {
  const { showAlert } = useContext(AlertContext)
  const dispatch = useDispatch()
  const [showCaptchaError, setShowCaptchaError] = useState(false)

  const recaptchaActivated = !!process.env.REACT_APP_RECAPTCHA_SITE_KEY

  const handleFormSubmit = useCallback(
    async ({ email, password }: AuthCredentials, { setSubmitting }: FormikHelpers<AuthCredentials>) => {
      try {
        let captchaToken = ''
        if (recaptchaActivated) {
          captchaToken = window.grecaptcha?.enterprise.getResponse()
          if (!captchaToken) {
            setShowCaptchaError(true)
            return
          }
        }
        await dispatch(loginUser({ email, password, captchaToken }))
      } catch (error) {
        window.grecaptcha?.enterprise.reset()
        showAlert(printApiMessage(error))
        setSubmitting(false)
      }
    },
    [dispatch, recaptchaActivated]
  )

  // Reset error when captcha is completed
  useEffect(() => {
    window[GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME] = () => setShowCaptchaError(false)
  }, [])

  // Load recaptcha script
  // Add scrpit in index.html doesn't work because the script need to be loaded after g-recaptcha component render
  useEffect(() => {
    const scriptId = 'LoginRecaptchaScript'
    const existingScript = document.getElementById(scriptId)
    if (existingScript) {
      document.body.removeChild(existingScript)
    }
    const script = document.createElement('script')
    script.id = scriptId
    script.src = 'https://www.google.com/recaptcha/enterprise.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <Container data-testid={'LoginPage'} className={styles.root}>
      <header className={styles.header}>
        <Avatar className={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </header>

      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}>
        {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isSubmitting, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              onChange={handleChange('email')}
              value={values.email}
              error={touched.email && !!errors.email}
              helperText={(touched.email && errors.email) || ''}
              onBlur={() => setFieldTouched('email')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              size="small"
              onChange={handleChange('password')}
              value={values.password}
              error={touched.password && !!errors.password}
              helperText={(touched.password && errors.password) || ''}
              onBlur={() => setFieldTouched('password')}
            />
            {recaptchaActivated ? (
              <div
                className={`g-recaptcha ${styles.captcha}`}
                data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                data-callback={GLOBAL_RECAPTCHA_CHECK_FUNCTION_NAME}
              />
            ) : null}
            {showCaptchaError ? <HelperText text="Please complete the security check." error /> : null}
            <Button
              data-testid={'submit'}
              type="submit"
              variant={'contained'}
              color={'primary'}
              fullWidth
              disabled={!isValid || !dirty || isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Log in'}
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  )
}
export default Login
