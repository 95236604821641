import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router'

import useSWR from 'swr'
import { ROUTES } from '../../routes/routes'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import ContentTagApi from '../../store/services/ContentTag/contentTagApi'
import { ContentType } from '../../store/services/ContentTag/types'

import ShelfApi from '../../store/services/Shelf/shelf.api'
import { CreateShelfBody, ShelfContent, UpdateShelfBody } from '../../store/services/Shelf/shelf.types'
import TopicsApi from '../../store/services/Topics/topicsApi'
import ShelfContentsList from '../ShelfContent/ShelfContent'
import ShelfForm from '../ShelfForm/ShelfForm'

interface ShelfParams {
  shelfId: string
}

export const SHELF_NEW_ID = 'new'

const ShelfSingle = () => {
  const { showAlert } = useContext(AlertContext)
  const history = useHistory()
  const { shelfId: id } = useParams<ShelfParams>()

  const shouldLoad = id && id !== SHELF_NEW_ID
  const { data: shelf, mutate } = useSWR(shouldLoad ? `load-single/${id}` : null, () => ShelfApi.loadSingle(id))

  const { data: industries } = useSWR('all-industries', () => ContentTagApi.loadAllIndustries())
  const { data: industryTags } = useSWR(shouldLoad ? `industries/${id}` : null, () =>
    ContentTagApi.loadIndustries({ contentId: id, contentType: ContentType.Shelf })
  )

  const { data: topics } = useSWR('all-topics', () => TopicsApi.getAll().then((res) => res.data))
  const { data: topicTags } = useSWR(shouldLoad ? `topics/${id}` : null, () =>
    TopicsApi.load({ contentId: id, contentType: ContentType.Shelf }).then((res) => res.data)
  )

  const handleCreate = async ({
    shelf,
    industryTags,
    topicTags
  }: {
    shelf: CreateShelfBody
    industryTags: string[]
    topicTags: string[]
  }) => {
    try {
      const result = await ShelfApi.create(shelf)
      await ContentTagApi.updateIndustries({
        contentType: ContentType.Shelf,
        contentId: result._id,
        tags: industryTags
      })
      await TopicsApi.update({ contentType: ContentType.Shelf, contentId: result._id, topics: topicTags })
      history.push(`${ROUTES.SHELF}/${result._id}`)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleUpdate = async ({
    shelf,
    industryTags,
    topicTags
  }: {
    shelf: UpdateShelfBody
    industryTags: string[]
    topicTags: string[]
  }) => {
    try {
      const data = await ShelfApi.update(id, shelf)
      await ContentTagApi.updateIndustries({ contentType: ContentType.Shelf, contentId: id, tags: industryTags })
      await TopicsApi.update({ contentType: ContentType.Shelf, contentId: id, topics: topicTags })
      await mutate(data)
      showAlert('Shelf updated succesfully', 'success')
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handlePublish = async (active: boolean) => {
    try {
      const data = await (active ? ShelfApi.publish(id) : ShelfApi.unpublish(id))
      await mutate(data)
      showAlert(active ? 'Shelf published' : 'Shelf unpublished', 'success')
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleDelete = async () => {
    try {
      await ShelfApi.delete(id)
      history.push(ROUTES.SHELF)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handleUpdateContent = async (contents: ShelfContent[]) => {
    try {
      const data = await ShelfApi.setContents(id, contents)
      mutate(data)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const contentLocked = !shelf || shelf.active

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ShelfForm
          shelf={shelf}
          disabled={shelf?.active}
          industries={industries ?? []}
          industryTags={industryTags ?? []}
          topics={topics ?? []}
          topicTags={topicTags ?? []}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          onPublish={() => handlePublish(true)}
          onUnpublish={() => handlePublish(false)}
        />
      </Grid>
      <Grid item xs={6}>
        <ShelfContentsList contents={shelf?.contents ?? []} disabled={contentLocked} onUpdate={handleUpdateContent} />
      </Grid>
    </Grid>
  )
}

export default ShelfSingle
