import { Box } from '@material-ui/core'
import React from 'react'
import { StudySetComponentVideoModel } from '../../../store/services/StudySet/types'
import { VideoPreview } from '../../components/VideoPreview/VideoPreview'
import { Exercise } from '../Exercise/Exercise'

interface Props {
  data: StudySetComponentVideoModel
}

export const Video = ({ data }: Props) => {
  return (
    <Box display="flex" alignItems="flex-start" gridGap={16}>
      {data.video.originalUrl && <VideoPreview src={data.video.originalUrl} />}
      {data.exercise && <Exercise exercise={data.exercise} />}
    </Box>
  )
}
