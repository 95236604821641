import { Divider, Grid, IconButton, MenuItem, TextField, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { FieldArray } from 'formik'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import TertiaryButton from '../../shared/components/TertiaryButton/TertiaryButton'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { TARGET_COUNTRIES_ARRAY } from '../../store/services/Book/types'
import { ExerciseFormFields, ExerciseReadWordRaceModel, ExerciseType } from '../../store/services/Exercise/types'
import { ExerciseFormFactory } from '../ExerciseFormFactory/ExerciseFormFactory'
import { ExerciseFormProps } from '../types'
import styles from './WordRace.module.scss'

const getValidationSchema = (bookChild: boolean) => {
  const baseFields = {
    points: ValidationRules.points,
    words: Yup.array().of(ValidationRules.required).min(2).max(100)
  }
  if (!bookChild) {
    return Yup.object().shape({
      ...baseFields,
      targetCountries: ValidationRules.targetCountries,
      difficultyLevel: ValidationRules.difficultyLevel
    })
  } else {
    return Yup.object().shape({
      ...baseFields
    })
  }
}

const getInitialValues = (data?: ExerciseReadWordRaceModel, bookChild?: boolean) => {
  const baseFields: FormFields = {
    points: data?.points || 15,
    words: data?.data.words || [''],
    type: data?.type || ExerciseType.ReadWordRace
  }

  if (!bookChild) {
    return {
      ...baseFields,
      difficultyLevel: data?.difficultyLevel || 0,
      targetCountries: data?.targetCountries || []
    }
  } else {
    return baseFields
  }
}

interface Props extends ExerciseFormProps {
  data?: ExerciseReadWordRaceModel
}

type FormFields = ExerciseFormFields<ExerciseReadWordRaceModel>

const WordRace: React.FC<Props> = ({ data, formDisabled, bookChild, ...exerciseFormProps }) => {
  const isNew = !data
  const handleSubmit = useCallback(
    ({ type, points, words, difficultyLevel, targetCountries }: FormFields) => {
      const preparedData: any = {
        type,
        points,
        data: JSON.stringify({ words })
      }

      if (!bookChild) {
        preparedData.difficultyLevel = difficultyLevel
        preparedData.targetCountries = targetCountries
      }

      return preparedData
    },
    // eslint-disable-next-line
    [isNew]
  )

  const initialValues = useMemo(() => getInitialValues(data, bookChild), [data, bookChild])
  const validationSchema = useMemo(() => getValidationSchema(bookChild), [bookChild])

  return (
    <ExerciseFormFactory
      {...exerciseFormProps}
      formDisabled={formDisabled}
      exerciseId={data?._id}
      bookChild={bookChild}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ handleChange, values, errors, touched, setFieldTouched }) => {
        return (
          <div data-testid="ReadingPage">
            <Typography variant="h5" gutterBottom>
              {isNew ? 'Add' : 'Update'} Word race
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="points"
              label="Stars"
              name="points"
              size="small"
              inputProps={{ min: 0 }}
              onChange={handleChange('points')}
              value={values.points}
              error={touched.points && !!errors.points}
              helperText={(touched.points && errors.points) || ''}
              onBlur={() => setFieldTouched('points')}
              disabled={formDisabled}
            />
            {!bookChild && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="difficultyLevel"
                    label="Difficulty level"
                    name="difficultyLevel"
                    size="small"
                    inputProps={{ min: 0, max: 100 }}
                    onChange={handleChange('difficultyLevel')}
                    value={values.difficultyLevel}
                    error={touched.difficultyLevel && !!errors.difficultyLevel}
                    helperText={(touched.difficultyLevel && errors.difficultyLevel) || ''}
                    onBlur={() => setFieldTouched('difficultyLevel')}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="targetCountries"
                    label="Target countries"
                    name="targetCountries"
                    size="small"
                    data-testid="targetCountries"
                    select
                    SelectProps={{ multiple: true }}
                    onChange={(e) => {
                      setFieldTouched('targetCountries')
                      return handleChange('targetCountries')(e)
                    }}
                    value={values.targetCountries}
                    error={touched.targetCountries && !!errors.targetCountries}
                    helperText={(touched.targetCountries && errors.targetCountries) || ''}
                    onBlur={() => setFieldTouched('targetCountries')}
                    disabled={formDisabled}
                    inputProps={{ 'data-testid': 'targetCountriesInput' }}>
                    {TARGET_COUNTRIES_ARRAY.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
            <Divider className={styles.divider} />
            <FieldArray name={'words'}>
              {(arrayHelpers) => {
                return (
                  <>
                    <div className={styles.wordsContainer}>
                      {values.words.map((word, index) => {
                        return (
                          <div className={styles.word} key={index}>
                            <TextField
                              required
                              fullWidth
                              variant="outlined"
                              size="small"
                              placeholder="Enter a word"
                              id={`words.${index}`}
                              name={`words.${index}`}
                              inputProps={{ 'data-testid': `word-${index}` }}
                              value={word}
                              onChange={handleChange(`words.${index}`)}
                              error={
                                Array.isArray(touched.words) &&
                                touched.words[index] &&
                                !!(Array.isArray(errors.words) && errors.words[index])
                              }
                              onBlur={() => setFieldTouched(`words.${index}`)}
                              disabled={formDisabled}
                            />
                            <IconButton
                              aria-label="remove"
                              data-testid={`word-${index}-remove`}
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                              disabled={formDisabled}>
                              <Clear />
                            </IconButton>
                          </div>
                        )
                      })}
                    </div>
                    <Grid container spacing={2} alignItems={'center'}>
                      <Grid item>
                        <TertiaryButton
                          data-testid="addWord"
                          onClick={() => {
                            arrayHelpers.push('')
                            setFieldTouched('words')
                          }}
                          disabled={values.words.length >= 100 || formDisabled}>
                          Add
                        </TertiaryButton>
                      </Grid>
                      <Grid item>
                        <Typography color="error" variant={'caption'}>
                          {touched.words && values.words.length < 2 ? 'Min 2 words' : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              }}
            </FieldArray>
          </div>
        )
      }}
    </ExerciseFormFactory>
  )
}

export default WordRace
