import React, { useEffect, useState } from 'react'
import { Typography, Chip, TextField, Button } from '@material-ui/core'

import styles from './Settings.module.scss'
import API from '../shared/api/API'

interface UpdateLists {
  blockList: string[]
  updateList: string[]
}

const Settings: React.FC = () => {
  const [updateLists, setUpdateLists] = useState<UpdateLists | null>(null)
  const [updateInputValue, setUpdateInputValue] = useState('')
  const [blockInputValue, setBlockInputValue] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await API.get<UpdateLists>('/update-lists')
        if (data) {
          setUpdateLists(data)
        }
      } catch (error) {}
    })()
  }, [])

  const addRemoveVersion = async (listType: keyof UpdateLists, version: string, removeVersion: boolean) => {
    if (updateLists) {
      const updatedUpdateLists: Partial<UpdateLists> = {}
      if (removeVersion) {
        updatedUpdateLists[listType] = [...updateLists[listType].filter((t) => t !== version)]
      } else {
        updatedUpdateLists[listType] = [...updateLists[listType], version]
      }
      try {
        const { data } = await API.post<UpdateLists>('/update-lists', updatedUpdateLists)
        setUpdateLists(data)
        if (!removeVersion && listType === 'updateList') setUpdateInputValue('')
        if (!removeVersion && listType === 'blockList') setBlockInputValue('')
      } catch (error) {}
    }
  }

  return (
    <div className={styles.container} data-testid={'Settings'}>
      <div className={styles.section}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Update List
        </Typography>
        <div className={styles.versionsContainer}>
          {updateLists?.updateList.map((version) => (
            <Chip
              className={styles.chip}
              label={version}
              onDelete={() => addRemoveVersion('updateList', version, true)}
            />
          ))}
        </div>
        <div className={styles.inputWrapper}>
          <TextField
            variant="outlined"
            value={updateInputValue}
            onChange={(e) => setUpdateInputValue(e.target.value)}
            size="small"
            margin="none"
          />
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            onClick={() => addRemoveVersion('updateList', updateInputValue, false)}>
            Add to update list
          </Button>
        </div>
      </div>

      <div className={styles.section}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Block List
        </Typography>
        <div className={styles.versionsContainer}>
          {updateLists?.blockList.map((version) => (
            <Chip
              className={styles.chip}
              label={version}
              onDelete={() => addRemoveVersion('blockList', version, true)}
            />
          ))}
        </div>
        <div className={styles.inputWrapper}>
          <TextField
            variant="outlined"
            value={blockInputValue}
            onChange={(e) => setBlockInputValue(e.target.value)}
            size="small"
            margin="none"
          />
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            onClick={() => addRemoveVersion('blockList', blockInputValue, false)}>
            Add to block list
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Settings
